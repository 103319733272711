<!--我的收藏-->
<template>
  <div :lang="i18n.locale.value" class="my_favorite">
    <div class="my_favorite_header">
      <p :class="queryParams.categoryId === '' ? 'activated' : ''" @click="changeType('')">
        {{ $t('individual.center.all') }}
      </p>
      <p v-for="(item, index) in courseCategoryList" :key="index" :class="queryParams.categoryId === item.Id ? 'activated' : ''" @click="changeType(item.Id)">
        {{ item.ContentName }}
      </p>
    </div>
    <XyzTransition duration="auto" xyz="fade small">
      <div v-if="collectList.loading" class="my_favorite_body">
        <div v-for="(item, index) in collectList.list" :key="index" class="favorite_item square xyz-nested" @click="useToTabPage('/courseDetail/' + item.Id)">
          <a-image :preview="false" :src="useUrlCv(item.CoverPicURL)" />
          <div class="item_text">
            <a-typography-text class="item_text_title" ellipsis>{{ item.Title }}</a-typography-text>
            <div class="item_text_desc">
              <a-typography-text ellipsis>{{ item.Introduction }}</a-typography-text>
              <SvgIcon class-name="favor_fill_icon" icon-class="favor_fill" @click.stop="cancelFavor(item)" />
            </div>
          </div>
        </div>
      </div>
    </XyzTransition>
    <Empty v-if="collectList.total === 0 && collectList.loading == true" />
    <a-pagination v-if="collectList.total !== 0" v-model:current="queryParams.pageIndex" v-model:page-size="queryParams.pageSize" :total="collectList.total" @change="getList" />
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue';
import SvgIcon from '@/components/svgIcon/index.vue';
import Confirm from '@/components/common/Confirm/index.js';
import { use$t } from '@/hooks/language.js';
import { useCheckIsLogin, useCourseCategoryApi, useGetUserinfo } from '@/hooks/commomApi.js';
import request from '@/common/request.js';
import { useUrlCv } from '@/hooks/format.js';
import { useToHome, useToTabPage } from '@/hooks/page.js';
import { useI18n } from 'vue-i18n';

const courseCategoryList = reactive([]);
const i18n = useI18n();
const collectList = reactive({
  loading: false,
  list: [],
  total: 0,
});
const queryParams = reactive({
  pageIndex: 1,
  pageSize: 6,
  categoryId: '',
});

const changeType = (val) => {
  queryParams.categoryId = val;
  getList();
};

useCourseCategoryApi().then((courseCategory) => {
  courseCategoryList.length = 0;
  courseCategory.data.forEach((item) => {
    courseCategoryList.push({
      Id: item.Id,
      ContentName: item.ContentName,
    });
  });
});

/**
 * 取消收藏
 */
const cancelFavor = (item) => {
  Confirm({
    title: use$t('individual.favorite.modal.title'),
    content: use$t('individual.favorite.modal.content') + '？',
    okEvent: () => {
      request({
        url: '/Course/CancelCollect',
        method: 'get',
        params: {
          courseId: item.Id,
        },
      }).then((resp) => {
        getList();
      });
    },
  });
};

const getList = () => {
  useCheckIsLogin(
    () => {},
    false,
    () => {
      useToHome();
    }
  );
  collectList.loading = false;
  request({
    url: '/Course/MyCollectList',
    method: 'get',
    params: queryParams,
  }).then((resp) => {
    collectList.loading = true;
    queryParams.pageIndex = resp.data.PageIndex;
    queryParams.PageSize = resp.data.PageSize;
    collectList.total = resp.data.Total;
    collectList.list = resp.data.DataList;
  });
};

onMounted(() => {
  getList();
});
</script>

<style lang="less" scoped>
.my_favorite {
  .my_favorite_header {
    padding: 10px 10px 20px 10px;
    border-bottom: 1px solid #e2e2e2;
    display: flex;

    p {
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #666666;
      line-height: 22px;
      text-align: left;
      font-style: normal;
      cursor: pointer;
      margin-left: 50px;

      &:hover {
        color: #0678ff;
      }

      &:first-child {
        margin-left: 0;
      }
    }

    .activated {
      color: #0678ff;
    }
  }

  .my_favorite_body {
    display: grid;
    grid-template-columns: 266px 266px 266px;
    grid-gap: 22px;
    margin: 30px auto;

    .favorite_item {
      width: 266px;
      height: 233px;
      background: #ffffff;
      box-shadow: 0 0 9px 0 rgba(1, 10, 29, 0.15);
      cursor: pointer;
      border-radius: 11px;

      :deep(.arco-image) {
        width: 100%;
      }

      :deep(.arco-image-img) {
        border-radius: 11px 11px 0 0;
        width: 100%;
        height: 147px;
      }

      .item_text {
        padding: 11px 9px;

        .item_text_title {
          margin: 0;
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 15px;
          color: #333333;
          line-height: 21px;
          text-shadow: 0 0 9px rgba(1, 10, 29, 0.15);
          font-style: normal;
        }

        .item_text_desc {
          display: flex;
          margin-top: 10px;
          align-items: center;
          justify-content: space-between;

          .arco-typography {
            margin: 0;
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 13px;
            color: #666666;
            line-height: 18px;
            text-shadow: 0 0 9px rgba(1, 10, 29, 0.15);
            font-style: normal;
            flex: 2;
          }

          .favor_fill_icon {
            width: 22px;
            height: 22px;

            svg {
            }
          }
        }
      }
    }
  }

  &:lang(ar),
  &:lang(pk) {
    .my_favorite_header {
      p {
        margin-left: 0;
        margin-right: 50px;
      }
    }
  }
}
</style>
