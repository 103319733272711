<!--最新消息，推荐课程-->
<template>
  <div :lang="i18n.locale.value" class="news">
    <div class="w1200 news_view">
      <div class="news_people_counting">
        <div>
          <div :lang="i18n.locale.value" class="news_people_counting_title">
            <span>230000 &nbsp;</span>
            <span>{{ $t('home.learn.count') }}</span>
          </div>
          <div class="news_people_counting_desc">{{ $t('home.unit.desc') }}</div>
        </div>
        <div class="news_people_counting_statement">*{{ $t('home.data.statistics') }}</div>
      </div>
      <div class="news_latest_news">
        <div class="news_latest_news_title_view">
          <div class="news_latest_news_title">
            <SvgIcon class-name="news_latest_news_icon" icon-class="news_flag" />
            <span :lang="i18n.locale.value" class="news_latest_news_title_text">{{ $t('home.latest.news') }}</span>
          </div>
          <div class="news_latest_news_viewMore" @click="useToTabPage('/culture')">
            {{ $t('cultural.message.01') }}
            <icon-right v-if="i18n.locale.value !== langToBack.ar" />
            <icon-left v-else />
          </div>
        </div>
        <div class="news_latest_news_body">
          <!--          <template v-for="(item, index) in newsList" :key="item.Id">
                      <div v-if="index < 4" class="news_latest_news_body_item anime_zoom_lager" @click="useToCurrentPage('/articleDetail/' + item.Id)">
                        <div class="news_latest_news_body_item_content">
                          <a-typography-text ellipsis title="">{{ item.Title }}</a-typography-text>
                          <a-typography-text ellipsis title="">{{ item.SubTitle }}</a-typography-text>
                        </div>
                      </div>
                    </template>-->
          <template v-if="newsList.length !== 0">
            <swiper
              :autoplay="{
                delay: 1500,
                pauseOnMouseEnter: true,
                disableOnInteraction: true,
              }"
              :direction="'vertical'"
              :freeMode="false"
              :loop="true"
              :modules="modules"
              :pagination="{
                clickable: true,
              }"
              :slidesPerView="4"
              :spaceBetween="20"
              class="mySwiper"
            >
              <swiper-slide v-for="(item, index) in newsList" :key="item.Id" class="anime_zoom_lager">
                <div class="news_latest_news_body_item" @click="useToCurrentPage('/articleDetail/' + item.Id)">
                  <div class="news_latest_news_body_item_content">
                    <a-typography-text ellipsis title="">{{ item.Title }}</a-typography-text>
                    <a-typography-text ellipsis title="">{{ item.SubTitle }}</a-typography-text>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </template>
          <template v-else>
            <swiper
              :direction="'vertical'"
              :freeMode="false"
              :modules="modules"
              :pagination="{
                clickable: true,
              }"
              :slidesPerView="4"
              :spaceBetween="20"
              class="mySwiper"
            >
              <swiper-slide v-for="item in 4" :key="item">
                <a-skeleton :animation="true">
                  <a-skeleton-line />
                </a-skeleton>
              </swiper-slide>
            </swiper>
          </template>
        </div>
      </div>
      <div class="news_recommended_course">
        <div class="news_recommended_course_title">
          <SvgIcon class-name="news_latest_news_icon" icon-class="news_flag" />
          <span :lang="i18n.locale.value" class="news_recommended_course_title_text">{{ $t('home.recommended.course') }}</span>
        </div>
        <template v-for="(item, index) in recommendCourseList" :key="index">
          <a-image :preview="false" :src="useUrlCv(item.CoverPicURL)" class="anime_zoom_lager"></a-image>
          <a-typography-text class="news_recommended_course_headline" ellipsis>{{ item.Title }}</a-typography-text>
          <div style="display: flex">
            <a-button :class="i18n.locale.value === langToBack.ar ? 'mr0' : ''" class="news_recommended_course_time mr10" shape="round" type="outline">
              {{ $t('home.recommended.cost-effective') }}
            </a-button>
            <a-button :lang="i18n.locale.value" class="news_recommended_course_time mr10" shape="round" type="outline">
              {{ $t('home.recommended.high.quality') }}
            </a-button>
          </div>
          <div class="news_recommended_course_chapter">
            <a-typography-text :ellipsis="{ rows: 4 }">{{ item.Introduction }}</a-typography-text>
          </div>

          <div class="news_recommended_course_go">
            <a-typography-text ellipsis>{{ $t('home.learned.count', { value: item.StudyNum }) }}</a-typography-text>
            <a-button shape="round" type="primary" @click="useToTabPage('/courseDetail/' + item.Id)">{{ $t('home.go.to') }}</a-button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import SvgIcon from '@/components/svgIcon/index.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { FreeMode, Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css/free-mode';
import 'swiper/css';
import { useToCurrentPage, useToTabPage } from '@/hooks/page.js';
import { onMounted, reactive, ref } from 'vue';
import request from '@/common/request.js';
import { useUrlCv } from '@/hooks/format.js';
import { useRecommendCourse } from '@/hooks/commomApi.js';
import { useI18n } from 'vue-i18n';
import { langToBack } from '@/hooks/sysDict.js';

const newsList = reactive([]);
const recommendCourseList = reactive([]);
const i18n = useI18n();

const modules = reactive([FreeMode, Autoplay]);

onMounted(() => {
  //文章列表
  request({
    url: '/Aticle/GetAticleList',
    method: 'get',
  }).then((resp) => {
    setTimeout(() => {
      newsList.length = 0;
      newsList.push(...resp.data);
    }, 500);
  });
});

//推荐课程
useRecommendCourse(1).then((resp) => {
  recommendCourseList.length = 0;
  let res = resp.data[0];
  recommendCourseList.push(res);
});
</script>

<style lang="less" scoped>
.news {
  width: 1920px;
  height: 620px;
  margin-top: 76px;
  background-color: #f8f8f8;
  display: flex;

  .news_view {
    margin: auto;
    height: 480px;
    background-color: #ffffff;
    border-radius: 20px;
    display: flex;

    .news_latest_news_icon {
      width: 6px;
      height: 28px;
    }

    .news_people_counting {
      width: calc(240px - 44px);
      height: calc(480px - 44px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 22px;
      background-image: url('@/assets/images/news_bg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;

      .news_people_counting_title {
        display: flex;
        align-items: center;

        flex-wrap: wrap;

        span:last-child {
          margin-left: 0 !important;
        }

        &:lang(ar),
        &:lang(pk) {
          flex-wrap: wrap;

          span:last-child {
            margin-right: 16px;
            margin-left: 0;
          }
        }

        span:first-child {
          font-family: PingFang SC;
          font-weight: 600;
          font-size: 30px;
          color: #fffb93;
          line-height: 42px;
          font-style: normal;
        }

        span:last-child {
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #ffffff;
          line-height: 17px;
          font-style: normal;
          margin-left: 16px;
        }
      }

      .news_people_counting_desc {
        margin-top: 24px;
        width: 196px;
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        line-height: 22px;
        font-style: normal;
      }

      .news_people_counting_statement {
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #ffffff;
        line-height: 17px;
        text-align: left;
        font-style: normal;
      }
    }

    .news_latest_news {
      width: calc(607px - 88px);
      padding: 32px 44px;

      .news_latest_news_title_view {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 519px;
        margin-bottom: 24px;

        .news_latest_news_title {
          display: flex;

          .news_latest_news_title_text {
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 18px;
            color: #000000;
            line-height: 25px;
            text-align: left;
            font-style: normal;
            margin-left: 20px;

            &:lang(ar),
            &:lang(pk) {
              margin-right: 20px;
              margin-left: 0;
            }
          }
        }

        .news_latest_news_viewMore {
          font-family: PingFang SC;
          cursor: pointer;
          font-weight: 400;
          font-size: 12px;
          color: #666666;
          line-height: 17px;
          text-align: left;
          font-style: normal;
        }
      }

      .news_latest_news_body {
        .news_latest_news_body_item {
          cursor: pointer;
          width: 520px;
          height: 80px;
          background-color: rgba(225, 237, 247, 0.36);
          display: flex;
          align-items: center;

          .news_latest_news_body_item_content {
            flex-grow: 2;
            padding-right: 15px;
            padding-left: 15px;

            .arco-typography:first-child {
              font-family: PingFang SC;
              font-weight: 400;
              font-size: 16px;
              color: #333333;
              line-height: 22px;
              font-style: normal;
              margin: 0;
            }

            .arco-typography:last-child {
              font-family: PingFang SC;
              margin: 10px 0 0;
              font-weight: 400;
              font-size: 12px;
              color: #8d8d8d;
              line-height: 17px;
              font-style: normal;
            }
          }
        }

        :deep(.arco-skeleton-line-row) {
          width: 520px;
          height: 80px !important;
        }
      }
    }

    .news_recommended_course {
      border-left: 1px solid #eaeaea;
      margin-top: 32px;
      margin-bottom: 40px;
      width: calc(352.93px - 88px);
      padding: 0 44px;

      .news_recommended_course_title {
        display: flex;

        .news_recommended_course_title_text {
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #000000;
          line-height: 25px;
          text-align: left;
          font-style: normal;
          margin-left: 20px;

          &:lang(ar),
          &:lang(pk) {
            margin-left: 0;
            margin-right: 20px;
          }
        }
      }

      :deep(.arco-image-img) {
        width: 250px;
        height: 140px;
        margin-top: 24px;
        border-radius: 8px;
      }

      .news_recommended_course_headline {
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #333333;
        line-height: 22px;
        font-style: normal;
        margin-top: 12px;
      }

      .news_recommended_course_time {
        width: auto;
        height: 26px;
        background: #f4f9fc;
        border-radius: 13px;
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 13px;
        color: #0678ff;
        line-height: 18px;
        text-align: center;
        font-style: normal;
        margin-top: 12px;

        &:lang(id) {
          margin-left: 5px;
        }
      }

      .news_recommended_course_chapter {
        margin-top: 14px;

        :deep(.arco-typography) {
          font-family: PingFang SC;
          margin: 0;
          font-weight: 400;
          font-size: 12px;
          color: #575d6c;
          line-height: 18px;
          letter-spacing: 1px;
          font-style: normal;
        }
      }

      .news_recommended_course_go {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        :deep(.arco-typography) {
          flex: 2;
          width: 109px;
          font-family: PingFang SC;
          font-weight: 400;
          margin: 0;
          font-size: 14px;
          color: #000000;
          line-height: 20px;
          text-align: left;
          font-style: normal;
        }

        .arco-btn {
          width: auto;
          height: 26px;
          border-radius: 13px;
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          font-style: normal;
        }
      }
    }
  }

  .mySwiper {
    height: 380px;

    .swiper-slide {
      height: 80px !important;
      margin-bottom: 20px !important;
    }
  }

  &:lang(ar),
  &:lang(pk) {
    .news_recommended_course_chapter {
      :deep(.arco-typography) {
        font-size: 13px !important;
      }
    }

    .arco-typography:last-child {
      font-size: 13px !important;
    }
  }
}
</style>
