<!--个人中心-->
<template>
  <div :lang="i18n.locale.value" class="individual_center">
    <div class="individual_center_header">
      <div class="people_header">
        <a-avatar :image-url="userAvatar" />
        <div class="people_header_text">
          <p>{{ localCache.userinfo?.UserNick }}</p>
          <a-typography-text class="email">{{ localCache.userinfo.EMail }}</a-typography-text>
        </div>
      </div>
    </div>

    <div class="individual_center_body">
      <div class="center_l">
        <div :class="tabMenuId === 1 ? 'center_l_item_activate' : ''" class="center_l_item" @click="changeMenu(1)">
          <SvgIcon :icon-class="tabMenuId === 1 ? 'user_w' : 'user'" class-name="user_icon" />
          {{ $t('individual.center') }}
        </div>
        <div :class="tabMenuId === 2 ? 'center_l_item_activate' : ''" class="center_l_item" @click="changeMenu(2)">
          <SvgIcon :icon-class="tabMenuId === 2 ? 'book_w' : 'book'" class-name="user_icon" />
          {{ $t('individual.center.my.homework') }}
        </div>
        <div :class="tabMenuId === 3 ? 'center_l_item_activate' : ''" class="center_l_item" @click="changeMenu(3)">
          <div>
            <SvgIcon :icon-class="tabMenuId === 3 ? 'help_w' : 'help'" class-name="user_icon" />
          </div>
          {{ $t('individual.center.my.course') }}
        </div>
        <div :class="tabMenuId === 7 ? 'center_l_item_activate' : ''" class="center_l_item" @click="changeMenu(7)">
          <div>
            <SvgIcon :icon-class="tabMenuId === 7 ? 'setmeal_a' : 'setmeal'" class-name="user_icon" />
          </div>
          {{ $t('setMeal.message.14') }}
        </div>
        <div :class="tabMenuId === 4 ? 'center_l_item_activate' : ''" class="center_l_item" @click="changeMenu(4)">
          <SvgIcon :icon-class="tabMenuId === 4 ? 'time_flag_w' : 'time_flag'" class-name="user_icon" />
          {{ $t('individual.center.my.collection') }}
        </div>
        <div :class="tabMenuId === 6 ? 'center_l_item_activate' : ''" class="center_l_item" @click="changeMenu(6)">
          <SvgIcon :icon-class="tabMenuId === 6 ? 'report_a' : 'report'" class-name="user_icon" />
          {{ $t('share.message.01') }}
        </div>
        <div :class="tabMenuId === 5 ? 'center_l_item_activate' : ''" class="center_l_item" @click="changeMenu(5)">
          <SvgIcon class-name="user_icon" icon-class="setting" />
          {{ $t('individual.center.log.out') }}
        </div>
      </div>
      <div class="center_r">
        <UserInfoCp v-if="tabMenuId === 1" />
        <MyHomework v-else-if="tabMenuId === 2" />
        <MyCourseCp v-else-if="tabMenuId === 3" />
        <MyFavorite v-else-if="tabMenuId === 4" />
        <share v-else-if="tabMenuId === 6" />
        <MySetMeal v-else-if="tabMenuId === 7" />
      </div>
    </div>
  </div>
</template>

<script setup>
import img from '@/components/layout/images/header.png';
import SvgIcon from '@/components/svgIcon/index.vue';
import { computed, onMounted, ref } from 'vue';
import UserInfoCp from '@/views/individualCenter/components/UserInfoCp.vue';
import Confirm from '@/components/common/Confirm/index.js';
import MyCourseCp from '@/views/individualCenter/components/MyCourseCp.vue';
import MyFavorite from '@/views/individualCenter/components/MyFavorite.vue';
import MyHomework from '@/views/individualCenter/components/MyHomework.vue';
import { use$t } from '@/hooks/language.js';
import request from '@/common/request.js';
import { useLocalCacheStore } from '@/stores/localCache.js';
import { useToHome } from '@/hooks/page.js';
import { useRoute, useRouter } from 'vue-router';
import { useCheckIsLogin } from '@/hooks/commomApi.js';
import { useI18n } from 'vue-i18n';
import Share from '@/views/individualCenter/components/share.vue';
import MySetMeal from '@/views/individualCenter/components/MySetMeal.vue';

const route = useRoute();
const router = useRouter();
const tabMenuId = ref(1);
const localCache = useLocalCacheStore();
const i18n = useI18n();

const userAvatar = computed(() => {
  if (localCache.userinfo?.UserIcon == null || localCache.userinfo?.UserIcon == 'null') {
    return img;
  }
  return import.meta.env.VITE_APP_IMG_BASE_URL + localCache.userinfo?.UserIcon;
});

/**
 * 切换菜单
 */
const changeMenu = (id) => {
  if (id === 5) {
    Confirm({
      title: use$t('individual.center.log.out'),
      content: use$t('individual.center.logout.confirm'),
      okEvent: () => {
        request({
          url: '/Auth/LogOut',
          method: 'get',
          params: {},
        }).then((resp) => {
          localCache.setUserInfo(undefined);
          localStorage.removeItem('Token');
          try {
            window._hmt.push(['_clearUserId', true]);
          } catch (e) {
            console.error(e);
          }
          useToHome();
        });
      },
    });
  } else {
    tabMenuId.value = id;
    router.push({
      path: route.path,
      query: {
        tabIndex: id,
      },
    });
  }
};
if (route.hasOwnProperty('query') && route.query.hasOwnProperty('tabIndex')) {
  tabMenuId.value = parseInt(route.query.tabIndex);
}
onMounted(() => {
  useCheckIsLogin(
    () => {},
    false,
    () => {
      useToHome();
    }
  );
});
</script>

<style lang="less" scoped>
.email {
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
}

.individual_center {
  width: 100vw;
  display: inline-block;
  min-height: 80vh;
  margin-top: 88px;
  background-color: #f8f8f8;
  padding-bottom: 149px;

  .individual_center_header {
    width: 1920px;
    height: 230px;
    background-image: url('@/assets/images/individual_center_bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;

    .people_header {
      width: 1200px;
      margin: auto;
      display: flex;
      align-items: center;

      .arco-avatar {
        width: 108px;
        height: 108px;
      }

      .people_header_text {
        margin-left: 30px;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 24px;
        color: #ffffff;
        line-height: 33px;
        font-style: normal;
      }

      .arco-btn {
        width: 136px;
        height: 36px;
        background: #0678ff;
        border-radius: 3px;
        border: 1px solid #ffffff;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #ffffff;
        line-height: 22px;
        text-align: center;
        font-style: normal;
      }
    }
  }

  .individual_center_body {
    width: 1200px;
    margin: 30px auto auto;
    display: flex;
    justify-content: space-between;

    .center_l {
      width: 300px;
      height: 664px;
      background-color: #ffffff;

      .center_l_item {
        cursor: pointer;
        margin-top: 25px;
        width: calc(280px - 75px);
        display: flex;
        align-items: center;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #666666;
        line-height: 25px;
        text-align: left;
        font-style: normal;
        padding: 14px 25px 14px 50px;
        overflow: hidden;

        .user_icon {
          width: 22px;
          height: 22px;
          margin-right: 16px;
        }

        &:hover {
          .center_l_item_activate;
        }
      }

      .center_l_item_activate {
        color: #ffffff;
        background-image: url('@/assets/images/activity_bg.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }

    .center_r {
      width: calc(880px - 40px);
      height: calc(664px - 40px);
      background: #ffffff;
      padding: 20px;
    }
  }

  &:lang(ar),
  &:lang(pk) {
    .people_header_text {
      margin-left: 0;
      margin-right: 30px;
    }
  }
}
</style>
