<!--文章详情-->
<template>
  <div class="article_detail">
    <div class="article_header">
      <div class="w1200 article_view_header">
        <a-breadcrumb>
          <template #separator>
            <icon-double-right />
          </template>
          <a-breadcrumb-item class="pointer" @click="useToHome">{{ $t('header.menu.homePage') }}</a-breadcrumb-item>
          <a-breadcrumb-item>{{ $t('common.article.detail') }}</a-breadcrumb-item>
        </a-breadcrumb>
      </div>
    </div>

    <div class="article_body">
      <p class="article_title">{{ newsDetail.Title }}</p>

      <div :lang="i18n.global.locale.value" class="article_time">
        <SvgIcon class-name="time_icon" icon-class="time"></SvgIcon>
        <span>{{ $t('common.article.public.date') }}：{{ useFormatDate(newsDetail.ShowTime, 'YYYY-MM-DD') }}</span>
      </div>

      <a-divider :margin="25" />
      <a-skeleton v-if="loading === true" animation>
        <a-skeleton-line :rows="2" />
        <a-skeleton-line :rows="2" />
        <a-skeleton-line :rows="2" />
        <a-skeleton-shape class="rich_style mt10" />
      </a-skeleton>
      <div v-else v-rich-text-src="newsDetail.Content" class="rich_style"></div>

      <a-divider :margin="25"></a-divider>
      <div class="article_footer">
        <div class="article_nav">
          <a-link v-if="preObj.Id !== ''" @click="changeArticle(preObj.Id)">{{ $t('common.article.pre') }}：{{ preObj.Title }}</a-link>
          <a-link v-if="nextObj.Id !== ''" @click="changeArticle(nextObj.Id)">{{ $t('common.article.next') }}：{{ nextObj.Title }}</a-link>
        </div>
        <a-button @click="useCloseWindowTab">{{ $t('common.back.home') }}</a-button>
      </div>
    </div>
  </div>
  <top />
</template>

<script setup>
import SvgIcon from '@/components/svgIcon/index.vue';
import { hideLoadingProcess, showLoadingProcess, useCloseWindowTab, useToHome } from '@/hooks/page.js';
import request from '@/common/request.js';
import { useRoute, useRouter } from 'vue-router';
import { reactive, ref, watch } from 'vue';
import { useFormatDate } from '@/hooks/format.js';
import i18n from '@/locales/index.js';
import top from '@/components/top/index.vue';

const route = useRoute();
const router = useRouter();
const loading = ref(false);
const nextObj = reactive({
  Id: '',
  Title: '',
});
const preObj = reactive({
  Id: '',
  Title: '',
});
const newsDetail = reactive({
  Id: '',
  ShowTime: '',
  Title: '',
  Content: '',
});

/**
 * 获取新闻详情
 */
const getArticleDetail = () => {
  showLoadingProcess();
  loading.value = true;
  request({
    url: '/Aticle/GetAticleById',
    method: 'get',
    params: {
      Id: route.params.id,
    },
  })
    .then((resp) => {
      newsDetail.Id = resp.data.Id;
      newsDetail.ShowTime = resp.data.ShowTime;
      newsDetail.Title = resp.data.Title;
      newsDetail.Id = resp.data.Id;
      newsDetail.Content = resp.data.Content;
    })
    .finally(() => {
      hideLoadingProcess();
      loading.value = false;
    });
};

/**
 * 获取上一条新闻
 */
const getPreNews = () => {
  request({
    url: '/Aticle/GetPreviousAticle',
    method: 'get',
    params: {
      Id: route.params.id,
    },
  })
    .then((resp) => {
      if (resp.data == null) {
        preObj.Id = '';
        preObj.Title = '';
      } else {
        preObj.Id = resp.data.Id;
        preObj.Title = resp.data.Title;
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

/**
 * 获取下一条新闻
 */
const getNextNews = () => {
  request({
    url: '/Aticle/GetNextAticle',
    method: 'get',
    params: {
      Id: route.params.id,
    },
  }).then((resp) => {
    if (resp.data == null) {
      nextObj.Id = '';
      nextObj.Title = '';
    } else {
      nextObj.Id = resp.data.Id;
      nextObj.Title = resp.data.Title;
    }
  });
};

/**
 * 切换新闻文章
 * @param Id
 */
const changeArticle = (Id) => {
  router.replace('/articleDetail/' + Id);
};

watch(
  () => router.currentRoute.value,
  () => {
    getArticleDetail();
    getPreNews();
    getNextNews();
  },
  { immediate: true }
);
</script>

<style lang="less" scoped>
.article_detail {
  width: 1920px;
  display: inline-block;
  min-height: 80vh;
  margin-top: 88px;
  background-color: #f5f7f9;
  padding-bottom: 149px;

  .article_header {
    .article_view_header {
      margin: 30px auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .article_body {
    background-color: #ffffff;
    margin: 0 auto;
    padding: 70px 25px;
    width: calc(1200px - 50px);

    .article_title {
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 44px;
      color: #000000;
      line-height: 60px;
      letter-spacing: 4px;
      text-align: center;
      font-style: normal;
    }

    .article_time {
      margin-top: 25px;
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #333333;
      line-height: 22px;
      letter-spacing: 1px;
      font-style: normal;

      .time_icon {
        width: 17px;
        height: 17px;
        margin-right: 11px;

        &:lang(ar),
        &:lang(pk) {
          margin-left: 11px;
          margin-right: 0;
        }
      }
    }

    .article_footer {
      display: flex;
      justify-content: space-between;

      .article_nav {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
      }

      .arco-btn {
        height: 60px;
        background: #0678ff;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        line-height: 24px;
        text-align: center;
        font-style: normal;
      }
    }
  }
}

.rich_style {
  font-size: 14px;
  line-height: 25px;
  width: 100%;
  min-height: 500px;
  direction: ltr;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
</style>
