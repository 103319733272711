<template>
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <div id="paypal-button-container22"></div>
  <!--  <a-button type="primary" @click="paymentEvent">立即支付</a-button>-->
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
</template>
<script setup>
import { nextTick, onMounted } from 'vue';
import { loadScript } from '@paypal/paypal-js';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useGetEnv, useInitPaypal } from '@/hooks/utils.js';
import i18n from '@/locales/index.js';
import { Message } from '@arco-design/web-vue';
import { use$t } from '@/hooks/language.js';

/**
 * paypal支付
 */
const paymentEvent = () => {
  // axios
  //   .post('http://192.168.2.18:6060/api/PayOrders/CreateOrder', {
  //     ProductId: uuidv4(),
  //     Amount: 12.0,
  //     Lang: 'zh',
  //     CurrencyCode: 'USD',
  //     ActivationCodeLevel: 1,
  //   })
  //   .then((resp) => {
  //     console.log(JSON.parse(resp.data.data).data.approve);
  //     window.location.href = JSON.parse(resp.data.data).data.approve;
  //   });

  useInitPaypal(
    '#paypal-button-container22',
    createOrder,
    (resp) => {
      console.log(resp);
      alert('支付成功');
    },
    (data) => {
      alert('主动取消支付');
      console.log('主动取消支付', data);
    },
    (err) => {
      alert('支付出现错误');
      console.log('支付出现错误', err);
    }
  );
};

const createOrder = async () => {
  try {
    const params = {
      ProductId: 'bcad8f39-c64e-4eb9-8a7f-ceb5e4c587d9',
      Lang: 'zh',
      ActivationCodeLevel: 1,
      Amount: '1.00',
      CurrencyCode: 'USD',
      Source: 'web',
    };
    let xAuthToken =
      'eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI0MzQzYTA0Yi0zYmFhLTQ2N2QtODQzZC1kMGE1NDY1Yzk4ZjYiLCJleHAiOjE3NjA2MDYzMzUsImlzcyI6IldlYkFwcElzc3VlciIsImF1ZCI6IldlYkFwcEF1ZGllbmNlIn0.MQKI2ws1x1mOtVVglCRzGTtCoh9TU8lXr-LZwfF1fis';
    const response = await fetch(useGetEnv('VITE_APP_PAYPAL_DOMAIN') + 'PayPal/orders', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + xAuthToken },
      body: JSON.stringify(params),
    });
    const orderData = await response.json();
    const paymentId = orderData.data.paymentId;
    return paymentId;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

onMounted(() => {
  nextTick(() => {
    paymentEvent();
  });
  // loadScript({
  //   'client-id': import.meta.env.VITE_APP_PAYPAL_CLIENT_ID,
  //   commit: true,
  //   components: 'buttons,messages',
  //   currency: 'USD',
  //   debug: false,
  // })
  //   .then((paypal) => {
  //     console.log('paypal===>', paypal);
  //     // start to use the PayPal JS SDK script
  //     if (paypal) {
  //       paypal
  //         .Buttons({
  //           style: {
  //             layout: 'vertical',
  //             color: 'gold',
  //             shape: 'rect',
  //             Tagline: 'false',
  //             label: 'buynow',
  //           },
  //           async createOrder() {
  //             try {
  //               const response = await fetch('https://paypal.hanzfeng.com/api/PayOrders/CreateOrder', {
  //                 method: 'POST',
  //                 headers: { 'Content-Type': 'application/json' },
  //                 body: JSON.stringify({
  //                   ProductId: uuidv4(),
  //                   Amount: 1.0,
  //                   Lang: 'zh',
  //                   CurrencyCode: 'USD',
  //                   ActivationCodeLevel: 1,
  //                 }),
  //               });
  //               const orderData = await response.json();
  //               const paymentId = JSON.parse(orderData.data).data.paymentId;
  //               return paymentId;
  //             } catch (error) {
  //               console.error(error);
  //               throw error;
  //             }
  //           },
  //           async onApprove(data) {
  //             console.log('支付成功', data);
  //             // Capture the funds from the transaction.
  //             const response = await fetch('https://paypal.hanzfeng.com/api/PayOrders/success_pay?token=' + data.orderID + '&payerId=' + data.payerID, {
  //               method: 'GET',
  //             });
  //
  //             const details = await response.json();
  //             console.log('支付成功', details);
  //             //
  //             // // Show success message to buyer
  //             // alert(`Transaction completed by ${details.payer.name.given_name}`);
  //           },
  //           onCancel(data) {
  //             // 取消支付
  //             // window.location.assign("/your-cancel-page");
  //             console.log('我取消了支付', data);
  //           },
  //           onError(err) {
  //             // 支付出现错误
  //             // window.location.assign('/your-error-page-here');
  //             console.log('支付出错了', err);
  //           },
  //         })
  //         .render('#paypal-button-container');
  //     }
  //   })
  //   .catch((err) => {
  //     console.error('failed to load the PayPal JS SDK script', err);
  //   });
});
</script>

<style lang="less" scoped></style>
