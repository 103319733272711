<!--套餐详情-->
<template>
  <div class="setMealDetail">
    <div class="article_header">
      <div class="w1200 article_view_header">
        <a-breadcrumb>
          <template #separator>
            <icon-double-right />
          </template>
          <a-breadcrumb-item class="pointer" @click="useToHome">{{ $t('header.menu.homePage') }}</a-breadcrumb-item>
          <a-breadcrumb-item>{{ setMealDetail?.Title }}</a-breadcrumb-item>
        </a-breadcrumb>
      </div>
    </div>
    <div class="course_detail_view">
      <div class="media_show square xyz-nested">
        <PreviewView ref="previewRef">
          <template #showroom>
            <div class="thumbnail_group">
              <template v-for="(item, index) in setMealDetail.ShowURL">
                <template v-if="item.type === 'image'">
                  <div
                    :class="activeShowObj.Id === item.Id ? 'activited' : ''"
                    :style="{ backgroundImage: 'url(' + item.url + ')' }"
                    class="thumbnail_item anime_zoom_lager"
                    @click="activeShowEvent(item)"
                  >
                    <!--<a-image :preview="false" :src="thumbnail" />-->
                  </div>
                </template>
                <template v-else>
                  <video
                    :class="activeShowObj.Id === item.Id ? 'activited' : ''"
                    :src="item.url"
                    autoplay
                    class="thumbnail_item anime_zoom_lager"
                    loop
                    muted
                    preload
                    @click="activeShowEvent(item)"
                  ></video>
                </template>
              </template>
            </div>
          </template>
        </PreviewView>
      </div>
      <div class="course_detail_desc">
        <div class="course_detail_title">
          <a-tag bordered>{{ $t('setMeal.message.02') }}</a-tag>
          <a-typography-text ellipsis>{{ setMealDetail.Title }}</a-typography-text>
        </div>
        <div class="course_detail_sub_title">
          <p>{{ $t('setMeal.message.03', { value: setMealDetail.CourseList?.length }) }}</p>
        </div>
        <div class="course_price_info">
          <div :lang="i18n.locale.value" class="course_price">
            <p>{{ $t('detail.course.price') }}</p>
            <p>$&nbsp;{{ setMealDetail.Price.toFixed(2) }}</p>
          </div>
          <div class="buyCount" v-html="$t('setMeal.message.04', { value: setMealDetail.BuyCount })"></div>
        </div>
        <div :lang="i18n.locale.value" class="course_special_service">
          <p>{{ $t('detail.course.characteristic.service') }}</p>
          <p>
            <SvgIcon class-name="special_service_icon" icon-class="safe" />
            {{ $t('detail.course.brush.questions') }}
          </p>
          <p>
            <SvgIcon class-name="special_service_icon" icon-class="time" />
            {{ $t('detail.course.online.consultation') }}
          </p>
          <p>
            <SvgIcon class-name="special_service_icon" icon-class="down" />
            {{ $t('detail.course.download.anytime') }}
          </p>
        </div>
        <div class="course_detail_line"></div>
        <div :lang="i18n.locale.value" class="course_detail_btn_group">
          <a-button v-if="alreadyOwned === true" :style="{ opacity: 0.36, cursor: 'not-allowed' }">
            <div class="btn_content">
              {{ alreadyOwnedSetMealId === setMealDetail.Id ? $t('setMeal.message.05') : $t('setMeal.message.18') }}
            </div>
          </a-button>
          <a-button v-else-if="alreadyOwned === false" class="buy_btn" @click="buyNow">
            <div class="btn_content">
              {{ $t('detail.course.buy.now') }}
            </div>
          </a-button>
          <!--          <a-button @click="consulEvent" @mouseleave="mouseleave" @mouseover="mouseover">
                      <img :src="phoneGif" class="kefu_icon" />
                      {{ $t('detail.course.consult.now') }}
                    </a-button>-->
        </div>
      </div>
    </div>
    <div class="course_content">
      <div class="course_content_detail">
        <a-tabs default-active-key="1" lazy-load>
          <a-tab-pane key="1" :title="$t('setMeal.message.08')">
            <div class="cardBody">
              <div v-for="(item, index) in setMealDetail.CourseList" :key="index" class="cardItem pointer" @click="clickCourseEvent(item)">
                <div class="card_content">
                  <a-image :preview="false" :src="useUrlCv(item.CoverPicURL)"></a-image>
                  <div class="item_l">
                    <a-typography-text :ellipsis="{ rows: 2 }" class="itemTitle">{{ item.Title }}</a-typography-text>
                    <a-typography-text :ellipsis="{ rows: 2 }" class="itemSubTitle">{{ item.Introduction }}</a-typography-text>
                  </div>
                </div>
                <div class="card_footer">
                  <template v-if="alreadyOwned && alreadyOwnedSetMealId === setMealDetail.Id">
                    <p>
                      {{ use$t('setMeal.message.16') }}：
                      <span>{{ usedCount(item.Id, index) }}</span>
                    </p>
                    <p>
                      {{ use$t('setMeal.message.17') }}：
                      <span>{{ remainingCount(item.Id, index) }}</span>
                    </p>
                  </template>
                  <template v-else>
                    <p>
                      {{ use$t('setMeal.message.12') }}：
                      <span>{{ use$t('setMeal.message.13', { value: setMealDetail.CourseCouters.split(',')[index] }) }}</span>
                    </p>
                  </template>
                  <a-progress
                    v-show="alreadyOwned && alreadyOwnedSetMealId === setMealDetail.Id"
                    :animation="true"
                    :color="{
                      '0%': '#22A6FD',
                      '100%': '#0B7FF6',
                    }"
                    :percent="parseFloat(getPercent(item.Id, index))"
                    :stroke-width="9"
                    :style="{ width: '50%' }"
                    :width="260"
                    track-color="#EEEEEE"
                  />
                </div>
              </div>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
      <div class="other_recommend">
        <p class="other_recommend_title">{{ $t('setMeal.message.07') }}</p>

        <div v-for="(item, index) in recommendSetMealList" :key="index" class="recommend_item pointer" @click="useToCurrentPage('/setMeal/detail/' + item.Id)">
          <a-image :preview="false" :src="useUrlCv(item.CoverPicURL)" class="anime_zoom_lager"></a-image>
          <div :lang="i18n.locale.value" class="recommend_item_desc">
            <a-typography-text
              :ellipsis="{
                rows: 2,
              }"
            >
              {{ item.Title }}
            </a-typography-text>
            <!--            <a-typography-text
                          :ellipsis="{
                            rows: 2,
                          }"
                        >
                          {{ item.Introduction }}
                        </a-typography-text>-->
            <p class="buy_count" v-html="$t('setMeal.message.04', { value: item.BuyCount })"></p>
            <p class="cursor_price_no">{{ $t('currency.symbol') }}&nbsp;{{ item.Price.toFixed(2) }}</p>
          </div>
        </div>
        <empty v-if="recommendSetMealList.length === 0" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref, watch } from 'vue';
import { useHead } from '@unhead/vue';
import { useRefreshPage, useToCurrentPage, useToHome, useToTabPage } from '@/hooks/page.js';
import { useCommonCacheStore } from '@/stores/commonCache.js';
import { useRoute, useRouter } from 'vue-router';
import request from '@/common/request.js';
import SvgIcon from '@/components/svgIcon/index.vue';
import PreviewView from '@/components/video/PreviewView.vue';
import { v4 as uuidv4 } from 'uuid';
import { useUrlCv } from '@/hooks/format.js';
import { useI18n } from 'vue-i18n';
import { useCheckBuySetMeal, useCheckIsLogin, useRecommendSetMeal } from '@/hooks/commomApi.js';
import setMealPayment from '@/components/setMealPayment/index.js';
import Empty from '@/components/empty/index.vue';
import Alert from '@/components/common/Alert/index.js';
import { use$t } from '@/hooks/language.js';
import { IconCopyright } from '@arco-design/web-vue/es/icon/index.js';

const commonCache = useCommonCacheStore();
const route = useRoute();
const router = useRouter();
const previewRef = ref();
const alreadyOwned = ref(false);
const alreadyOwnedSetMealId = ref('');
const i18n = useI18n();
const recommendSetMealList = reactive([]);
const setMealDetail = reactive({
  Id: '',
  PackageType: 0,
  CourseIds: '',
  BuyCount: 0,
  Title: '',
  ShowURL: [],
  CourseList: [],
  Introduction: '',
  Price: 0,
  EurPrice: 0,
  CoverPicURL: '',
  Content: '',
  CourseCouters: '',
  StudyList: [],
});
const activeShowObj = reactive({
  Id: '',
  type: '',
  url: '',
});
onMounted(() => {
  let Id = route.params.id;
  commonCache.setHeaderWhiteTheme();
  //获取套餐详情
  request({
    url: '/Package/GetPackageDetails',
    method: 'get',
    params: {
      Id: Id,
    },
  }).then(async (resp) => {
    setMealDetail.Id = resp.data.Id;
    setMealDetail.PackageType = resp.data.PackageType;
    setMealDetail.CourseIds = resp.data.CourseIds;
    setMealDetail.BuyCount = resp.data.BuyCount;
    setMealDetail.Title = resp.data.Title;
    setMealDetail.Introduction = resp.data.Introduction;
    setMealDetail.Price = resp.data.Price;
    setMealDetail.EurPrice = resp.data.EurPrice;
    setMealDetail.CoverPicURL = resp.data.CoverPicURL;
    setMealDetail.Content = resp.data.Content;
    setMealDetail.CourseList = resp.data.CourseList;
    setMealDetail.CourseCouters = resp.data.CourseCouters;
    setMealDetail.StudyList = resp.data.StudyList;
    const showArr = resp.data.ShowURL.split(',');
    showArr.forEach((item) => {
      if (item.indexOf('.mp4') > -1) {
        //视频
        setMealDetail.ShowURL.push({
          Id: uuidv4(),
          type: 'video',
          url: useUrlCv(item),
        });
      } else {
        setMealDetail.ShowURL.push({
          Id: uuidv4(),
          type: 'image',
          url: useUrlCv(item),
        });
      }
    });
    //设置网页头
    useHead({
      title: setMealDetail.Title,
    });
    activeShowObj.Id = setMealDetail.ShowURL[0].Id;
    activeShowObj.type = setMealDetail.ShowURL[0].type;
    activeShowObj.url = setMealDetail.ShowURL[0].url;
    previewRef.value.setActiveValue({
      type: setMealDetail.ShowURL[0].type,
      url: setMealDetail.ShowURL[0].url,
    });
    try {
      let promise = await useCheckBuySetMeal(setMealDetail.Id);
      console.log('是否购买了套餐', promise);
      if (promise.data !== 0) {
        alreadyOwned.value = true;
        alreadyOwnedSetMealId.value = promise.data;
      } else {
        alreadyOwned.value = false;
        alreadyOwnedSetMealId.value = '';
      }
    } catch (e) {
      alreadyOwned.value = false;
    } finally {
      //获取推荐课程
      useRecommendSetMeal(100).then((resp) => {
        recommendSetMealList.length = 0;
        recommendSetMealList.push(...resp.data.DataList.filter((item) => item.Id !== setMealDetail.Id));
      });
    }
  });
});

const clickCourseEvent = (item) => {
  if (alreadyOwnedSetMealId.value === setMealDetail.Id && alreadyOwned.value === true) {
    useToCurrentPage('/courseDetail/' + item.Id);
  }
};

/**
 * 点击预览
 * @param item
 */
const activeShowEvent = (item) => {
  previewRef.value.setActiveValue({
    type: item.type,
    url: item.url,
  });
  activeShowObj.Id = item.Id;
  activeShowObj.type = item.type;
  activeShowObj.url = item.url;
};

/**
 * 套餐使用进度
 */
const getPercent = (Id, index) => {
  if (setMealDetail.StudyList === undefined || setMealDetail.StudyList.length === 0) {
    return 0.0;
  } else {
    //总课程
    const count = setMealDetail.CourseCouters.split(',')[index];
    //已学习课程
    const findObj = setMealDetail.StudyList.find((item) => item.CourseId === Id);
    if (findObj) {
      const studyCount = findObj.Counter;
      //计算百分比
      return (studyCount / count).toFixed(2);
    } else {
      return 0.0;
    }
  }
};

/**
 * 剩余课程
 * @param Id
 * @param index
 */
const remainingCount = (Id, index) => {
  const count = setMealDetail.CourseCouters.split(',')[index];
  //已学习课程
  const findObj = setMealDetail.StudyList.find((item) => item.CourseId === Id);
  if (findObj) {
    const studyCount = findObj.Counter;
    return count - studyCount;
  } else {
    return count;
  }
};
/**
 * 已使用课程
 * @param Id
 * @param index
 */
const usedCount = (Id, index) => {
  const count = setMealDetail.CourseCouters.split(',')[index];
  //已学习课程
  const findObj = setMealDetail.StudyList.find((item) => item.CourseId === Id);
  if (findObj) {
    return findObj.Counter;
  } else {
    return 0;
  }
};

/**
 * 立即购买
 */
const buyNow = () => {
  useCheckIsLogin(() => {
    setMealPayment(
      setMealDetail,
      () => {
        Alert({
          content: use$t('setMeal.message.10'),
          okEvent: () => {
            useRefreshPage();
          },
          cancelEvent: () => {
            useRefreshPage();
          },
        });
      },
      'setMeal'
    );
  });
};

watch(
  () => router.currentRoute.value,
  (value, oldValue, onCleanup) => {
    useRefreshPage();
  },
  { immediate: false }
);
</script>

<style lang="less" scoped>
.setMealDetail {
  background-color: #f5f7f9;
  width: 1920px;
  display: inline-block;
  min-height: 80vh;
  margin-top: 88px;
  padding-bottom: 149px;

  .article_header {
    .article_view_header {
      margin: 30px auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .course_detail_view {
    margin: 30px auto auto;
    width: calc(1200px - 60px);
    height: calc(420px - 80px);
    background: #ffffff;
    padding: 40px 30px;
    display: flex;

    .media_show {
      background: #ffffff;
      flex-direction: column;
      display: flex;

      :deep(.thumbnail_group) {
        width: 100%;
        height: 80px;
        overflow: hidden;
        position: relative;
        padding: 0;
        bottom: 90px;
        align-items: center;
        display: flex;

        .thumbnail_item {
          width: 128px;
          height: 72px;
          margin-left: 9px;
          cursor: pointer;
          background-size: 100% 100%;
          background-repeat: no-repeat;

          //.arco-image-img {
          //  width: 127px !important;
          //  height: 72px !important;
          //  box-shadow: 5px 5px 5px 1px red inset;
          //}

          &:first-child {
            margin-left: 18px;
          }

          &:last-child {
            margin-right: 18px;
          }
        }

        .activited {
          border: 2px solid #145ee5;
          box-shadow:
            1px 1px 1px 1px #ffffff inset,
            -1px -1px 1px 1px #ffffff inset;
        }
      }
    }

    .course_detail_desc {
      width: calc(600px - 40px);
      padding: 0 20px;

      .course_detail_title {
        display: flex;
        align-items: center;

        .arco-tag {
          height: 23px;
          border-radius: 4px;
          border: 1px solid #0678ff;
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #0678ff;
          text-align: center;
          font-style: normal;

          text-overflow: unset;
          overflow: auto;
          box-sizing: unset;
        }

        :deep(.arco-typography) {
          font-family: PingFang SC;
          font-weight: 600;
          font-size: 24px;
          flex-grow: 1;
          color: #333333;
          line-height: 33px;
          text-align: left;
          font-style: normal;
          margin: 0 0 0 10px;
        }
      }

      .course_detail_sub_title {
        display: flex;
        align-items: center;
        margin-top: 17px;

        p {
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #575d6c;
          line-height: 20px;
          text-align: left;
          font-style: normal;
        }

        div {
          width: 1px;
          height: 15px;
          background-color: rgba(87, 93, 108, 0.5);
          margin: 0 10px;
        }
      }

      .course_price_info {
        width: 550px;
        height: 65px;
        margin-top: 20px;
        background-image: url('@/assets/images/course_price_bg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .course_price {
          display: flex;
          align-items: center;
          margin-left: 10px;

          p:first-child {
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #575d6c;
            line-height: 20px;
            text-align: left;
            font-style: normal;
          }

          p:nth-child(2) {
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 20px;
            color: #f54030;
            line-height: 28px;
            text-align: left;
            font-style: normal;
            margin-left: 24px;
          }

          &:lang(ar),
          &:lang(pk) {
            margin-left: 0;
            margin-right: 10px;

            p:nth-child(2) {
              margin-left: 0;
              margin-right: 24px;
            }
          }

          .arco-typography {
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #999999;
            line-height: 20px;
            text-align: left;
            font-style: normal;
            margin-left: 12px;
          }
        }

        .course_sell {
          font-family: PingFang SC;
          font-weight: 600;
          font-size: 14px;
          color: #999999;
          line-height: 20px;
          text-align: left;
          font-style: normal;
          margin-right: 20px;

          :deep(span) {
            color: #f54030;
          }
        }

        .buyCount {
          margin-right: 10px;
          font-family: PingFang SC;
          font-weight: 600;
          font-size: 14px;
          color: #999999;

          :deep(span) {
            color: #f54030;
          }
        }
      }

      .course_special_service {
        display: flex;
        align-items: center;
        margin-top: 20px;

        p {
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
          line-height: 20px;
          text-align: left;
          font-style: normal;
          margin-left: 20px;
          display: flex;
          align-items: center;

          .special_service_icon {
            width: 16px;
            height: 16px;
            margin-right: 3px;
          }
        }

        p:first-child {
          color: #575d6c;
          margin-left: 0;
        }

        &:lang(en) {
          justify-content: space-between;

          p {
            font-size: 12px;
            margin: 0;
          }
        }

        &:lang(ar),
        &:lang(pk) {
          justify-content: space-between;

          p {
            font-size: 12px;
            margin: 0;

            .special_service_icon {
              margin-left: 3px;
              margin-right: 0;
            }
          }
        }
      }

      .course_detail_line {
        width: 550px;
        height: 1px;
        background: #ededed;
        margin-top: 20px;
      }

      .course_detail_btn_group {
        display: flex;
        align-items: center;
        margin-top: 35px;

        .arco-btn {
          min-width: 148px;
          height: 44px;
          background: #0678ff;
          border-radius: 4px;
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #ffffff;
          line-height: 25px;
          margin-right: 30px;
          text-align: center;
          font-style: normal;
        }

        &:lang(ar),
        &:lang(pk) {
          .arco-btn {
            width: auto;
            margin-right: 0;

            &:last-child {
              margin-left: 0;
              margin-right: 30px;

              .kefu_icon {
                margin-right: 0;
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
  }

  .course_content {
    width: 1200px;
    margin: 20px auto auto;
    display: flex;
    justify-content: space-between;

    .course_content_detail {
      width: 820px;
      min-height: 510px;
      background-color: #ffffff;

      :deep(.arco-tabs) {
        margin: 0 20px;
      }

      :deep(.arco-tabs-tab) {
        line-height: 50px;
        margin-left: 20px;

        &:nth-child(2) {
          margin-left: 100px;
        }
      }

      :deep(.arco-tabs-content) {
        padding-bottom: 20px;
      }

      .cardBody {
        padding: 0 20px;
        background: #ffffff;
        border-radius: 20px;

        .cardItem {
          border-radius: 12px;
          border: 1px solid #dfe3eb;
          background-color: #f5f7f9;
          width: 620px;

          .card_content {
            background-color: white;
            padding: 13px;
            border-radius: 12px;
            filter: blur(0px);
            display: flex;

            :deep(.arco-image-img) {
              width: 272px;
              height: 156px;
              border-radius: 12px;
            }

            .item_l {
              display: flex;
              justify-content: center;
              flex-direction: column;
              margin-left: 13px;

              .itemTitle {
                font-family: PingFang SC;
                font-weight: 500;
                font-size: 16px;
                color: #333333;
                line-height: 22px;
              }

              .itemSubTitle {
                font-family: PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #999999;
                line-height: 20px;
              }
            }
          }

          .card_footer {
            display: flex;
            justify-content: space-between;
            margin: 0 13px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 36px;
            font-size: 14px;
            color: #8d8d8d;

            span {
              color: #0678ff;
            }
          }

          &:not(&:first-child) {
            margin-top: 20px;
          }
        }
      }
    }

    .other_recommend {
      width: calc(360px - 40px);
      min-height: 510px;
      background-color: #ffffff;
      padding: 20px;
      height: fit-content;

      .other_recommend_title {
        font-family: PingFang SC;
        font-weight: 600;
        font-size: 18px;
        color: #333333;
        line-height: 25px;
        font-style: normal;
      }

      .recommend_item {
        display: flex;
        align-items: center;
        margin-top: 20px;

        :deep(.arco-image-img) {
          width: 156px;
          height: 88px;
        }

        .recommend_item_desc {
          height: 88px;
          margin-left: 16px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          &:lang(ar),
          &:lang(pk) {
            margin-left: 0;
            margin-right: 16px;
          }

          .arco-typography {
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #575d6c;
            line-height: 20px;
            font-style: normal;
            margin: 0;
          }

          .buy_count {
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #666666 !important;
            line-height: 17px;
            font-style: normal;
          }

          .cursor_price_no {
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #f54030;
            line-height: 20px;
            font-style: normal;
          }
        }
      }
    }
  }
}
</style>
