<!--直播课详情-->
<template>
  <div :lang="i18n.global.locale.value" class="live_detail_view">
    <div class="live_detail_breadcrumb w1200">
      <a-breadcrumb>
        <template #separator>
          <icon-double-right />
        </template>
        <a-breadcrumb-item style="cursor: pointer" @click="back">{{ $t('live.message.01') }}</a-breadcrumb-item>
        <a-breadcrumb-item>{{ $t('live.message.02') }}</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="detail_header arco-row arco-row-justify-space-between arco-row-nowrap">
      <a-image :src="useUrlCv(liveInfo.PicUrl)" class="detail_image" height="319" width="570"></a-image>
      <div class="live_right">
        <div class="arco-row arco-row-nowrap arco-row-align-center">
          <a-tag color="#0678FF">{{ $t('live.message.05') }}</a-tag>
          <a-typography-text :ellipsis="{ rows: 1 }" class="title">{{ liveInfo.Title }}</a-typography-text>
        </div>
        <div class="arco-row arco-row-nowrap arco-row-align-center mt30">
          <svg-icon class-name="calendar_icon" icon-class="calendar"></svg-icon>
          <p class="sub_title">{{ $t('live.message.06') }} {{ dateStr(liveInfo.LiveStartTime) }} （{{ dateTimeStr(liveInfo.LiveStartTime, liveInfo.LiveEndTime) }}）</p>
        </div>
        <div class="arco-row arco-row-nowrap arco-row-align-center live_t mt20">
          <p>{{ $t('live.message.04') }}：{{ liveInfo.LiveTeacher }}</p>
        </div>
        <a-divider />
        <div class="arco-row arco-row-nowrap arco-row-align-center">
          <div class="desc_text desc_item">{{ $t('detail.course.characteristic.service') }}</div>
          <div class="arco-row arco-row-nowrap arco-row-align-center desc_item">
            <svg-icon class-name="choiceness_icon" icon-class="choiceness"></svg-icon>
            <p class="desc_text">{{ $t('live.message.07') }}</p>
          </div>
          <div class="arco-row arco-row-nowrap arco-row-align-center desc_item">
            <svg-icon class-name="profile_light_icon" icon-class="profile_light"></svg-icon>
            <p class="desc_text">{{ $t('live.message.08') }}</p>
          </div>
          <div class="arco-row arco-row-nowrap arco-row-align-center desc_item">
            <svg-icon class-name="group_icon" icon-class="group"></svg-icon>
            <p class="desc_text">{{ $t('live.message.09') }}</p>
          </div>
        </div>
        <a-divider />
      </div>
    </div>

    <div class="w1200 live_body arco-row arco-row-nowrap">
      <div class="body_l">
        <a-tabs default-active-key="1">
          <a-tab-pane key="1" :title="$t('live.message.10')">
            <div v-rich-text-src="liveInfo.Detail" class="rich_style"></div>
          </a-tab-pane>
        </a-tabs>
      </div>
      <div class="body_r">
        <p class="body_r_title">{{ $t('live.message.11') }}</p>
        <template v-for="(item, index) in tableList">
          <div v-if="index < 4" class="arco-row arco-row-align-center arco-row-nowrap mt20 pointer" @click="changeLive(item)">
            <a-image :preview="false" :src="useUrlCv(item.PicUrl)"></a-image>
            <div class="item_text">
              <a-typography-text :ellipsis="{ rows: 2 }" class="item_text_desc">{{ item.Title }}</a-typography-text>
              <p class="item_text_desc_t">{{ $t('live.message.04') }}：{{ item.LiveTeacher }}</p>
            </div>
          </div>
        </template>
        <Empty v-if="tableList.length === 0" />
      </div>
    </div>
  </div>
</template>

<script setup>
import SvgIcon from '@/components/svgIcon/index.vue';
import { useRouter } from 'vue-router';
import { onMounted, reactive, watch } from 'vue';
import request from '@/common/request.js';
import { hideLoadingProcess, showLoadingProcess, useToCurrentPage } from '@/hooks/page.js';
import moment from 'moment';
import { useUrlCv } from '@/hooks/format.js';
import Empty from '@/components/empty/index.vue';
import i18n from '@/locales/index.js';

const router = useRouter();
const tableList = reactive([]);
const liveInfo = reactive({
  Id: '',
  LiveStartTime: '',
  LiveEndTime: '',
  LiveTeacher: '',
  Title: '',
  PicUrl: '',
  Detail: '',
});

const back = () => {
  useToCurrentPage('/live/timeline');
};

const dateStr = (LiveStartTime) => {
  return moment(LiveStartTime).format('MM/DD');
};

const dateTimeStr = (LiveStartTime, LiveEndTime) => {
  return moment(LiveStartTime).format('HH:mm') + '-' + moment(LiveEndTime).format('HH:mm');
};

const changeLive = (item) => {
  useToCurrentPage('/live/detail/' + item.Id);
};

watch(
  () => router.currentRoute.value,
  (value, oldValue) => {
    showLoadingProcess();
    request({
      url: '/Live/GetLiveDetails',
      method: 'GET',
      params: {
        Id: value.params.id,
      },
    })
      .then((resp) => {
        Object.assign(liveInfo, resp.data);

        //获取所有直播，剔除当前直播
        tableList.length = 0;
        request({
          url: '/Live/GetLiveList',
          method: 'GET',
        }).then((resp) => {
          resp.data.forEach((item) => {
            item.Items.forEach((live) => {
              if (live.Id !== value.params.id) {
                tableList.push(live);
              }
            });
          });
          console.log(tableList);
        });
      })
      .finally(() => {
        hideLoadingProcess();
      });
  },
  { immediate: true }
);
</script>

<style lang="less" scoped>
.live_detail_view {
  background-color: #f5f7f9;

  .live_detail_breadcrumb {
    margin: 88px auto 0;
    padding: 30px 0;
  }

  .detail_header {
    margin: 0 auto;
    width: calc(1200px - 60px);
    padding: 30px;
    background-color: #ffffff;

    .detail_image {
      width: 570px;
      height: 319px;
    }

    .live_right {
      width: 100%;
      margin-left: 20px;

      .title {
        font-family: PingFang SC;
        flex-grow: 1;
        font-weight: 600;
        font-size: 24px;
        margin: 0 10px;
        color: #333333;
      }
    }

    .arco-tag {
      height: 26px;
      border-radius: 4px;
      background-color: #ffffff !important;
      border: 1px solid #0678ff;

      font-family: PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #0678ff;
      line-height: 28px;
    }
  }

  .choiceness_icon,
  .profile_light_icon,
  .group_icon {
    width: 16px;
    height: 16px;
  }

  .calendar_icon {
    width: 22px;
    height: 22px;
  }

  .arco-divider {
    margin: 40px 0;
  }

  .sub_title {
    font-family: PingFang SC;
    font-weight: 400;
    margin: 0 3px;
    font-size: 14px;
    color: #adafca;
  }

  .live_t {
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #575d6c;
  }

  .desc_text {
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    margin: 0 2px;
  }

  .desc_item {
    &:nth-child(n + 2) {
      margin: 0 10px;
    }
  }

  .live_body {
    margin: 20px auto;

    .rich_style {
      font-size: 14px;
      line-height: 25px;
      min-height: 500px;
    }

    .body_l {
      width: calc(820px - 50px);
      background-color: #ffffff;
      padding: 25px;
    }

    .body_r {
      flex-grow: 1;
      width: 360px;
      margin-left: 20px;
      background-color: #ffffff;
      padding: 25px;

      .body_r_title {
        font-family: PingFang SC;
        font-weight: 600;
        font-size: 18px;
        color: #333333;
      }

      .item_text {
        height: calc(88px - 16px);
        padding: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;

        .item_text_desc {
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: #575d6c;
          line-height: 20px;
        }

        .item_text_desc_t {
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #666666;
          line-height: 17px;
        }
      }

      :deep(.arco-image-img) {
        width: 156px;
        height: 88px;
        border-radius: 6px;
      }
    }
  }

  &:lang(pk),
  &:lang(ar) {
    .live_right {
      margin-left: 0;
      margin-right: 20px;
    }
  }
}
</style>
