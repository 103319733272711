<!--词典-->
<template>
  <div :lang="i18n.locale.value" class="dictionaries">
    <div class="dictionaries_center">
      <p class="dictionaries_title">{{ $t('dictionaries.glossary.1') }}</p>
      <p class="dictionaries_desc">{{ $t('dictionaries.glossary.2') }}</p>
      <a-input-search v-model="queryParams.word" :placeholder="$t('dictionaries.glossary.4')" allow-clear class="search_btn" search-button @clear="allClear" @search="allSearch"></a-input-search>
    </div>
    <p class="dictionaries_p">{{ $t('dictionaries.glossary.3') }}</p>
    <div class="dictionaries_body w1200">
      <div class="body_l">
        <XyzTransitionGroup xyz="fade small stagger">
          <div v-if="show" class="body_l_header">
            <div>
              <p class="pinyin">[ {{ currentDict?.Tone }} ]</p>
              <div class="header_view">
                <p>{{ currentDict?.DicWord }}</p>
                <SvgIcon id="svg" class-name="megaphone_icon" icon-class="megaphone" @click="playAudio" />
                <img id="img" class="megaphone_icon" src="@/assets/images/audioPlay.gif" style="display: none" @click="playAudio" />
              </div>
              <p :style="{ visibility: currentDict.Word ? '' : 'hidden' }" class="pinyin">[ {{ currentDict?.Word }} ]</p>
            </div>
            <a-select v-model="queryParams.lang" @change="changeLang">
              <template #label>
                <div class="arco-row arco-row-align-center">
                  <a-image v-if="queryParams.lang === 'zh'" :preview="false" :src="zhImg" class="imageFlag"></a-image>
                  <a-image v-if="queryParams.lang === 'id'" :preview="false" :src="idImg" class="imageFlag"></a-image>
                  <a-image v-if="queryParams.lang === 'ar'" :preview="false" :src="arImg" class="imageFlag"></a-image>
                  <a-image v-if="queryParams.lang === 'en'" :preview="false" :src="enImg" class="imageFlag"></a-image>
                  <a-image v-if="queryParams.lang === 'pk'" :preview="false" :src="pkImg" class="imageFlag"></a-image>
                  <a-typography-text bold>{{ useGetDictKey('language', queryParams.lang) }}</a-typography-text>
                </div>
              </template>
              <a-option v-for="(item, index) in dict.language" :key="index" :value="item.value">
                <div class="arco-row arco-row-align-center p5">
                  <a-image v-if="item.value === 'zh'" :preview="false" :src="zhImg" class="imageFlag"></a-image>
                  <a-image v-if="item.value === 'id'" :preview="false" :src="idImg" class="imageFlag"></a-image>
                  <a-image v-if="item.value === 'ar'" :preview="false" :src="arImg" class="imageFlag"></a-image>
                  <a-image v-if="item.value === 'en'" :preview="false" :src="enImg" class="imageFlag"></a-image>
                  <a-image v-if="item.value === 'pk'" :preview="false" :src="pkImg" class="imageFlag"></a-image>
                  <p class="imageFlagText">{{ item.label }}</p>
                </div>
              </a-option>
            </a-select>
          </div>
          <p v-if="show" class="example_text">{{ $t('dictionaries.glossary.7') }}</p>
          <p v-if="show" class="example" v-html="currentDict?.SentenceCN"></p>
          <p v-if="show" class="example01" v-html="currentDict?.Sentence"></p>
          <a-image v-if="show" :preview="false" :src="imgSrc"></a-image>
        </XyzTransitionGroup>
      </div>
      <div v-if="currentDict" class="body_r">
        <a-select v-model="queryParams.level" @change="changeLevel">
          <a-option v-for="(item, index) in opts" :key="index" :value="item.value">{{ $t(item.label) }}</a-option>
        </a-select>
        <a-input v-model="searchValue" :placeholder="$t('dictionaries.glossary.9')" allow-clear @clear="inputClear" @input="inputSearch">
          <template #suffix>
            <svg-icon class-name="search_w_icon" icon-class="search_w"></svg-icon>
          </template>
        </a-input>
        <div class="diction_container">
          <p v-for="(item, index) in dicShowList" :key="index" :class="item.Id == currentDict.Id ? 'activity_p' : ''" @click="selectDictEvent(item)">
            {{ item.DicWord }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <audio ref="audioRef" :src="audioSrc" :volumn="1"></audio>
</template>

<script setup>
import SvgIcon from '@/components/svgIcon/index.vue';
import { computed, onMounted, reactive, ref } from 'vue';
import request from '@/common/request.js';
import { dict, useGetDictKey } from '@/hooks/sysDict.js';
import { cloneDeep } from 'lodash';
import { hideLoadingProcess, showLoadingProcess } from '@/hooks/page.js';
import { useCommonCacheStore } from '@/stores/commonCache.js';
import { useI18n } from 'vue-i18n';
import zhImg from '@/components/layout/images/zh.svg';
import idImg from '@/components/layout/images/id.svg';
import arImg from '@/components/layout/images/ar.svg';
import enImg from '@/components/layout/images/en.svg';
import pkImg from '@/components/layout/images/pk.svg';
import { useLocalCacheStore } from '@/stores/localCache.js';

const localCache = useLocalCacheStore();
const audioRef = ref();
const searchValue = ref('');
const commonCache = useCommonCacheStore();
const show = ref(false);
const i18n = useI18n();
const queryParams = reactive({
  lang: localCache.defaultLanguage,
  level: '一级',
  word: '',
});
const opts = reactive([
  { label: 'dictionaries.glossary.10', value: '一级' },
  { label: 'dictionaries.glossary.11', value: '二级' },
  { label: 'dictionaries.glossary.12', value: '三级' },
  { label: 'dictionaries.glossary.13', value: '四级' },
  { label: 'dictionaries.glossary.14', value: '五级' },
  { label: 'dictionaries.glossary.15', value: '六级' },
]);

const dicList = reactive([]);
const dicShowList = reactive([]);
const currentDict = ref(undefined);

/**
 * 处理图片路径
 * @param item
 * @returns {string}
 */
const imgSrc = computed(() => {
  return import.meta.env.VITE_APP_IMG_B_BASE_URL + 'dic/gif/' + currentDict.value?.PicGIF;
});

/**
 * 处理音频路径
 * @type {ComputedRef<string>}
 */
const audioSrc = computed(() => {
  return import.meta.env.VITE_APP_IMG_B_BASE_URL + 'dic/mp3/' + currentDict.value?.AudioMP3;
});

/**
 * 播放音频
 */
const playAudio = () => {
  audioRef.value.load();
  audioRef.value.play();
};

const allClear = () => {
  queryParams.level = '';
  queryParams.word = '';
  getList();
};

/**
 * 选择词典
 */
const selectDictEvent = (item) => {
  document.getElementById('svg').style.display = 'block';
  document.getElementById('img').style.display = 'none';
  currentDict.value = item;
  getDetail(item.Id);
};

const changeLang = (lang) => {
  getDetail(currentDict.value.Id);
};

/**
 * 获取详情
 */
const getDetail = (Id) => {
  show.value = false;
  request({
    url: '/Dic/GetDicById',
    method: 'get',
    params: {
      Id: Id,
      lang: queryParams.lang,
    },
  })
    .then((resp) => {
      currentDict.value = resp.data;
    })
    .finally(() => {
      show.value = true;
    });
};

const allSearch = () => {
  queryParams.level = '';
  getList();
};

const inputSearch = (val) => {
  dicShowList.length = 0;
  const filterArr = dicList.filter((item) => {
    return item.DicWord.indexOf(val) > -1;
  });
  if (val == '') {
    dicShowList.push(...dicList);
  }
  dicShowList.push(...filterArr);
};

const inputClear = () => {
  dicShowList.length = 0;
  dicShowList.push(...dicList);
};

const changeLevel = () => {
  searchValue.value = '';
  getList();
};

/**
 * 搜索词典
 */
const getList = () => {
  showLoadingProcess();
  request({
    url: '/Dic/GetDicList',
    method: 'get',
    params: queryParams,
  })
    .then((resp) => {
      dicList.length = 0;
      if (resp.data.length == 0) {
        dicShowList.length = 0;
        return;
      }
      dicList.push(...resp.data);
      dicShowList.length = 0;
      dicShowList.push(...cloneDeep(dicList));
      currentDict.value = dicShowList[0];
      queryParams.level = currentDict.value.Level;
      getDetail(currentDict.value.Id);
    })
    .finally(() => {
      hideLoadingProcess();
    });
};
getList();

onMounted(() => {
  commonCache.setHeaderWhiteTheme();
  audioRef.value.addEventListener('playing', () => {
    document.getElementById('svg').style.display = 'none';
    document.getElementById('img').style.display = 'block';
  });
  audioRef.value.addEventListener('pause', () => {
    document.getElementById('svg').style.display = 'block';
    document.getElementById('img').style.display = 'none';
  });
});
</script>

<style lang="less" scoped>
.dictionaries_center {
  width: 100%;
  height: 400px;
  text-align: center;
  background-image: url('@/assets/images/cidian_bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .dictionaries_title {
    font-family: AlimamaShuHeiTi;
    font-size: 42px;
    color: #0971f8;
    line-height: 42px;
    font-weight: bold;
    text-align: center;
    font-style: normal;
    padding-top: 103px;
  }

  .dictionaries_desc {
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 24px;
    color: #333333;
    line-height: 24px;
    text-align: center;
    font-style: normal;
    padding-top: 20px;
  }

  .search_btn {
    width: 770px;
    height: 54px;
    margin-top: 35px;
    background: #ffffff;
    border-radius: 8px;

    :deep(.arco-input-wrapper) {
      border: 0;

      &:hover {
        background-color: #ffffff;
      }
    }

    :deep(.arco-input-append) {
      width: 110px;

      .arco-btn {
        width: 100%;
        height: 100%;
        border-radius: 0 8px 8px 0;

        &-icon {
          font-size: 36px;
        }
      }
    }
  }
}

.dictionaries_p {
  text-align: center;
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 42px;
  color: #333333;
  line-height: 59px;
  font-style: normal;
  padding: 30px 30px;
}

.dictionaries {
  width: 100vw;
  display: inline-block;
  min-height: 80vh;
  margin-top: 88px;
  background-color: #f8f8f8;
  padding-bottom: 149px;

  &:lang(ar),
  &:lang(pk) {
    :deep(.arco-input-append) {
      .arco-btn {
        border-radius: 8px 0 0 8px;
      }
    }
  }
}

.dictionaries_view {
  margin: 50px auto;
  display: flex;
  justify-content: center;
  align-items: center;

  .arco-input-wrapper {
    margin-left: 20px;
    width: 546px;
    height: 52px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #999999;
  }

  .arco-select-view-single {
    width: 120px;
    height: 50px !important;
    background: #f4f9fc;
    border-radius: 4px;
    border: 0;

    .arco-image {
      height: 30px;

      .arco-image-img {
        height: 30px;
      }
    }
  }
}

.search_1_icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.dictionaries_body {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  .body_l {
    width: 800px;
    height: 60vh;
    background: #ffffff;
    border-radius: 6px;
    padding: 56px 31px;
    box-shadow: 0px 0 8px 0px rgba(134, 134, 134, 0.08);

    .body_l_header {
      display: flex;
      justify-content: space-between;

      .header_view {
        display: flex;
        align-items: center;

        p {
          height: 50px;
          font-family: PingFang SC;
          font-weight: 600;
          font-size: 36px;
          color: #333333;
          line-height: 50px;
          text-align: left;
          font-style: normal;
        }
      }

      .pinyin {
        font-size: 18px;
        font-weight: 400;
        padding-left: 2px;

        &:first-child {
          padding-bottom: 8px;
          color: #999999;
        }

        &:last-child {
          padding-top: 8px;
          color: #0678ff;
        }
      }

      :deep(.arco-select-view-single) {
        width: 180px;
        height: 40px;
        background: #f4f9fc;
        border-radius: 4px;

        img {
          width: 20px;
        }

        .arco-image {
          margin: 0 5px !important;
        }
      }
    }

    :deep(.arco-image) {
      margin-top: 25px;

      .arco-image-img {
        width: 300px;
      }
    }

    .example_text {
      margin-top: 25px;
      font-family: PingFang SC;
      font-weight: 600;
      font-size: 18px;
      color: #333333;
      line-height: 25px;
      font-style: normal;
    }

    .example {
      direction: ltr;
      margin-top: 10px;
      white-space: pre-wrap;
      color: #0678ff;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 30px;
      font-size: 18px;
      font-style: normal;
    }

    .example01 {
      direction: ltr;
      margin-top: 10px;
      white-space: pre-wrap;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 30px;
      font-size: 18px;
      font-style: normal;
    }
  }

  .body_r {
    width: 320px;
    background: #ffffff;
    box-shadow: 0px 0 8px 0px rgba(134, 134, 134, 0.08);
    border-radius: 6px;
    text-align: center;

    .search_w {
      width: 22px;
      height: 22px;
    }

    :deep(.arco-input-wrapper) {
      width: 300px;
      height: 46px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #979797;
      margin-top: 25px;
    }

    :deep(.arco-select-view-single) {
      width: 300px;
      height: 46px;
      background: #ffffff;
      border-radius: 4px;
    }

    .diction_container {
      text-align: left;
      height: 58vh;
      overflow-y: auto;

      p {
        width: 280px;
        margin: 10px auto 0;
        height: 50px;
        padding-left: 20px;
        font-family: PingFang SC;
        cursor: pointer;
        font-weight: 400;
        font-size: 16px;
        border-radius: 4px;
        line-height: 50px;
      }

      .activity_p {
        background: #0678ff;
        color: #ffffff;
      }
    }
  }

  .megaphone_icon {
    width: 22px;
    height: 22px;
    margin-left: 10px;
    cursor: pointer;
  }

  :deep(.arco-select-view-single.arco-select-view-focus) {
    border: 0;

    &:focus-visible {
      border: 0;
    }
  }
}

.imageFlag {
  width: 20px;

  :deep(.arco-image-img) {
    width: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.imageFlagText {
  padding: 0 5px;
}

.p5 {
  padding: 5px 0;
}
</style>
