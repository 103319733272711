<!--我的套餐-->
<template>
  <div :lang="i18n.locale.value" class="my_setmeal">
    <div class="my_setmeal_header">
      <p class="activated">{{ $t('individual.center.all') }}</p>
    </div>
    <XyzTransition duration="auto" xyz="fade small">
      <div v-if="loading" class="my_setmeal_body">
        <div v-for="(item, index) in courseList.list" :key="index" class="course_item square xyz-nested" @click="useToCurrentPage('/setMeal/detail/' + item.Id)">
          <a-image :preview="false" :src="useUrlCv(item.CoverPicURL)" />
          <div class="item_text">
            <a-typography-text class="item_text_title" ellipsis>{{ item.Title }}</a-typography-text>
            <a-typography-text :ellipsis="{ rows: 2 }" class="item_text_Introduction">{{ item.Introduction }}</a-typography-text>
            <div class="arco-row arco-row-justify-space-between arco-row-align-center">
              <div class="validityView arco-row arco-row-align-center">
                <p>{{ $t('validity.message.01') }}:</p>
                <p>{{ $t('validity.message.02', { value: item.RemainingDays }) }}</p>
              </div>
              <div class="item_text_desc">
                <a-typography-text>{{ useFormatDate(item.BuyTime, 'YYYY.MM.DD') }}</a-typography-text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </XyzTransition>
    <Empty v-if="courseList.total === 0 && loading == true" />
    <a-pagination v-if="courseList.total !== 0" v-model:current="queryParams.pageIndex" v-model:page-size="queryParams.pageSize" :total="courseList.total" @change="getList" />
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue';
import { useToCurrentPage, useToHome, useToTabPage } from '@/hooks/page.js';
import SvgIcon from '@/components/svgIcon/index.vue';
import request from '@/common/request.js';
import { useFormatDate, useUrlCv } from '@/hooks/format.js';
import { useCheckIsLogin, useGetUserinfo } from '@/hooks/commomApi.js';
import { useI18n } from 'vue-i18n';

const loading = ref(false);
const videoProcessList = reactive([]);
const i18n = useI18n();
const courseList = reactive({
  list: [],
  total: 0,
});

const queryParams = reactive({
  pageIndex: 1,
  pageSize: 6,
});

/**
 * 查询我的课程
 */
const getList = () => {
  useCheckIsLogin(
    () => {},
    false,
    () => {
      useToHome();
    }
  );
  loading.value = false;
  request({
    url: '/Package/MyPackage',
    method: 'get',
    params: {
      pageIndex: queryParams.pageIndex,
      pageSize: queryParams.pageSize,
    },
  })
    .then((resp) => {
      queryParams.pageIndex = resp.data.PageIndex;
      queryParams.PageSize = resp.data.PageSize;
      courseList.total = resp.data.Total;
      courseList.list = resp.data.DataList;
      // courseList.list.forEach((item) => {
      //   const findObj = videoProcessList.find((video) => {
      //     return item.Id === video.CourseId;
      //   });
      //   item['videoInfo'] = findObj;
      // });
    })
    .finally(() => {
      loading.value = true;
    });
};

onMounted(() => {
  //获取所有课程的学习进度
  request({
    url: '/Course/GetLastStudyCourseVideo',
    method: 'get',
  }).then((resp) => {
    videoProcessList.length = 0;
    resp.data.forEach((item) => {
      if (item) {
        videoProcessList.push(item);
      }
    });
    getList();
  });
});
</script>

<style lang="less" scoped>
.my_setmeal {
  .my_setmeal_header {
    padding: 10px 10px 20px 10px;
    border-bottom: 1px solid #e2e2e2;
    display: flex;

    p {
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #666666;
      line-height: 22px;
      text-align: left;
      font-style: normal;
      cursor: pointer;
      margin-left: 50px;

      &:hover {
        color: #0678ff;
      }

      &:first-child {
        margin-left: 0;
      }
    }

    .activated {
      color: #0678ff;
    }
  }

  .my_setmeal_body {
    display: grid;
    grid-template-columns: 266px 266px 266px;
    grid-gap: 22px;
    margin: 30px auto;

    .course_item {
      width: 266px;
      min-height: 233px;
      background: #ffffff;
      box-shadow: 0 0 9px 0 rgba(1, 10, 29, 0.15);
      cursor: pointer;
      border-radius: 11px;

      :deep(.arco-image) {
        width: 100%;
      }

      :deep(.arco-image-img) {
        border-radius: 11px 11px 0 0;
        width: 100%;
        height: 147px;
      }

      .item_text {
        padding: 11px 9px;

        .validityView {
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #999999;
          line-height: 17px;
          text-shadow: 0 0 6px rgba(1, 10, 29, 0.15);
        }

        .item_text_title {
          margin: 0;
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 15px;
          color: #333333;
          line-height: 21px;
          text-shadow: 0 0 9px rgba(1, 10, 29, 0.15);
          font-style: normal;
        }

        .item_text_Introduction {
          margin-top: 6px;
          margin-bottom: 10px;
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          line-height: 20px;
          text-shadow: 0 0 6px rgba(1, 10, 29, 0.15);
        }

        .item_text_desc {
          .arco-typography {
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #999999;
            line-height: 17px;
            text-shadow: 0 0 6px rgba(1, 10, 29, 0.15);
          }
        }
      }
    }
  }

  &:lang(ar),
  &:lang(pk) {
    .my_setmeal_header {
      p {
        margin-left: 0;
        margin-right: 50px;
      }
    }
  }
}
</style>
