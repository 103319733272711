<!--课程章节-->
<template>
  <div :lang="i18n.locale.value" class="collapse_view">
    <a-collapse :default-active-key="activeKey" :destroy-on-hide="true" expand-icon-position="right" @change="collapseChange">
      <a-collapse-item v-for="item in chapterList" :key="item.Id" :header="item.Title">
        <template #expand-icon="{ active }">
          <template v-if="active">
            <icon-down />
          </template>
          <template v-else>
            <icon-right v-if="i18n.locale.value !== langToBack.ar" />
            <icon-left v-else />
          </template>
        </template>
        <div v-for="(video, videoIndex) in item.videoList" :key="videoIndex" class="chapter_item">
          <div class="chapter_item_title">
            <div :lang="i18n.locale.value" class="title_view">
              <SvgIcon class-name="play_circle_icon" icon-class="play-circle"></SvgIcon>
              <span>{{ video.Title }}</span>
            </div>
            <div class="chapter_item_time">{{ useSecondsToMinutes(video.TotalTime) }}</div>
          </div>

          <!-- 试播 -->
          <a-button v-if="btnType(video) === 'tryPlay'" type="outline" @click="playVideoEvent(item, video)">
            {{ $t('detail.records.try') }}
          </a-button>
          <!--    付费，并且无试播 && 套餐播放次数没有了       -->
          <icon-lock v-if="btnType(video) === 'lock'" :size="20" color="#999999" />
          <!-- 免费或者已购买  -->
          <a-button v-if="btnType(video) === 'play'" :class="playNameText(video) ? 'alreadyPlay' : ''" type="outline" @click="playVideoEvent(item, video)">
            {{ playNameText(video) ? use$t('chapter.message.01') : use$t('detail.records.Play') }}
          </a-button>
        </div>
      </a-collapse-item>
    </a-collapse>
  </div>
</template>
<script setup>
import request from '@/common/request.js';
import { computed, reactive, ref } from 'vue';
import { useSecondsToMinutes } from '@/hooks/format.js';
import { useToCurrentPage, useToTabPage } from '@/hooks/page.js';
import { useCheckBuyCourse, useCheckIsLogin } from '@/hooks/commomApi.js';
import { useI18n } from 'vue-i18n';
import { langToBack } from '@/hooks/sysDict.js';
import { useLocalCacheStore } from '@/stores/localCache.js';
import { use$t } from '@/hooks/language.js';

const btnType = (video) => {
  if ($props.freeType == '2') {
    //免费
    return 'play';
  } else {
    //付费
    if (isBuy.value === true) {
      //已购买
      if (setMealByCourse.Total != 0) {
        //套餐
        if (video.NewUserTrialTotal == '1') {
          return 'tryPlay';
        } else {
          if (setMealCourse(video)) {
            return 'play';
          } else {
            return 'lock';
          }
        }
      } else {
        //单课程
        return 'play';
      }
    } else {
      //未购买
      if (video.NewUserTrialTotal == '1' && video.TrialTime != 0) {
        return 'tryPlay';
      } else {
        return 'lock';
      }
    }
  }
};

const i18n = useI18n();
const isBuy = ref(false);
const localCache = useLocalCacheStore();
const setMealByCourse = reactive({
  List: [],
  Total: 0,
});
const $props = defineProps({
  sign: {
    type: String,
    required: true,
  },
  courseId: {
    type: String,
    required: true,
  },
  freeType: {
    type: Number || String,
    required: true,
  },
});

const activeKey = reactive([]);
const chapterList = reactive([]);

/**
 * 展开/折叠
 * @param activeKey
 * @param ev
 */
const collapseChange = (activeKey, ev) => {
  // activeKey.forEach((item) => {
  //   const findObj = chapterList.find((chapter) => {
  //     return chapter.Id === item;
  //   });
  //   if (findObj.videoList.length === 0) {
  //     request({
  //       url: '/Video/GetVideoListByChapterId',
  //       method: 'get',
  //       params: {
  //         sign: $props.sign,
  //         chapterId: item,
  //       },
  //     }).then((resp) => {
  //       findObj.videoList = resp.data;
  //     });
  //   }
  // });
};

const playNameText = (video) => {
  const id = localCache.userinfo.Id;
  if (id) {
    let item = localStorage.getItem(id + video.Id);
    if (item === true || item === 'true') {
      return true;
    }
  }
  return false;
};

const setMealCourse = (video) => {
  if (setMealByCourse.Total === setMealByCourse.List.length) {
    let filter = setMealByCourse.List.filter((item) => item.VideoId === video.Id);
    if (filter.length === 0) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};

const playVideoEvent = (item, video) => {
  //登录判断
  useCheckIsLogin(() => {
    //课程是否免费，2免费1付费
    if ($props.freeType == 2) {
      //加入我的学习
      request({
        url: '/Course/BuyCourse',
        method: 'get',
        params: {
          courseId: $props.courseId,
        },
      }).then((resp) => {
        useToCurrentPage('/coursePlayer', {
          sign: $props.sign,
          chapterId: item.Id,
          videoId: video.Id,
          courseId: $props.courseId,
        });
      });
    } else if ($props.freeType == 1) {
      useToCurrentPage('/coursePlayer', {
        sign: $props.sign,
        chapterId: item.Id,
        videoId: video.Id,
        courseId: $props.courseId,
      });
    }
  });
};

//获取该课程套餐信息
request({
  url: '/Video/GetLearnedVideoList',
  method: 'get',
  params: {
    courseId: $props.courseId,
  },
})
  .then((resp) => {
    setMealByCourse.List = resp.data.List || [];
    setMealByCourse.Total = resp.data.Total;
  })
  .catch((err) => {
    setMealByCourse.List = resp.data.List || [];
    setMealByCourse.Total = resp.data.Total;
  });

//获取所有章节
request({
  url: '/Chapter/GetChapterListByCourseId',
  method: 'get',
  params: {
    sign: $props.sign,
  },
}).then(async (resp) => {
  chapterList.length = 0;
  chapterList.push(...resp.data);

  chapterList.forEach((item) => {
    activeKey.push(item.Id);
    request({
      url: '/Video/GetVideoListByChapterId',
      method: 'get',
      params: {
        sign: $props.sign,
        chapterId: item.Id,
      },
    }).then((resp) => {
      item['videoList'] = resp.data;
    });
  });

  try {
    let promise = await useCheckBuyCourse($props.courseId, $props.freeType);
    if (promise.data !== 0) {
      isBuy.value = true;
    }
  } catch (e) {
    isBuy.value = false;
  }
});
</script>

<style lang="less" scoped>
.collapse_view {
  .arco-collapse {
    border: 0 !important;
  }

  :deep(.arco-collapse-item) {
    border: 0;
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }
  }

  :deep(.arco-collapse-item-header) {
    border: 0;
    height: 56px;
    background: #f5f8fa;
    border-radius: 6px;

    .arco-icon {
      width: 19px;
      height: 19px;
      color: #a1a5b2;
      margin-right: 15px;
    }

    .arco-collapse-item-header-title {
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #333333;
      line-height: 22px;
      text-align: left;
      font-style: normal;
      margin-left: 8px;
    }
  }

  :deep(.arco-collapse-item-content) {
    padding: 0;
  }

  :deep(.arco-collapse-item-content-box) {
    background-color: #ffffff;
    padding: 0 20px;
  }

  .chapter_item {
    padding: 10px 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eeeeee;

    &:last-child {
      border: 0;
    }

    .chapter_item_title {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .title_view {
        display: flex;

        .play_circle_icon {
          width: 16px;
          height: 16px;
          margin-top: 3px;
        }

        span {
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: rgba(51, 51, 51, 0.83);
          line-height: 22px;
          font-style: normal;
          margin-left: 10px;
        }

        &:lang(ar),
        &:lang(pk) {
          span {
            margin-left: 0;
            margin-right: 10px;
          }
        }
      }

      :deep(.arco-btn) {
        width: auto !important;
        padding-left: 5px;
        padding-right: 5px;
      }

      .arco-btn {
        width: 38px;
        height: 22px;
        background: #e3efff;
        border-radius: 4px;
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #0678ff;
        line-height: 22px;
        text-align: center;
        font-style: normal;
      }
    }

    .alreadyPlay {
      background-color: rgba(76, 191, 37, 0.2) !important;
      color: #45cd19 !important;
      border: 1px solid rgba(76, 191, 37, 0.2) !important;

      &:hover:before {
        contain: unset !important;
      }

      &:hover {
        animation: unset !important;
      }
    }

    .chapter_item_time {
      width: 43px;
      height: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #575d6c;
      line-height: 20px;
      text-align: left;
      font-style: normal;
      margin-left: 26px;
      margin-top: 10px;
    }

    .arco-btn {
      height: 22px;
      font-size: 13px;
      padding: 8px;
      background: #e3efff;
      border-radius: 4px;
    }
  }

  &:lang(ar),
  &:lang(pk) {
    :deep(.arco-collapse-item-header-title) {
      margin-left: 0;
      margin-right: 8px;
    }

    :deep(.arco-collapse-item-icon-right) {
      right: unset;
      left: 10px;
    }
  }
}
</style>
