<!--我的课程-->
<template>
  <div :lang="i18n.locale.value" class="my_course">
    <div class="my_course_header">
      <p :class="queryParams.statu === '' ? 'activated' : ''" @click="changeType('')">{{ $t('individual.center.all') }}</p>
      <p :class="queryParams.statu === 0 ? 'activated' : ''" @click="changeType(0)">{{ $t('individual.center.studying') }}</p>
      <p :class="queryParams.statu === 1 ? 'activated' : ''" @click="changeType(1)">{{ $t('individual.center.finished') }}</p>
    </div>
    <XyzTransition duration="auto" xyz="fade small">
      <div v-if="loading" class="my_course_body">
        <div
          v-for="(item, index) in courseList.list"
          :key="index"
          class="course_item square xyz-nested"
          @click="useToTabPage('/coursePlayer', { sign: item.Sign, chapterId: item.videoInfo.ChapterId, videoId: item.videoInfo.VideoId, courseId: item.Id })"
        >
          <a-image :preview="false" :src="useUrlCv(item.CoverPicURL)" />
          <div class="item_text">
            <a-typography-text class="item_text_title" ellipsis>{{ item.Title }}</a-typography-text>
            <div class="item_text_desc">
              <a-typography-text :ellipsis="{ rows: 1 }">{{ item.videoInfo.Title }}</a-typography-text>
            </div>
            <div class="validityView arco-row arco-row-justify-space-between arco-row-align-center">
              <div class="arco-row">
                <p>{{ $t('validity.message.01') }}:</p>
                <p>{{ $t('validity.message.02', { value: item.remainingDays }) }}</p>
              </div>
              <a-typography-text>{{ useFormatDate(item.videoInfo.LastModifyTime, 'YYYY.MM.DD') }}</a-typography-text>
            </div>
          </div>
          <div class="course_item_mask">
            <SvgIcon v-show="false" class-name="check-circle_icon" icon-class="check-circle" />
            <SvgIcon class-name="time-circle_icon" icon-class="time-circle" />
            <div class="mask_text">
              {{ item.Statu == '0' ? $t('individual.center.studying') : $t('individual.center.finished') }}
            </div>
          </div>
        </div>
      </div>
    </XyzTransition>
    <Empty v-if="courseList.total === 0 && loading == true" />
    <a-pagination v-if="courseList.total !== 0" v-model:current="queryParams.pageIndex" v-model:page-size="queryParams.pageSize" :total="courseList.total" @change="getList" />
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue';
import { useToHome, useToTabPage } from '@/hooks/page.js';
import SvgIcon from '@/components/svgIcon/index.vue';
import request from '@/common/request.js';
import { useFormatDate, useUrlCv } from '@/hooks/format.js';
import { useCheckIsLogin, useGetUserinfo } from '@/hooks/commomApi.js';
import { useI18n } from 'vue-i18n';

const loading = ref(false);
const videoProcessList = reactive([]);
const i18n = useI18n();
const courseList = reactive({
  list: [],
  total: 0,
});

/**
 * 切换学习状态
 * @param val
 */
const changeType = (val) => {
  queryParams.statu = val;
  queryParams.pageIndex = 1;
  getList();
};

const queryParams = reactive({
  pageIndex: 1,
  pageSize: 6,
  statu: '', //0没学完，1学完
});

/**
 * 查询我的课程
 */
const getList = () => {
  useCheckIsLogin(
    () => {},
    false,
    () => {
      useToHome();
    }
  );
  loading.value = false;
  request({
    url: '/Course/StudyCourse',
    method: 'get',
    params: {
      pageIndex: queryParams.pageIndex,
      pageSize: queryParams.pageSize,
      statu: queryParams.statu,
    },
  })
    .then((resp) => {
      queryParams.pageIndex = resp.data.PageIndex;
      queryParams.PageSize = resp.data.PageSize;
      courseList.total = resp.data.Total;
      courseList.list = resp.data.DataList;
      courseList.list.forEach((item) => {
        const findObj = videoProcessList.find((video) => {
          return item.Id === video.CourseId;
        });
        item['videoInfo'] = findObj;
      });
    })
    .finally(() => {
      loading.value = true;
    });
};

onMounted(() => {
  //获取所有课程的学习进度
  request({
    url: '/Course/GetLastStudyCourseVideo',
    method: 'get',
  }).then((resp) => {
    videoProcessList.length = 0;
    resp.data.forEach((item) => {
      if (item) {
        videoProcessList.push(item);
      }
    });
    getList();
  });
});
</script>

<style lang="less" scoped>
.my_course {
  .my_course_header {
    padding: 10px 10px 20px 10px;
    border-bottom: 1px solid #e2e2e2;
    display: flex;

    p {
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #666666;
      line-height: 22px;
      text-align: left;
      font-style: normal;
      cursor: pointer;
      margin-left: 50px;

      &:hover {
        color: #0678ff;
      }

      &:first-child {
        margin-left: 0;
      }
    }

    .activated {
      color: #0678ff;
    }
  }

  .my_course_body {
    display: grid;
    grid-template-columns: 266px 266px 266px;
    grid-gap: 22px;
    margin: 30px auto;

    .course_item {
      width: 266px;
      height: 246px;
      background: #ffffff;
      box-shadow: 0 0 9px 0 rgba(1, 10, 29, 0.15);
      cursor: pointer;
      border-radius: 11px;

      :deep(.arco-image) {
        width: 100%;
      }

      :deep(.arco-image-img) {
        border-radius: 11px 11px 0 0;
        width: 100%;
        height: 147px;
      }

      .item_text {
        padding: 11px 9px;

        .validityView {
          font-family: PingFang SC;
          font-weight: 400;
          margin-top: 10px;
          font-size: 14px;
          color: #666666;
          line-height: 20px;
          text-shadow: 0 0 6px rgba(1, 10, 29, 0.15);

          p {
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #999999;
            line-height: 17px;
            text-shadow: 0 0 6px rgba(1, 10, 29, 0.15);
          }

          .arco-typography {
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #999999;
            line-height: 17px;
            text-shadow: 0 0 6px rgba(1, 10, 29, 0.15);
          }
        }

        .item_text_title {
          margin: 0;
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 15px;
          color: #333333;
          line-height: 21px;
          text-shadow: 0 0 9px rgba(1, 10, 29, 0.15);
          font-style: normal;
        }

        .item_text_desc {
          margin-top: 6px;

          .arco-typography {
            margin: 0;
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #666666;
            line-height: 20px;
            text-shadow: 0 0 6px rgba(1, 10, 29, 0.15);
          }
        }
      }

      .course_item_mask {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 266px;
        height: 147px;
        bottom: 244px;
        position: relative;
        background: rgba(0, 0, 0, 0.58);
        box-shadow: 0 0 6px 0 rgba(1, 10, 29, 0.15);
        backdrop-filter: blur(0.933321456865564px);
        border-radius: 11px 11px 0 0;

        .check-circle_icon {
          width: 46px;
          height: 46px;
        }

        .time-circle_icon {
          width: 46px;
          height: 46px;
        }

        .mask_text {
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 18px;
          color: #ffffff;
          line-height: 25px;
          text-shadow: 0 0 6px rgba(1, 10, 29, 0.15);
          margin-top: 10px;
        }
      }
    }
  }

  &:lang(ar),
  &:lang(pk) {
    .my_course_header {
      p {
        margin-left: 0;
        margin-right: 50px;
      }
    }
  }
}
</style>
