<!--电子教材-->
<template>
  <div class="learning_material">
    <div class="learning_material_title">{{ $t('player.course.electronic.textbook') }}</div>
    <div ref="materialRef" :style="{ '--backdrop': backdropHeight + 'px' }" class="learning_material_body">
      <div class="learning_material_word_view">
        <div class="learning_material_word_header">
          <div :lang="i18n.locale.value" class="word_l">1.{{ $t('player.course.electronic.word') }}</div>
          <div :lang="i18n.locale.value" class="word_r">
            <div class="lang_item">
              {{ $t('player.course.chinese.pinyin') }}：
              <div class="legend"></div>
            </div>
            <div class="lang_item">
              {{ $t('hzf.variable.word') }}：
              <div class="legend"></div>
            </div>
            <div class="lang_item">
              {{ $t('player.course.chinese') }}：
              <div class="legend"></div>
            </div>
            <a-button :class="showPinYinWord ? '' : 'closePyBtn'" @click="closePinYin('word')">
              {{ showPinYinWord ? $t('player.course.close.pinyin') : $t('player.course.open.pinyin') }}
            </a-button>
          </div>
        </div>
        <div class="learning_material_clause">
          <div v-for="(item, index) in wordEBookList" :key="index" class="cause_item">
            <div :lang="i18n.locale.value" class="item_l">
              <div class="item_l_u">
                <div v-for="(pinyin, $index) in item.PinYinList" :key="$index" class="item_l_u_cell">
                  <div :style="{ visibility: showPinYinWord ? '' : 'hidden' }">{{ pinyin }}</div>
                  <div>{{ item.ContentList[$index] }}</div>
                </div>
              </div>
              <div :style="{ visibility: item.TransferContent ? '' : 'hidden' }" class="item_l_d">
                {{ item.TransferContent }}
              </div>
            </div>
            <div :id="'item_' + item.Attach" :lang="i18n.locale.value" class="item_r" @click="playAudio($event, item)">
              <SvgIcon class-name="megaphone_icon" icon-class="megaphone" style="display: block" />
              <img class="megaphone_icon" src="@/assets/images/audioPlay.gif" style="display: none" />
            </div>
          </div>
          <Empty v-if="wordEBookList.length == 0" />
        </div>
      </div>
      <div class="learning_material_word_view learning_material_sentences_view">
        <div class="learning_material_word_header">
          <div :lang="i18n.locale.value" class="word_l">2.{{ $t('player.course.electronic.sentence') }}</div>
          <div :lang="i18n.locale.value" class="word_r">
            <div class="lang_item">
              {{ $t('player.course.chinese.pinyin') }}：
              <div class="legend"></div>
            </div>
            <div class="lang_item">
              {{ $t('hzf.variable.word') }}：
              <div class="legend"></div>
            </div>
            <div class="lang_item">
              {{ $t('player.course.chinese') }}：
              <div class="legend"></div>
            </div>
            <a-button :class="showPinYinSentences ? '' : 'closePyBtn'" @click="closePinYin('sentences')">
              {{ showPinYinSentences ? $t('player.course.close.pinyin') : $t('player.course.open.pinyin') }}
            </a-button>
          </div>
        </div>
        <div class="learning_material_clause">
          <div v-for="(item, index) in clauseEBookList" :key="index" class="cause_item">
            <a-scrollbar style="overflow: auto" type="track">
              <div class="item_l">
                <div class="item_l_u">
                  <div v-for="(cell, $index) in item.PinYinList" :key="$index" class="item_l_u_cell">
                    <div :style="{ visibility: showPinYinSentences ? '' : 'hidden' }">{{ cell }}</div>
                    <div>{{ item.ContentList[$index] }}</div>
                  </div>
                </div>
                <div :style="{ visibility: item.TransferContent ? '' : 'hidden' }" class="item_l_d">
                  <p>{{ item.TransferContent }}</p>
                </div>
              </div>
            </a-scrollbar>
            <div :id="'item_' + item.Attach" class="item_r" @click="playAudio($event, item)">
              <SvgIcon class-name="megaphone_icon" icon-class="megaphone" style="display: block" />
              <img class="megaphone_icon" src="@/assets/images/audioPlay.gif" style="display: none" />
            </div>
          </div>
          <Empty v-if="clauseEBookList.length == 0" />
        </div>
      </div>
    </div>
  </div>
  <audio ref="audioRef" :src="audioSrc" :volume="1"></audio>
</template>

<script setup>
import SvgIcon from '@/components/svgIcon/index.vue';
import { nextTick, onMounted, ref } from 'vue';
import request from '@/common/request.js';
import { useRoute } from 'vue-router';
import BusinessContextEvent from '@/common/BusinessContextEvent.js';
import { useUrlCv } from '@/hooks/format.js';
import { useCheckBuyCourse, useCheckIsLogin } from '@/hooks/commomApi.js';
import { useI18n } from 'vue-i18n';

const route = useRoute();
const i18n = useI18n();
const showPinYinWord = ref(true);
const showPinYinSentences = ref(true);
const wordEBookList = ref([]);
const clauseEBookList = ref([]);
const audioSrc = ref('');
const audioRef = ref();
const backdropHeight = ref(0);
const materialRef = ref();
const alreadyOwned = ref(false);
const currentId = ref('');

const closePinYin = (type) => {
  if (type === 'word') {
    showPinYinWord.value = !showPinYinWord.value;
  } else {
    showPinYinSentences.value = !showPinYinSentences.value;
  }
};

const $props = defineProps({
  FeeType: String | Number,
});

/**
 * 获取电子教材
 */
BusinessContextEvent.$off('getEBook').$on('getEBook', async (playerVideo) => {
  useCheckIsLogin(async () => {
    let promise = await useCheckBuyCourse(route.query.courseId, $props.FeeType);
    if (promise.data === 1) {
      alreadyOwned.value = true;
    }
    wordEBookList.value = [];
    clauseEBookList.value = [];
    //获取电子教材
    request({
      url: '/Video/GetEBookByVideoId',
      method: 'get',
      params: {
        sign: route.query.sign,
        videoId: playerVideo.Id,
        lang: i18n.locale.value === 'zh' || i18n.locale.value === 'en' ? 'en' : i18n.locale.value,
      },
    }).then((resp) => {
      resp.data.forEach((item) => {
        if (item.EBookType === 0) {
          //短教材
          wordEBookList.value.push({
            PinYinList: item.Pinyin.split('|'),
            ContentList: item.Content.split(''),
            TransferContent: item.TransferContent,
            Attach: item.Attach,
          });
        } else {
          //长教材
          clauseEBookList.value.push({
            PinYinList: item.Pinyin.split('|'),
            ContentList: item.Content.split(''),
            TransferContent: item.TransferContent,
            Attach: item.Attach,
          });
        }
      });
      nextTick(() => {
        if (alreadyOwned.value || playerVideo.TrialTime !== 0) {
          backdropHeight.value = 0;
        } else {
          backdropHeight.value = materialRef.value.offsetHeight - 40;
        }
      });
    });
  });
});

/**
 * 播放音频
 * @param item
 */
const playAudio = async ($event, item) => {
  let pattern = 'item_';
  let elements = document.querySelectorAll('[id*="' + pattern + '"]');
  elements.forEach((item) => {
    item.querySelector('svg').style.display = 'block';
    item.querySelector('img').style.display = 'none';
  });
  currentId.value = 'item_' + item.Attach;
  audioSrc.value = useUrlCv(item.Attach);
  await audioRef.value.load();
  await audioRef.value.play();
};

onMounted(() => {
  audioRef.value.addEventListener('playing', () => {
    document.getElementById(currentId.value).getElementsByTagName('svg')[0].style.display = 'none';
    document.getElementById(currentId.value).getElementsByTagName('img')[0].style.display = 'block';
  });
  audioRef.value.addEventListener('pause', () => {
    document.getElementById(currentId.value).getElementsByTagName('svg')[0].style.display = 'block';
    document.getElementById(currentId.value).getElementsByTagName('img')[0].style.display = 'none';
  });
});
</script>

<style lang="less" scoped>
.learning_material {
  width: 1200px;
  margin: auto;
  padding-top: 31px;

  .learning_material_title {
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 26px;
    color: #333333;
    line-height: 37px;
    text-align: left;
    font-style: normal;
  }

  .learning_material_body {
    background-color: #f8f8f8;
    padding: 20px 25px;
    margin-top: 15px;

    .learning_material_word_view {
      padding-bottom: 14px;

      .learning_material_word_header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e1e1e1;
        padding: 30px 0;

        .word_l {
          border-left: 4px solid #0678ff;
          padding-left: 20px;
          height: 25px;
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #333333;
          line-height: 25px;
          font-style: normal;

          &:lang(ar),
          &:lang(pk) {
            border-left: 0;
            padding-left: 0;
            border-right: 4px solid #0678ff;
            padding-right: 20px;
          }
        }

        .word_r {
          display: flex;

          .lang_item {
            display: flex;
            align-items: center;
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #98c2f4;
            line-height: 20px;
            font-style: normal;

            .legend {
              width: 29px;
              height: 20px;
              background: #98c2f4;
              border-radius: 2px;
            }

            &:nth-child(2) {
              color: #e2ba7e;
              margin-left: 33px;

              .legend {
                background: #e2ba7e;
              }
            }

            &:nth-child(3) {
              color: #0678ff;
              margin-left: 33px;

              .legend {
                background: #0678ff;
              }
            }
          }

          .arco-btn {
            width: auto;
            height: 40px;
            background: #ea951a;
            border-radius: 6px;
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #ffffff;
            line-height: 22px;
            text-align: center;
            font-style: normal;
            margin-left: 60px;
          }

          .closePyBtn {
            background: #f8f8f8;
            border: 1px solid #0678ff;
            color: #0678ff;
          }

          &:lang(ar),
          &:lang(pk) {
            :deep(.arco-btn) {
              margin-left: 0;
              margin-right: 10px;
            }

            .lang_item {
              margin-left: 0;

              &:nth-child(2) {
                margin-right: 10px;
              }

              &:nth-child(3) {
                margin-right: 10px;
              }
            }
          }
        }
      }

      .learning_material_clause {
        display: flex;
        direction: ltr;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        gap: 14px;
        padding-top: 14px;

        .cause_item {
          min-width: 80px;
          height: 50px;
          padding: 6px 11px;
          background: #ffffff;
          box-shadow: 0 2px 3px 0 rgba(187, 187, 187, 0.21);
          border-radius: 4px;
          display: flex;
          align-items: center;

          .item_l {
            .item_l_u {
              display: flex;
              justify-content: flex-start;

              .item_l_u_cell {
                padding: 0 3px;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;

                div:first-child {
                  font-family: PingFang SC;
                  font-weight: 400;
                  font-size: 10px;
                  color: #98c2f4;
                  line-height: 14px;
                  text-align: left;
                  font-style: normal;
                }

                div:last-child {
                  font-family: PingFang SC;
                  font-weight: 600;
                  font-size: 16px;
                  color: #0678ff;
                  line-height: 22px;
                  text-align: left;
                  font-style: normal;
                }
              }
            }

            .item_l_d {
              display: block;
              text-align: justify;
              font-family: PingFang SC;
              font-weight: 400;
              font-size: 10px;
              height: 14px;
              color: #e2ba7e;
              line-height: 14px;
              font-style: normal;
            }
          }

          .item_r {
            .megaphone_icon {
              width: 22px;
              height: 22px;
              cursor: pointer;
            }

            margin-left: 25px;
          }
        }
      }
    }

    .learning_material_sentences_view {
      border-top: 1px solid #e1e1e1;

      .cause_item {
        height: auto !important;

        :deep(.arco-scrollbar) {
          width: 1078px;
        }
      }

      .item_r {
        width: 50px !important;
        display: flex;
        margin-left: 11px !important;
        justify-content: center;
      }
    }

    &:before {
      content: '';
      display: block;
      width: calc(1200px - 25px);
      left: calc((100vw - 1175px) / 2);
      height: var(--backdrop);
      border-radius: 5px;
      position: absolute;
      z-index: 1;
      pointer-events: painted;
      background-color: rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(3px);
    }
  }
}
</style>
