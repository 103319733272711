<!--文化中心-->
<template>
  <div :lang="i18n.locale.value" class="culture_center">
    <p class="culture_title">{{ $t('home.latest.news') }}</p>
    <p class="culture_desc">{{ $t('culture.center.subTitle') }}</p>
    <a-input-search v-model="queryParams.title" :placeholder="$t('culture.center.search')" allow-clear class="search_btn" search-button @search="getList"></a-input-search>
  </div>
  <div class="culture_body">
    <p class="body_subTitle">{{ $t('culture.center.text') }}</p>
    <XyzTransition duration="auto" xyz="fade small">
      <div v-if="tableList.loading" class="culture_card w1200">
        <div v-for="(item, index) in tableList.list" :key="index" class="card_item">
          <div class="card_text">
            <a-typography-text :ellipsis="{ rows: 2 }" class="card_title">{{ item.Title }}</a-typography-text>
            <a-typography-text :ellipsis="{ rows: 2 }" class="card_desc" title="">
              {{ item.SubTitle }}
            </a-typography-text>
          </div>
          <a-button class="card_btn" @click="useToCurrentPage('/articleDetail/' + item.Id)">{{ $t('culture.center.btn') }}</a-button>
        </div>
      </div>
    </XyzTransition>
    <Empty v-if="tableList.total === 0 && tableList.loading == true" />
    <a-pagination v-if="tableList.total !== 0" v-model:current="queryParams.pageIndex" v-model:page-size="queryParams.pageSize" :total="tableList.total" @change="getList" />
  </div>
</template>
<script setup>
import request from '@/common/request.js';
import { hideLoadingProcess, showLoadingProcess, useToCurrentPage } from '@/hooks/page.js';
import { onMounted, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { useCommonCacheStore } from '@/stores/commonCache.js';
import $ from 'jquery';

const i18n = useI18n();
const commonCache = useCommonCacheStore();

const queryParams = reactive({
  pageIndex: 1,
  pageSize: 6,
  title: '',
});

const tableList = reactive({
  list: [],
  total: 0,
  loading: false,
});
onMounted(() => {
  commonCache.setHeaderWhiteTheme();
});
const getList = () => {
  showLoadingProcess();
  $('html, body').scrollTop(0);
  tableList.loading = false;
  request({
    url: '/Aticle/GetAticleListPager',
    method: 'GET',
    params: queryParams,
  })
    .then((resp) => {
      tableList.total = resp.data.Total;
      queryParams.pageIndex = resp.data.PageIndex;
      queryParams.PageSize = resp.data.PageSize;
      tableList.list = resp.data.DataList;
    })
    .finally(() => {
      tableList.loading = true;
      hideLoadingProcess();
    });
};
getList();
</script>

<style lang="less" scoped>
.culture_center {
  width: 100%;
  height: 400px;
  text-align: center;
  margin-top: 89px;
  background-image: url('@/assets/images/culture.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .culture_title {
    font-family: AlimamaShuHeiTi;
    font-size: 42px;
    color: #0971f8;
    line-height: 42px;
    font-weight: bold;
    text-align: center;
    font-style: normal;
    padding-top: 103px;
  }

  .culture_desc {
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 24px;
    color: #333333;
    line-height: 24px;
    text-align: center;
    font-style: normal;
    padding-top: 20px;
  }

  .search_btn {
    width: 770px;
    height: 54px;
    margin-top: 35px;
    background: #ffffff;
    border-radius: 8px;

    :deep(.arco-input-wrapper) {
      border: 0;

      &:hover {
        background-color: #ffffff;
      }
    }

    :deep(.arco-input-append) {
      width: 110px;

      .arco-btn {
        width: 100%;
        height: 100%;
        border-radius: 0 8px 8px 0;

        &-icon {
          font-size: 36px;
        }
      }
    }
  }

  &:lang(ar),
  &:lang(pk) {
    :deep(.arco-input-append) {
      .arco-btn {
        border-radius: 8px 0 0 8px;
      }
    }
  }
}

.culture_body {
  background-color: #f8f8f8;
  padding-bottom: 50px;

  .body_subTitle {
    font-family: PingFang SC;
    font-weight: 600;
    font-size: 42px;
    color: #333333;
    line-height: 59px;
    text-align: center;
    font-style: normal;
    padding: 50px;
  }

  .culture_card {
    display: grid;
    grid-template-columns: 590px 590px;
    grid-gap: 20px;
    margin: auto;
    padding-bottom: 50px;

    .card_item {
      width: 530px;
      height: 210px;
      background: #ffffff;
      box-shadow: 0px 0 8px 0px rgba(134, 134, 134, 0.08);
      border-radius: 10px;
      padding: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;

      .card_text {
        width: 100%;

        .card_title {
          font-family: PingFang SC;
          font-weight: 600;
          font-size: 22px;
          color: #333333;
          line-height: 32px;
          font-style: normal;
        }

        .card_desc {
          font-family: PingFang SC;
          margin-top: 30px;
          font-weight: 400;
          font-size: 15px;
          color: #666666;
          line-height: 24px;
          font-style: normal;
        }
      }

      .card_btn {
        width: 136px;
        height: 40px;
        background: #0971f8;
        border-radius: 2px;
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        line-height: 22px;
        text-align: center;
        font-style: normal;
      }
    }
  }
}
</style>
