<!--个人信息-->
<template>
  <div :lang="i18n.locale.value" class="tabs">
    <p :class="tabs === 1 ? 'activated' : ''" @click="changeTabs(1)">{{ $t('individual.center') }}</p>
    <p :class="tabs === 2 ? 'activated' : ''" @click="changeTabs(2)">{{ $t('individual.update.password') }}</p>
  </div>
  <div :lang="i18n.locale.value" class="content_body">
    <div class="user_info_view">
      <div class="user_header_img">
        <a-avatar :image-url="formParams.imageUrl ? formParams.imageUrl : userHeader" trigger-type="mask" @click="selectPic">
          <template #trigger-icon>
            <IconCamera />
          </template>
        </a-avatar>
      </div>
      <div class="user_info_form">
        <XyzTransitionGroup class="example-grid" xyz="fade small stagger">
          <a-form v-if="tabs === 1" ref="userFormRef" :model="formParams" :rules="rules" @submit="handleSubmit">
            <a-form-item :label="$t('individual.center.nickname')" class="form_nick" field="nickName" validate-trigger="blur">
              <a-input v-model="formParams.nickName" :placeholder="$t('form.placeholder.nickname')" allow-clear></a-input>
            </a-form-item>
            <a-form-item class="form_btn_group">
              <a-button :loading="loadingUser" html-type="submit">{{ $t('individual.center.submit') }}</a-button>
            </a-form-item>
          </a-form>
          <a-form v-if="tabs !== 1" ref="pwdFormRef" :auto-label-width="true" :model="formParamsPassword" :rules="rulesPassword" @submit="handleSubmitPassword">
            <a-form-item :label="$t('individual.center.origin.password')" class="form_nick" field="originPassword" validate-trigger="blur">
              <a-input-password v-model="formParamsPassword.originPassword" :placeholder="$t('form.placeholder.origin.password')"></a-input-password>
            </a-form-item>
            <a-form-item :label="$t('individual.center.new.password')" class="form_nick" field="newPassword" validate-trigger="blur">
              <a-input-password v-model="formParamsPassword.newPassword" :placeholder="$t('form.placeholder.new.password')"></a-input-password>
            </a-form-item>
            <a-form-item :label="$t('individual.center.confirm.password')" class="form_nick" field="confirmPassword" validate-trigger="blur">
              <a-input-password v-model="formParamsPassword.confirmPassword" :placeholder="$t('form.placeholder.confirm.password')"></a-input-password>
            </a-form-item>
            <a-form-item class="form_btn_group">
              <a-button :loading="loadingPwd" html-type="submit">{{ $t('individual.center.submit') }}</a-button>
            </a-form-item>
          </a-form>
        </XyzTransitionGroup>
      </div>
    </div>
  </div>
</template>

<script setup>
import userHeader from '@/components/layout/images/header.png';
import { reactive, ref } from 'vue';
import Cropper from '@/components/common/Cropper/index.js';
import { use$t } from '@/hooks/language.js';
import request from '@/common/request.js';
import { Notification } from '@arco-design/web-vue';
import { useLocalCacheStore } from '@/stores/localCache.js';
import { useI18n } from 'vue-i18n';

const loadingPwd = ref(false);
const loadingUser = ref(false);
const localCache = useLocalCacheStore();
const i18n = useI18n();

const userFormRef = ref();
const pwdFormRef = ref();

const tabs = ref(1);
const changeTabs = (id) => {
  tabs.value = id;
};

const formParams = reactive({
  nickName: localCache.userinfo?.UserNick,
  imageUrl: localCache.userinfo?.UserIcon == null ? null : import.meta.env.VITE_APP_IMG_BASE_URL + localCache.userinfo.UserIcon,
});

const formParamsPassword = reactive({
  originPassword: '',
  newPassword: '',
  confirmPassword: '',
});

const rules = reactive({
  nickName: [
    {
      required: true,
      message: use$t('form.placeholder.nickname'),
    },
  ],
});

const rulesPassword = reactive({
  originPassword: [
    {
      required: true,
      message: use$t('form.placeholder.origin.password'),
    },
    // {
    //   validator: (value, cb) => {
    //     if (RegUtils.RegPw(value)) {
    //       cb(use$t(use$t('form.password.validate')));
    //     } else {
    //       cb();
    //     }
    //   },
    // },
  ],
  newPassword: [
    {
      required: true,
      message: use$t('form.placeholder.new.password'),
    },
    // {
    //   validator: (value, cb) => {
    //     if (RegUtils.RegPw(value)) {
    //       cb(use$t(use$t('form.password.validate')));
    //     } else {
    //       cb();
    //     }
    //   },
    // },
  ],
  confirmPassword: [
    {
      required: true,
      message: use$t('form.placeholder.confirm.password'),
    },
    {
      validator: (value, cb) => {
        if (value !== formParamsPassword.newPassword) {
          cb(use$t('form.password.unlikeness'));
        } else {
          cb();
        }
      },
    },
  ],
});

/**
 * 用户信息更新
 * @param values
 * @param errors
 */
const handleSubmit = ({ values, errors }) => {
  if (errors) return;
  loadingUser.value = true;
  request({
    url: '/Auth/SavePersonalInformation',
    method: 'get',
    params: {
      icon: formParams.imageUrl ? (formParams.imageUrl + '').replace(import.meta.env.VITE_APP_IMG_BASE_URL, '') : null,
      userNick: formParams.nickName,
    },
  })
    .then((resp) => {
      Notification.success(use$t('code.1009'));
      localCache.updUserInfo({
        UserNick: formParams.nickName,
        UserIcon: (formParams.imageUrl + '').replace(import.meta.env.VITE_APP_IMG_BASE_URL, ''),
      });
    })
    .finally(() => {
      loadingUser.value = false;
    });
};

const handleSubmitPassword = ({ values, errors }) => {
  if (errors) return;
  loadingPwd.value = true;
  request({
    url: '/Auth/ChangePwd',
    method: 'get',
    params: {
      oldpwd: formParamsPassword.originPassword,
      newpwd: formParamsPassword.newPassword,
    },
  })
    .then((resp) => {
      Notification.success(use$t('code.1011'));
      console.log(resp);
    })
    .finally(() => {
      loadingPwd.value = false;
    });
};

/**
 * 选择头像图片
 */
const selectPic = () => {
  const input = document.createElement('input');
  input.type = 'file';
  input.accept = 'image/*';
  input.onchange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // 这里可以处理选中的文件，例如显示预览、上传等
      // 创建FileReader对象
      const reader = new FileReader();
      // 文件读取成功后执行的回调
      reader.onload = function (event) {
        const base64 = event.target.result;
        // 在这里处理base64字符串
        Cropper({
          imgUrl: base64,
          success: (blob) => {
            //上传头像
            // 创建FormData对象来处理文件上传
            const formData = new FormData();
            formData.append('file', blob, file.name);

            request({
              url: '/File/UploadFile',
              method: 'post',
              headers: { 'Content-Type': 'multipart/form-data' },
              data: formData,
            }).then((resp) => {
              formParams.imageUrl = import.meta.env.VITE_APP_IMG_BASE_URL + resp.data.UniqueFileName;
              userFormRef.value.validateField('nickName', (errors) => {
                if (errors) return;
                handleSubmit({ values: undefined, errors: undefined });
              });
            });
          },
        });
      };
      // 以DataURL的形式读取文件
      reader.readAsDataURL(file);
    }
  };
  input.click();
};
</script>

<style lang="less" scoped>
.tabs {
  display: flex;

  p {
    cursor: pointer;
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #666666;
    line-height: 22px;
    text-align: left;
    font-style: normal;
    margin-top: 10px;

    &:last-child {
      margin-left: 50px;
    }

    &:hover {
      color: #0678ff;
    }
  }

  .activated {
    color: #0678ff;
  }

  &:lang(ar),
  &:lang(pk) {
    p {
      &:last-child {
        margin-left: 0;
        margin-right: 50px;
      }
    }
  }
}

.content_body {
  border-top: 1px solid #e2e2e2;

  margin-top: 20px;

  &:lang(ar),
  &:lang(pk) {
    :deep(.arco-form-item-label-col) {
      padding-left: 16px;
    }
  }
}

.user_info_view {
  display: flex;

  .user_header_img {
    width: 220px;
    height: 520px;
    text-align: center;
    background: #f5f7fb;

    .arco-avatar {
      margin-top: 60px;
      width: 150px;
      height: 150px;

      .arco-icon {
        width: 35px;
        height: 35px;
      }
    }
  }

  .user_info_form {
    text-align: center;
    margin-left: 35px;

    .arco-form {
      width: 450px;
      position: absolute;
      margin: 35px auto auto;

      .form_nick {
        :deep(.arco-form-item-content-wrapper) {
          height: 32px;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #dddddd;
        }
      }

      :deep(.arco-form-item-label-required-symbol) {
        display: none;
      }

      .form_btn_group {
        margin-top: 50px;

        .arco-btn {
          width: 88px;
          height: 32px;
          background: #0678ff;
          border-radius: 2px;
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #ffffff;
          line-height: 22px;
          text-align: center;
          font-style: normal;
        }
      }
    }
  }
}
</style>
