<!--课后练习-->
<template>
  <div :lang="i18n.locale.value" class="homework">
    <!--    <div class="course_header">
          <div class="w1200 course_view_header">
            <a-breadcrumb>
              <template #separator>
                <icon-double-right />
              </template>
              <a-breadcrumb-item>{{ $t('detail.course.classify') }}</a-breadcrumb-item>
              <a-breadcrumb-item>HSK</a-breadcrumb-item>
            </a-breadcrumb>
          </div>
        </div>-->
    <div class="homework_body w1200">
      <div class="body_l">
        <div v-for="(item, index) in exercisesList" :id="'anchor_' + index" :key="index" class="body_view">
          <p class="problem_type">{{ index + 1 }}.【{{ getProblemType(item) }}】</p>
          <div v-rich-text="item.Problem.Title" :class="item.Problem.ProblemType == 10 ? '' : 'p_line'" class="problem_title"></div>
          <!--   阅读题子项       -->
          <div v-if="item.SubList.length != 0 && item.Problem.ProblemType == 10" class="ml20">
            <template v-for="(read, readIndex) in item.SubList">
              <div class="mt20"></div>
              <p class="problem_type">{{ index + 1 }}.{{ readIndex + 1 }}【{{ getProblemType(read) }}】</p>
              <p v-rich-text="read.Problem.Title" class="problem_title"></p>
              <!-- 单选、判断   -->
              <a-radio-group v-if="read.Problem.ProblemType == '1' || read.Problem.ProblemType == '2'" v-model="read.Problem.userAnswer">
                <a-radio v-for="(option, optionIndex) in read.AnswerList" :key="optionIndex" :value="option.OptionTag">
                  <template #radio="{ checked }">
                    <div :class="{ 'select_item-checked': checked }" class="select_item">
                      <div>{{ option.OptionTag }}</div>
                      <div class="select_line"></div>
                      <div v-rich-text="option.Content"></div>
                    </div>
                  </template>
                </a-radio>
              </a-radio-group>
              <!--     多选         -->
              <a-checkbox-group v-if="read.Problem.ProblemType == 3" v-model="item.Problem.userAnswer">
                <a-checkbox v-for="(option, optionIndex) in read.AnswerList" :key="optionIndex" :value="option.OptionTag">
                  <template #checkbox="{ checked }">
                    <div :class="{ 'select_item-checked': checked }" class="select_item">
                      <div>{{ option.OptionTag }}</div>
                      <div class="select_line"></div>
                      <div v-rich-text="option.Content"></div>
                    </div>
                  </template>
                </a-checkbox>
              </a-checkbox-group>
              <!--      主主观题        -->
              <a-textarea
                v-if="read.Problem.ProblemType == 4"
                v-model="read.Problem.userAnswer"
                :auto-size="{ minRows: 5, maxRows: 30 }"
                :max-length="500"
                :placeholder="$t('form.placeholder.textarea') + '：'"
                show-word-limit
              />
            </template>
          </div>
          <!--   单选、判断       -->
          <a-radio-group v-if="item.Problem.ProblemType == 1 || item.Problem.ProblemType == 2" v-model="item.Problem.userAnswer">
            <a-radio v-for="(option, optionIndex) in item.AnswerList" :key="optionIndex" :value="option.OptionTag">
              <template #radio="{ checked }">
                <div :class="{ 'select_item-checked': checked }" class="select_item">
                  <div>{{ option.OptionTag }}</div>
                  <div class="select_line"></div>
                  <div v-rich-text="option.Content"></div>
                </div>
              </template>
            </a-radio>
          </a-radio-group>

          <!--   多选       -->
          <a-checkbox-group v-if="item.Problem.ProblemType == 3" v-model="item.Problem.userAnswer">
            <a-checkbox v-for="(option, optionIndex) in item.AnswerList" :key="optionIndex" :value="option.OptionTag">
              <template #checkbox="{ checked }">
                <div :class="{ 'select_item-checked': checked }" class="select_item">
                  <div>{{ option.OptionTag }}</div>
                  <div class="select_line"></div>
                  <div v-rich-text="option.Content"></div>
                </div>
              </template>
            </a-checkbox>
          </a-checkbox-group>

          <!--  主观题 -->
          <a-textarea
            v-if="item.Problem.ProblemType == 4"
            v-model="item.Problem.userAnswer"
            :auto-size="{ minRows: 5, maxRows: 30 }"
            :max-length="500"
            :placeholder="$t('form.placeholder.textarea') + '：'"
            show-word-limit
          />
          <hr class="item_line" />
        </div>
      </div>
      <!--   答题卡   -->
      <a-affix :offsetTop="107">
        <div class="body_r">
          <div class="index-header">
            <div class="header_l">
              <div></div>
              <p>{{ $t('practiceResult.answer.sheet') }}</p>
            </div>
            <div class="header_r">
              <div class="header_r_item">
                <p>{{ $t('practiceResult.answer.unanswered') }}</p>
                <div></div>
              </div>
              <div class="header_r_item">
                <p>{{ $t('practiceResult.answer.answered') }}</p>
                <div></div>
              </div>
            </div>
          </div>
          <div class="index_body_view">
            <div class="index_body">
              <div v-for="(item, index) in exercisesList" :key="index" :class="answeredHandle(item) ? 'index_activated' : ''" class="index_item" @click="scrollToAnchor(index)">{{ index + 1 }}</div>
            </div>
          </div>

          <a-button :disabled="disabled" @click="submitHandle">{{ $t('practiceResult.submit.now') }}</a-button>
        </div>
      </a-affix>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive } from 'vue';
import request from '@/common/request.js';
import { useRoute, useRouter } from 'vue-router';
import { use$t } from '@/hooks/language.js';
import Alert from '@/components/common/Alert/index.js';
import { useI18n } from 'vue-i18n';

const route = useRoute();
const router = useRouter();
const exercisesList = reactive([]);
const i18n = useI18n();

const disabled = computed(() => {
  let disabled = true;
  exercisesList.forEach((item) => {
    if (item.Problem.userAnswer != '') {
      disabled = false;
    }
    if (item.SubList.length != 0) {
      item.SubList.forEach((sub) => {
        if (sub.Problem.userAnswer != '') {
          disabled = false;
        }
      });
    }
  });
  return disabled;
});

/**
 * 判断 = 1,//判断
 * 单选  = 2,//单选
 * 多选 = 3,//多选
 * 主观 = 4,//主观
 * 阅读理解=10,//阅读理解
 * @type {ComputedRef<unknown>}
 */
const getProblemType = (item) => {
  if (exercisesList.length == 0) {
    return;
  }
  if (item.Problem.ProblemType == 1) {
    return use$t('exercise.true.false questions');
  } else if (item.Problem.ProblemType == 2) {
    return use$t('exercise.Multiple-choice.question');
  } else if (item.Problem.ProblemType == 3) {
    return use$t('exercise.Multiple.choice.question');
  } else if (item.Problem.ProblemType == 4) {
    return use$t('exercise.subjective.questions');
  } else if (item.Problem.ProblemType == 10) {
    return use$t('exercise.reading.comprehension.questions');
  }
  return '';
};
//获取课后习题
request({
  url: '/Video/GetAfterCourse',
  method: 'get',
  params: {
    sign: route.query.sign,
    videoId: route.query.videoId,
    courseId: route.query.courseId,
    lang: 'zh',
  },
}).then((resp) => {
  exercisesList.length = 0;
  exercisesList.push(...resp.data);
  //添加提交字段
  exercisesList.forEach((item) => {
    item.Problem['userAnswer'] = '';
    if (item.SubList.length > 0 && item.Problem.ProblemType == 10) {
      item.SubList.forEach((read) => {
        read.Problem['userAnswer'] = '';
      });
    }
  });
});

/**
 * 定位题目
 * @param index
 */
const scrollToAnchor = (index) => {
  const el = document.getElementById('anchor_' + index);
  if (el) {
    if (index == 0) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
  }
};

/**
 * 是否已答
 */
const answeredHandle = (item) => {
  if (item.SubList.length > 0) {
    const findArr = item.SubList.filter((item) => item.Problem.userAnswer == '');
    if (findArr.length > 0) {
      return false;
    }
    return true;
  } else {
    if (item.Problem.userAnswer == '') {
      return false;
    }
    return true;
  }
};

const handleParams = (params, item) => {
  if (item.Problem.ProblemType == 1 || item.Problem.ProblemType == 2) {
    //判断、单选
    params.push({
      ProblemId: item.Problem.Id,
      ProblemType: item.Problem.ProblemType,
      UserAnswer: item.Problem.userAnswer,
      UserContent: '',
      RightAnswer: item.Problem.Answers,
      IsRight: item.Problem.Answers == item.Problem.userAnswer ? true : false,
      Score: item.Problem.Score,
    });
  } else if (item.Problem.ProblemType == 3) {
    //多选
    params.push({
      ProblemId: item.Problem.Id,
      ProblemType: item.Problem.ProblemType,
      UserAnswer: item.Problem.userAnswer == '' ? '' : item.Problem.userAnswer.sort().join(''),
      UserContent: '',
      RightAnswer: item.Problem.Answers,
      IsRight: item.Problem.userAnswer != '' && item.Problem.Answers == item.Problem.userAnswer.sort().join('') ? true : false,
      Score: item.Problem.Score,
    });
  } else if (item.Problem.ProblemType == 4) {
    //主观题
    params.push({
      ProblemId: item.Problem.Id,
      ProblemType: item.Problem.ProblemType,
      UserAnswer: '',
      UserContent: item.Problem.userAnswer,
      RightAnswer: '',
      IsRight: '',
      Score: item.Problem.Score,
    });
  } else if (item.Problem.ProblemType == 10) {
    //阅读题
    params.push({
      ProblemId: item.Problem.Id,
      ProblemType: item.Problem.ProblemType,
      UserAnswer: '',
      UserContent: '',
      RightAnswer: '',
      IsRight: '',
      Score: item.Problem.Score,
    });
  }
};

/**
 * 提交作业
 */
const submitHandle = () => {
  //组装数据
  const params = [];
  exercisesList.forEach((item) => {
    handleParams(params, item);
    item.SubList.forEach((cell) => {
      handleParams(params, cell);
    });
  });
  request({
    url: '/Work/WorkSubmit',
    method: 'post',
    data: {
      CourseId: route.query.courseId,
      VideoId: route.query.videoId,
      ListProblem: params,
    },
  }).then((resp) => {
    Alert({
      title: use$t('common.homework.title'),
      content: use$t('code.1019'),
      okEvent: () => {
        router.replace({
          path: '/individualCenter',
          query: {
            tabIndex: 2,
          },
        });
      },
    });
  });
};
</script>

<style lang="less" scoped>
.homework {
  width: 100%;
  display: inline-block;
  min-height: 80vh;
  margin-top: 88px;
  background-color: #f4f6f9;
  padding-bottom: 149px;

  .course_header {
    background-color: #ffffff;
    height: 82px;
    display: flex;

    .course_view_header {
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .homework_body {
    display: flex;
    justify-content: space-between;
    margin: 20px auto auto;

    .body_l {
      width: 860px;
      overflow-y: auto;
      background: #ffffff;

      .body_view {
        width: calc(780px - 22px - 60px);
        background-color: white;
        padding: 16px 22px 10px;

        .problem_title {
          direction: ltr;
          font-family: PingFang SC;
          color: #333333;
          font-style: normal;
          margin-top: 20px;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
        }

        .problem_type {
          font-family: PingFang SC;
          color: #333333;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 25px;
          letter-spacing: 1px;
        }

        .p_line {
          :deep(p) {
            display: flex;
            align-items: center;
          }
        }

        .arco-radio-group,
        .arco-checkbox-group {
          width: 100%;

          .arco-radio,
          .arco-checkbox {
            width: calc(100% - 44px);
            background: #f4f5fb;
            border-radius: 6px;
            padding: 17px 22px;
            margin-top: 12px;

            &:first-child {
              margin-top: 16px;
            }

            &:hover {
              background-color: #ecf4ff;
              color: white;

              .select_item {
                div:first-child {
                  color: #0678ff;
                }

                div:nth-child(2) {
                  background-color: #0678ff;
                }

                div:last-child {
                  color: #0678ff;
                }
              }
            }
          }

          .arco-radio-checked,
          .arco-checkbox-checked {
            background-color: #ecf4ff;
          }

          .select_item {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            div:first-child {
              font-family: PingFang SC;
              font-weight: 500;
              font-size: 16px;
              color: #333333;
              line-height: 22px;
              text-align: left;
              font-style: normal;
            }

            div:nth-child(2) {
              width: 1px;
              height: 20px;
              background: #333333;
              margin-left: 15px;
              margin-right: 15px;
            }

            div:last-child {
              font-family: PingFang SC;
              font-weight: 500;
              font-size: 16px;
              color: #333333;
              line-height: 22px;
              text-align: left;
              font-style: normal;
            }
          }

          .select_item-checked {
            div:first-child {
              color: #0678ff !important;
            }

            div:nth-child(2) {
              background-color: #0678ff !important;
            }

            div:last-child {
              color: #0678ff !important;
            }
          }
        }

        .item_line {
          border-top: 1px solid #e1e1e1;
          border-bottom: 0;
          margin-top: 30px;
        }

        .arco-textarea-wrapper {
          margin-top: 20px;
          width: 700px;
          background: #f4f5fb;
          border-radius: 8px;
          border: 1px solid #dadada;
        }
      }
    }

    .body_r {
      width: calc(320px - 40px);
      min-height: 460px;
      max-height: 1000px;
      background: #ffffff;
      padding: 14px 20px;

      .index-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .header_l {
          align-items: center;
          display: flex;

          div {
            width: 3px;
            height: 10px;
            background: #0678ff;
            margin-right: 7px;
          }

          p {
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #333333;
            line-height: 20px;
            text-align: left;
            font-style: normal;
          }
        }

        .header_r {
          display: flex;

          .header_r_item {
            display: flex;
            align-items: center;

            p {
              font-family: PingFang SC;
              font-weight: 400;
              font-size: 12px;
              color: #575d6c;
              line-height: 17px;
              text-align: left;
              font-style: normal;
            }

            div {
              width: 12px;
              height: 12px;
              background: #ffffff;
              border-radius: 2px;
              border: 1px solid #cdcdcd;
              margin-left: 4px;
            }

            &:last-child {
              margin-left: 10px;

              div {
                background: #1882ff;
                border: 1px solid #1882ff;
              }
            }
          }
        }
      }

      .index_body_view {
        min-height: 200px;
        overflow-y: auto;
        padding-bottom: 30px;
        border-bottom: 1px solid #e0e0e0;

        .index_body {
          display: grid;
          grid-template-columns: 36px 36px 36px 36px 36px;
          grid-row-gap: 14px;
          grid-column-gap: 14px;
          max-height: 500px;
          overflow-y: auto;
          overflow-x: hidden;
          margin-top: 25px;
          padding: 0 25px;

          .index_item {
            cursor: pointer;
            width: 36px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid #cdcdcd;
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
            line-height: 20px;
            font-style: normal;

            &:hover {
              border: 1px solid #1882ff;
            }
          }

          .index_activated {
            background: #1882ff;
            border: 1px solid #1882ff;
            color: #ffffff;
          }
        }
      }

      .arco-btn {
        background: #1882ff;
        border-radius: 21px;
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        line-height: 22px;
        text-align: center;
        font-style: normal;
        margin: 80px auto auto;
        width: 270px;
        height: 36px;
        background: #1882ff;
        border-radius: 21px;
      }
    }
  }

  &:lang(ar),
  &:lang(pk) {
    .arco-radio-group,
    .arco-checkbox-group {
      direction: ltr;
    }
  }
}

:deep(.homeworkImg) {
  height: 100px;
}
</style>
