<template>
  <div class="pdfView">
    <PdfObject :options="pdfOption" :url="pdfFile" />
  </div>
</template>

<script setup>
import aboutMe_zh from '@/assets/pdf/aboutMe_zh.pdf';
import aboutMe_en from '@/assets/pdf/aboutMe_en.pdf';
import aboutMe_ar from '@/assets/pdf/aboutMe_ar.pdf';
import aboutMe_id from '@/assets/pdf/aboutMe_id.pdf';
import aboutMe_pk from '@/assets/pdf/aboutMe_pk.pdf';
import { computed, reactive } from 'vue';
import { useLocalCacheStore } from '@/stores/localCache.js';

const localCache = useLocalCacheStore();
const pdfOption = reactive({ pdfOpenParams: { view: 'Fit', page: '1', toolbar: 0, scrollbars: 0 }, height: '100%' });

const pdfFile = computed(() => {
  let defaultLanguage = localCache.defaultLanguage;
  if (defaultLanguage === 'zh') {
    return aboutMe_zh;
  } else if (defaultLanguage === 'ar') {
    return aboutMe_ar;
  } else if (defaultLanguage === 'id') {
    return aboutMe_id;
  } else if (defaultLanguage === 'pk') {
    return aboutMe_pk;
  } else {
    return aboutMe_en;
  }
});

const demo = () => {
  pdfOption.pdfOpenParams.page = 1;
};
</script>

<style lang="less" scoped>
.pdfView {
  height: 100vh;
  padding-bottom: 150px;

  .pdfobject-container {
    height: 100%;
    width: 1200px;
    margin: 100px auto;
  }
}
</style>
