<!--练习结果-->
<template>
  <div :lang="i18n.locale.value" class="practice_result">
    <!--    <div class="course_header">
          <div class="w1200 course_view_header">
            <a-breadcrumb>
              <template #separator>
                <icon-double-right />
              </template>
              <a-breadcrumb-item class="pointer" @click="useToAllCourse()">{{ $t('individual.center.my.homework') }}</a-breadcrumb-item>
              <a-breadcrumb-item>爱上更多机会大</a-breadcrumb-item>
            </a-breadcrumb>
          </div>
        </div>-->
    <div class="homework_body w1200">
      <div class="body_l">
        <div class="statistics">
          <div class="statistics_l">
            <div class="statistics_l_item">
              <p>{{ $t('practiceResult.count.questions') }}</p>
              <p>{{ questionCount }}</p>
            </div>
            <div class="statistics_l_item">
              <p>{{ $t('practiceResult.count.questions.unanswered') }}</p>
              <p>{{ noAnswer }}</p>
            </div>
          </div>
          <div class="statistics_r">
            <a-progress ref="progressRef" :percent="percent" animation color="#0678FF" stroke-width="6" track-color="#DCDFE5" type="circle" />
          </div>
        </div>
        <div v-for="(item, index) in exercisesList" :id="'anchor_' + index" :key="index" class="body_view">
          <p class="problem_type">{{ index + 1 }}.【{{ getProblemType(item) }}】</p>
          <p v-rich-text="item.Problem.Title" class="problem_title"></p>
          <div v-if="item.Problem.ProblemType == 10" class="ml20">
            <template v-for="(read, readIndex) in item.SubList">
              <div class="mt20"></div>
              <p class="problem_type">{{ index + 1 }}.{{ readIndex + 1 }}【{{ getProblemType(read) }}】</p>
              <p v-rich-text="read.Problem.Title" class="problem_title"></p>
              <!--   单选、判断       -->
              <a-radio-group v-if="read.Problem.ProblemType == 1 || read.Problem.ProblemType == 2" v-model="read.UserAnswer" disabled>
                <a-radio v-for="(option, optionIndex) in read.Answers" :key="optionIndex" :value="option.OptionTag">
                  <template #radio="{ checked }">
                    <!--                <div :class="{ 'select_item-checked': checked }" class="select_item">-->
                    <div :class="checked && read.UserAnswer == read.RightAnswer ? 'select_item-checked' : 'select_item-error'" class="select_item">
                      <div>{{ option.OptionTag }}</div>
                      <div class="select_line"></div>
                      <div v-rich-text="option.Content"></div>
                    </div>
                  </template>
                </a-radio>
              </a-radio-group>

              <!-- 多选  -->
              <a-checkbox-group v-if="read.Problem.ProblemType == 3" :default-value="read.UserAnswer.split('')" disabled>
                <a-checkbox v-for="(option, optionIndex) in read.Answers" :key="optionIndex" :value="option.OptionTag">
                  <template #checkbox="{ checked }">
                    <div :class="checked && read.UserAnswer == read.RightAnswer ? 'select_item-checked' : 'select_item-error'" class="select_item">
                      <div>{{ option.OptionTag }}</div>
                      <div class="select_line"></div>
                      <div v-rich-text="option.Content"></div>
                    </div>
                  </template>
                </a-checkbox>
              </a-checkbox-group>

              <!-- 主观题   -->
              <a-textarea v-if="read.Problem.ProblemType == 4" v-model="read.UserContent" :auto-size="{ minRows: 5, maxRows: 30 }" :max-length="500" readonly show-word-limit />
              <hr class="item_line" />
              <!--    主观题解析      -->
              <a-collapse v-if="read.Problem.ProblemType == 4" expand-icon-position="right">
                <a-collapse-item>
                  <template #header>
                    <a-typography-text ellipsis>{{ $t('common.homework.teacher.comments') }}：{{ read.CorrectionContent }}</a-typography-text>
                  </template>
                  <template #expand-icon="{ active }">
                    <div v-if="active" class="expand_text">
                      {{ $t('exercise.reading.pack.up') }}
                      <icon-down />
                    </div>
                    <div v-else class="expand_text">
                      {{ $t('exercise.reading.view.parsing') }}
                      <icon-up />
                    </div>
                  </template>
                  <p v-rich-text="read.CorrectionContent" class="analyze"></p>
                  <div class="feedback" @click="feedbackEvent(read)">
                    <p>{{ $t('topic.problem.text') }}：</p>
                    <a-button>
                      <template #icon>
                        <icon-edit />
                      </template>
                      <template #default>{{ $t('topic.problem.text') }}</template>
                    </a-button>
                  </div>
                </a-collapse-item>
              </a-collapse>
              <!--   判断、单选、多选解析       -->
              <a-collapse v-if="read.Problem.ProblemType == 1 || read.Problem.ProblemType == 2 || read.Problem.ProblemType == 3" disabled expand-icon-position="right">
                <a-collapse-item>
                  <template #header>
                    <p>{{ $t('exercise.reading.correct.answer') }}：{{ read.RightAnswer }}</p>
                    <p>
                      {{ $t('exercise.reading.your.answer') }}：
                      <span :style="{ color: read.UserAnswer == read.RightAnswer ? '#56CBB1' : '#FA5E59' }">
                        {{ read.UserAnswer }}
                      </span>
                    </p>
                  </template>
                  <template #expand-icon="{ active }">
                    <div v-if="active" class="expand_text">
                      {{ $t('exercise.reading.pack.up') }}
                      <icon-down />
                    </div>
                    <div v-else class="expand_text">
                      {{ $t('exercise.reading.view.parsing') }}
                      <icon-up />
                    </div>
                  </template>
                  <p v-rich-text="read.Problem.Analysis" class="analyze"></p>
                  <div class="feedback" @click="feedbackEvent(read)">
                    <p>{{ $t('topic.problem.text') }}：</p>
                    <a-button>
                      <template #icon>
                        <icon-edit />
                      </template>
                      <template #default>{{ $t('topic.problem.text') }}</template>
                    </a-button>
                  </div>
                </a-collapse-item>
              </a-collapse>
            </template>
          </div>
          <!--   单选、判断       -->
          <a-radio-group v-if="item.Problem.ProblemType == 1 || item.Problem.ProblemType == 2" v-model="item.UserAnswer" disabled>
            <a-radio v-for="(option, optionIndex) in item.Answers" :key="optionIndex" :value="option.OptionTag">
              <template #radio="{ checked }">
                <!--                <div :class="{ 'select_item-checked': checked }" class="select_item">-->
                <div :class="checked && item.UserAnswer == item.RightAnswer ? 'select_item-checked' : 'select_item-error'" class="select_item">
                  <div>{{ option.OptionTag }}</div>
                  <div class="select_line"></div>
                  <div v-rich-text="option.Content"></div>
                </div>
              </template>
            </a-radio>
          </a-radio-group>

          <!--   多选       -->
          <a-checkbox-group v-if="item.Problem.ProblemType == 3" :default-value="item.UserAnswer.split('')" disabled>
            <a-checkbox v-for="(option, optionIndex) in item.Answers" :key="optionIndex" :value="option.OptionTag">
              <template #checkbox="{ checked }">
                <div :class="checked && item.UserAnswer == item.RightAnswer ? 'select_item-checked' : 'select_item-error'" class="select_item">
                  <div>{{ option.OptionTag }}</div>
                  <div class="select_line"></div>
                  <div v-rich-text="option.Content"></div>
                </div>
              </template>
            </a-checkbox>
          </a-checkbox-group>

          <!--  主观题  -->
          <a-textarea v-if="item.Problem.ProblemType == 4" v-model="item.UserContent" :auto-size="{ minRows: 5, maxRows: 30 }" :max-length="500" readonly show-word-limit />
          <hr class="item_line" />
          <!-- 主观题解析  -->
          <a-collapse v-if="item.Problem.ProblemType == 4" expand-icon-position="right">
            <a-collapse-item>
              <template #header>
                <a-typography-text ellipsis>{{ $t('common.homework.teacher.comments') }}：{{ item.CorrectionContent }}</a-typography-text>
              </template>
              <template #expand-icon="{ active }">
                <div v-if="active" class="expand_text">
                  {{ $t('exercise.reading.pack.up') }}
                  <icon-down />
                </div>
                <div v-else class="expand_text">
                  {{ $t('exercise.reading.view.parsing') }}
                  <icon-up />
                </div>
              </template>
              <p v-rich-text="item.CorrectionContent" class="analyze"></p>
              <div class="feedback" @click="feedbackEvent(item)">
                <p>{{ $t('topic.problem.text') }}：</p>
                <a-button>
                  <template #icon>
                    <icon-edit />
                  </template>
                  <template #default>{{ $t('topic.problem.text') }}</template>
                </a-button>
              </div>
            </a-collapse-item>
          </a-collapse>
          <!--   判断、单选、多选解析       -->
          <a-collapse v-if="item.Problem.ProblemType == 1 || item.Problem.ProblemType == 2 || item.Problem.ProblemType == 3" expand-icon-position="right">
            <a-collapse-item>
              <template #header>
                <p>{{ $t('exercise.reading.correct.answer') }}：{{ item.RightAnswer }}</p>
                <p>
                  {{ $t('exercise.reading.your.answer') }}：
                  <span :style="{ color: item.UserAnswer == item.RightAnswer ? '#56CBB1' : '#FA5E59' }">
                    {{ item.UserAnswer }}
                  </span>
                </p>
              </template>
              <template #expand-icon="{ active }">
                <div v-if="active" class="expand_text">
                  {{ $t('exercise.reading.pack.up') }}
                  <icon-down />
                </div>
                <div v-else class="expand_text">
                  {{ $t('exercise.reading.view.parsing') }}
                  <icon-up />
                </div>
              </template>
              <p v-rich-text="item.Problem.Analysis" class="analyze"></p>
              <div class="feedback" @click="feedbackEvent(item)">
                <p>{{ $t('topic.problem.text') }}：</p>
                <a-button>
                  <template #icon>
                    <icon-edit />
                  </template>
                  <template #default>{{ $t('topic.problem.text') }}</template>
                </a-button>
              </div>
            </a-collapse-item>
          </a-collapse>
        </div>
      </div>
      <a-affix :offsetTop="107">
        <div class="body_r">
          <div class="index-header">
            <div class="header_l">
              <div></div>
              <p>{{ $t('practiceResult.answer.sheet') }}</p>
            </div>
            <div class="header_r">
              <div class="header_r_item">
                <p>{{ $t('practiceResult.answer.correct') }}</p>
                <div></div>
              </div>
              <div class="header_r_item">
                <p>{{ $t('practiceResult.answer.error') }}</p>
                <div></div>
              </div>
              <!--              <div class="header_r_item">
                              <p>{{ $t('practiceResult.answer.unanswered') }}</p>
                              <div></div>
                            </div>-->
            </div>
          </div>
          <div class="index_body_view">
            <div class="index_body">
              <div v-for="(item, index) in exercisesList" :key="index" :class="answeredHandle(item)" class="index_item" @click="scrollToAnchor(index)">
                {{ index + 1 }}
              </div>
            </div>
          </div>
        </div>
      </a-affix>
    </div>
  </div>
  <TopicFeedback v-model:topic-visible="topicVisible" :problemId="problemId"></TopicFeedback>
</template>

<script setup>
import { computed, onMounted, reactive, ref } from 'vue';
import { use$t } from '@/hooks/language.js';
import request from '@/common/request.js';
import { useRoute } from 'vue-router';
import { hideLoadingProcess, showLoadingProcess } from '@/hooks/page.js';
import TopicFeedback from '@/components/topicFeedback/index.vue';
import { useI18n } from 'vue-i18n';

const route = useRoute();
const i18n = useI18n();
const exercisesList = reactive([]);
const Toatal = ref(0);
const progressRef = ref();
const topicVisible = ref(false);
const problemId = ref('');

const percent = computed(() => {
  return Toatal.value / score.value;
});

/**
 * 题目总数
 * @type {ComputedRef<unknown>}
 */
const questionCount = computed(() => {
  let count = 0;
  if (exercisesList.length == 0) return count;
  exercisesList.forEach((item) => {
    if (item.hasOwnProperty('SubList') && item.SubList.length != 0) {
      count += item.SubList.length;
    } else {
      count++;
    }
  });
  return count;
});

/**
 * 未答题目数
 * @type {ComputedRef<unknown>}
 */
const noAnswer = computed(() => {
  let count = 0;
  if (exercisesList.length == 0) return count;
  recursion(exercisesList);
  return count;

  function recursion(list) {
    list.forEach((item) => {
      if (item.Problem.ProblemType == 1 || item.Problem.ProblemType == 2 || item.Problem.ProblemType == 3) {
        if (item.UserAnswer == '') {
          count++;
        }
      } else if (item.Problem.ProblemType == 4) {
        if (item.UserContent == '') {
          count++;
        }
      } else if (item.Problem.ProblemType == 10) {
        if (item.SubList) recursion(item.SubList);
      }
    });
  }
});
/**
 * 定位题目
 * @param index
 */
const scrollToAnchor = (index) => {
  const el = document.getElementById('anchor_' + index);
  if (el) {
    if (index == 0) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
  }
};

//题目总分
const score = computed(() => {
  let count = 0;
  if (exercisesList.length == 0) return count;
  computeScore(exercisesList);
  return count;

  function computeScore(list) {
    list.forEach((item) => {
      if (item.Problem.ProblemType == 1 || item.Problem.ProblemType == 2 || item.Problem.ProblemType == 3 || item.Problem.ProblemType == 4) {
        count += item.Problem.Score;
      } else if (item.Problem.ProblemType == 10) {
        if (item.SubList) computeScore(item.SubList);
      }
    });
  }
});

/**
 * 判断 = 1,//判断
 * 单选  = 2,//单选
 * 多选 = 3,//多选
 * 主观 = 4,//主观
 * 阅读理解=10,//阅读理解
 * @type {ComputedRef<unknown>}
 */
const getProblemType = (item) => {
  if (exercisesList.length == 0) {
    return;
  }
  if (item.Problem.ProblemType == 1) {
    return use$t('exercise.true.false questions');
  } else if (item.Problem.ProblemType == 2) {
    return use$t('exercise.Multiple-choice.question');
  } else if (item.Problem.ProblemType == 3) {
    return use$t('exercise.Multiple.choice.question');
  } else if (item.Problem.ProblemType == 4) {
    return use$t('exercise.subjective.questions');
  } else if (item.Problem.ProblemType == 10) {
    return use$t('exercise.reading.comprehension.questions');
  }
  return '';
};

/**
 * 题目反馈
 * @param item
 */
const feedbackEvent = (item) => {
  topicVisible.value = true;
  problemId.value = item.Problem.Id;
};

onMounted(() => {
  showLoadingProcess();
  request({
    url: '/Work/GetWorkById',
    method: 'get',
    params: {
      Id: route.query.Id,
      lang: 'zh',
    },
  }).then((resp) => {
    exercisesList.length = 0;
    exercisesList.push(...resp.data.AnswerDetail);
    Toatal.value = resp.data.Toatal;
    let elementsByClassNameElement = progressRef.value.$el.getElementsByClassName('arco-progress-circle-text')[0];
    elementsByClassNameElement.innerHTML = `<p lang="${i18n.locale.value}">${Toatal.value}</p><p lang="${i18n.locale.value}">${use$t('practiceResult.full.mark', { value: score.value })}</p>`;
    hideLoadingProcess();
  });
});

/**
 * 是否已答，答对、答错
 */
const answeredHandle = (item) => {
  if (item.Problem.ProblemType == 1 || item.Problem.ProblemType == 2 || item.Problem.ProblemType == 3) {
    if (item.UserAnswer == item.RightAnswer) {
      return 'index_activated';
    } else if (item.UserAnswer == '') {
      return 'index_error';
    } else {
      return 'index_error';
    }
  }
  if (item.Problem.ProblemType == 4) {
    if (item.UserContent != '') {
      return 'index_activated';
    } else {
      return 'index_error';
    }
  }

  if (item.Problem.ProblemType == 10) {
    if (!item.SubList) return;
    //存在没有答的题目
    const filterNullArr = item.SubList.filter((item) => {
      return ((item.Problem.ProblemType == 1 || item.Problem.ProblemType == 2 || item.Problem.ProblemType == 3) && item.UserAnswer == '') || (item.Problem.ProblemType == 4 && item.UserContent == '');
    });
    if (filterNullArr.length != 0) {
      return 'index_error';
    }

    const filterTrueArr = item.SubList.filter((item) => {
      return (
        ((item.Problem.ProblemType == 1 || item.Problem.ProblemType == 2 || item.Problem.ProblemType == 3) && item.UserAnswer == item.RightAnswer) ||
        (item.Problem.ProblemType == 4 && item.UserContent != '')
      );
    });
    if (filterTrueArr.length == item.SubList.length) {
      //全部正确
      return 'index_activated';
    } else {
      return 'index_error';
    }
  }
};
</script>

<style lang="less" scoped>
.practice_result {
  width: 100vw;
  display: inline-block;
  min-height: 80vh;
  margin-top: 88px;
  background-color: #f8f8f8;
  padding-bottom: 149px;

  .course_header {
    background-color: #ffffff;
    height: 80px;
    display: flex;
    align-items: center;

    .course_view_header {
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .homework_body {
    display: flex;
    justify-content: space-between;
    margin: 20px auto auto;

    .body_l {
      width: 860px;
      min-height: 460px;
      background: #ffffff;

      .statistics {
        width: calc(780px - 22px - 60px);
        margin: 16px 22px 10px;
        height: 150px;
        background: #f5f7fb;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .statistics_l {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-left: 30px;

          .statistics_l_item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0 20px;

            &:first-child {
              margin-left: 0;
              font-weight: bolder;
            }

            &:last-child {
              margin-right: 0;
              font-weight: bolder;
            }

            p:first-child {
              font-family: PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #777777;
              line-height: 20px;
              text-align: left;
              font-style: normal;
            }

            p:last-child {
              font-family: PingFang SC;
              font-size: 40px;
              color: #333333;
              line-height: 56px;
              text-align: left;
              font-style: normal;
            }
          }
        }

        .statistics_r {
          margin-right: 30px;

          :deep(.arco-progress-circle-wrapper) {
            width: 110px !important;
            height: 110px !important;

            .arco-progress-circle-text {
              width: 100px;
              height: 80px;

              p:first-child {
                font-family: PingFang SC;
                font-size: 36px;
                font-weight: bolder;
                color: #333333;
                line-height: 50px;
                text-align: center;
                font-style: normal;

                &:lang(ar),
                &:lang(pk) {
                  line-height: 36px;
                }
              }

              p:last-child {
                font-family: PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #333333;
                line-height: 20px;
                text-align: center;
                font-style: normal;

                &:lang(ar),
                &:lang(pk) {
                  line-height: 14px;
                }
              }
            }
          }
        }
      }

      .body_view {
        width: calc(780px - 22px - 60px);
        background-color: white;
        padding: 16px 22px 10px;

        .problem_title {
          direction: ltr;
          font-family: PingFang SC;
          color: #333333;
          text-align: left;
          font-style: normal;
          margin-top: 20px;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;

          :deep(p) {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
          }
        }

        .problem_type {
          font-family: PingFang SC;
          color: #333333;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 25px;
          letter-spacing: 1px;
        }

        .arco-radio-group,
        .arco-checkbox-group {
          width: 100%;

          .arco-radio,
          .arco-checkbox {
            width: calc(100% - 44px);
            background: #f4f5fb;
            border-radius: 6px;
            padding: 17px 22px;
            margin-top: 12px;

            &:first-child {
              margin-top: 16px;
            }
          }

          .arco-radio-checked,
          .arco-checkbox-checked {
            background-color: #ecf4ff;

            &:has(.select_item-error) {
              background-color: #fae4e4;
            }
          }

          .select_item {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            div:first-child {
              font-family: PingFang SC;
              font-weight: 500;
              font-size: 16px;
              color: #333333;
              line-height: 22px;
              text-align: left;
              font-style: normal;
            }

            div:nth-child(2) {
              width: 1px;
              height: 20px;
              background: #333333;
              margin-left: 15px;
              margin-right: 15px;
            }

            div:last-child {
              font-family: PingFang SC;
              font-weight: 500;
              font-size: 16px;
              color: #333333;
              line-height: 22px;
              text-align: left;
              font-style: normal;
            }
          }

          .select_item-checked {
            div:first-child {
              color: #0678ff !important;
            }

            div:nth-child(2) {
              background-color: #0678ff !important;
            }

            div:last-child {
              color: #0678ff !important;
            }
          }
        }

        .item_line {
          border-top: 1px solid #e1e1e1;
          border-bottom: 0;
          margin-top: 30px;
        }

        .arco-textarea-wrapper {
          margin-top: 20px;
          background: #f4f5fb;
          border-radius: 8px;
          border: 1px solid #dadada;
        }

        :deep(.arco-collapse) {
          width: 100%;
          margin-top: 20px;

          .arco-collapse-item-header {
            height: 56px;
            background: #f4f5fb;
            border-radius: 6px 6px 0 0;

            .arco-collapse-item-icon-right {
              display: flex;
              align-items: center;

              .arco-btn {
                margin-right: 30px;
                width: 100px;
                height: 36px;
                background: #ffffff;
                border-radius: 6px;
                border: 1px solid #0678ff;
                font-family: PingFang SC;
                font-weight: 500;
                font-size: 16px;
                color: #1882ff;
                line-height: 22px;
                text-align: left;
                font-style: normal;
              }
            }

            .arco-collapse-item-header-title {
              display: flex;
              align-items: center;

              p {
                display: flex;
                font-family: PingFang SC;
                font-weight: 500;
                font-size: 16px;
                color: #333333;
                line-height: 22px;
                text-align: left;
                font-style: normal;
                margin: 0;

                &:last-child {
                  margin-left: 50px;
                }
              }
            }

            .expand_text {
              font-family: PingFang SC;
              font-weight: 500;
              font-size: 14px;
              color: #1882ff;
              line-height: 20px;
              text-align: right;
              font-style: normal;
            }

            .arco-typography {
              width: 500px;
              margin: 0;
              font-family: PingFang SC;
              font-weight: 500;
              font-size: 16px;
              color: #333333;
              line-height: 22px;
              font-style: normal;
            }
          }

          .arco-collapse-item-content {
            background-color: #ffffff;
            overflow: hidden;

            &-box {
              padding: 15px 2px;

              p {
                font-family: PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #333333;
                line-height: 22px;
                letter-spacing: 1px;
                text-align: left;
                font-style: normal;
              }

              .feedback {
                margin-top: 20px;
                display: flex;
                align-items: center;

                p {
                  font-family: PingFang SC;
                  font-weight: 500;
                  font-size: 16px;
                  color: #000000;
                  line-height: 22px;
                  text-align: left;
                  font-style: normal;
                }

                .arco-btn {
                  height: 34px;
                  background: #0678ff;
                  border-radius: 18px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  font-size: 14px;
                  color: #ffffff;
                  line-height: 20px;
                  text-align: center;
                  font-style: normal;
                }
              }
            }
          }
        }
      }
    }

    .body_r {
      width: calc(320px - 40px);
      max-height: 1000px;
      background: #ffffff;
      padding: 14px 20px;

      .index-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .header_l {
          align-items: center;
          display: flex;

          div {
            width: 3px;
            height: 10px;
            background: #0678ff;
            margin-right: 7px;
          }

          p {
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #333333;
            line-height: 20px;
            text-align: left;
            font-style: normal;
          }
        }

        .header_r {
          display: flex;

          .header_r_item {
            display: flex;
            align-items: center;

            p {
              font-family: PingFang SC;
              font-weight: 400;
              font-size: 12px;
              color: #575d6c;
              line-height: 17px;
              text-align: left;
              font-style: normal;
            }

            div {
              width: 12px;
              height: 12px;
              background: #ffffff;
              border-radius: 2px;
              border: 1px solid #cdcdcd;
              margin-left: 4px;
            }

            &:nth-child(1) {
              div {
                background: #58c99d;
                border: 1px solid #58c99d;
              }
            }

            &:nth-child(2) {
              margin-left: 10px;

              div {
                background: #ff7b78;
                border: 1px solid #ff7b78;
              }
            }

            &:nth-child(3) {
              margin-left: 10px;

              div {
                background: #ffffff;
                border: 1px solid #cdcdcd;
              }
            }
          }
        }
      }

      .index_body_view {
        min-height: 200px;
        overflow-y: auto;
        padding-bottom: 30px;
        border-bottom: 1px solid #e0e0e0;

        .index_body {
          display: grid;
          grid-template-columns: 36px 36px 36px 36px 36px;
          grid-row-gap: 14px;
          grid-column-gap: 14px;
          max-height: 500px;
          overflow-y: auto;
          overflow-x: hidden;
          margin-top: 25px;
          padding: 0 25px;

          .index_item {
            cursor: pointer;
            width: 36px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid #cdcdcd;
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
            line-height: 20px;
            font-style: normal;
          }

          .index_activated {
            background: #58c99d;
            border: 1px solid #58c99d;
            color: #ffffff;
          }

          .index_error {
            background: #ff7b78;
            border: 1px solid #ff7b78;
            color: #ffffff;
          }
        }
      }

      .arco-btn {
        background: #1882ff;
        border-radius: 21px;
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        line-height: 22px;
        text-align: center;
        font-style: normal;
        margin: 80px auto auto;
        width: 270px;
        height: 36px;
        background: #1882ff;
        border-radius: 21px;
      }
    }
  }

  &:lang(ar),
  &:lang(pk) {
    .problem_title {
      direction: ltr;
    }

    .arco-radio-group,
    .arco-checkbox-group {
      direction: ltr;
    }

    .arco-collapse-item-content-box {
      .analyze {
        direction: ltr !important;
      }
    }

    .expand_text {
      text-align: unset;
    }

    :deep(.arco-collapse-item-icon-right) {
      left: 13px !important;
      right: unset !important;
    }

    :deep(.arco-collapse-item-header-title) {
      p:first-child {
        text-align: unset !important;
        margin-left: 50px !important;
      }
    }

    .statistics_l {
      margin-right: 30px;
    }

    .statistics_r {
      margin-left: 30px;
    }
  }

  :deep(.arco-textarea) {
    direction: ltr;
  }
}
</style>
