<!--课程套餐-->
<template>
  <div class="setMealView">
    <div class="setMealBody">
      <div class="arco-row arco-row-justify-space-between">
        <p class="setMealTitle">{{ $t('setMeal.message.11') }}</p>
        <div class="arco-row arco-row-align-center pointer" @click="useToCurrentPage('/setMeal/list')">
          <span class="more">{{ $t('setMeal.message.01') }}</span>
          <icon-double-right v-if="i18n.locale.value !== 'pk' && i18n.locale.value !== 'ar'" size="18" />
          <icon-double-left v-else size="18" />
        </div>
      </div>

      <div class="cardBody">
        <div v-for="(item, index) in setMealList" :key="index" class="cardItem pointer anime_zoom_lager" @click="useToCurrentPage('/setMeal/detail/' + item.Id)">
          <div class="itemFlag">{{ $t('setMeal.message.02') }}</div>
          <a-image :preview="false" :src="useUrlCv(item.CoverPicURL)"></a-image>
          <div class="item_l">
            <a-typography-text :ellipsis="{ rows: 2 }" class="itemTitle">{{ item.Title }}</a-typography-text>
            <a-typography-text :ellipsis="{ rows: 2 }" class="itemSubTitle">{{ item.Introduction }}</a-typography-text>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useToCurrentPage } from '@/hooks/page.js';
import { onMounted, reactive } from 'vue';
import request from '@/common/request.js';
import { useUrlCv } from '@/hooks/format.js';
import { useI18n } from 'vue-i18n';

const i18n = useI18n();
const setMealList = reactive([]);

onMounted(() => {
  request({
    url: '/Package/GetPackageList',
    method: 'get',
    params: {
      pageIndex: 1,
      pageSize: 4,
    },
  }).then((resp) => {
    setMealList.length = 0;
    setMealList.push(...resp.data.DataList);
  });
});
</script>

<style lang="less" scoped>
.setMealView {
  background-color: #f8f8f8;
  padding-bottom: 50px;

  .setMealBody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    margin: 0 auto;
    padding: 25px 20px;
    width: calc(1200px - 40px);
    min-height: 258px;
    background-image: url('@/assets/images/setMealBg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .setMealTitle {
      font-family: PangMenZhengDao;
      font-size: 36px;
      color: #333333;
      line-height: 41px;
    }

    .more {
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #666666;
      line-height: 22px;
    }

    .arco-icon-double-right {
      color: #666666;
    }

    .cardBody {
      display: grid;
      grid-template-columns: 550px 550px;
      padding: 20px;
      margin-top: 20px;
      grid-gap: 20px;
      background: #ffffff;
      border-radius: 20px;

      .cardItem {
        width: 550px;
        height: 156px;
        background: #f4f9fc;
        border-radius: 12px;
        filter: blur(0px);
        display: flex;

        .itemFlag {
          min-width: 50px;
          height: 25px;
          background: #ff7330;
          border-radius: 8px 0 8px 0;
          position: absolute;
          z-index: 1;
          opacity: 0.8;

          font-family: PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          line-height: 25px;
          text-align: center;
        }

        :deep(.arco-image-img) {
          width: 281px;
          height: 156px;
          border-radius: 12px;
        }

        .item_l {
          margin: 31px 20px 0 20px;

          .itemTitle {
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #333333;
            line-height: 22px;
          }

          .itemSubTitle {
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #999999;
            line-height: 20px;
          }
        }
      }
    }
  }
}
</style>
