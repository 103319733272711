<!--用户协议-->
<template></template>
<script setup>
import { createApp } from 'vue';
import privacy from '@/components/protocol/privacy.vue';
import privacyAr from '@/components/protocol/privacy_Ar.vue';
import privacyId from '@/components/protocol/privacy_Id.vue';
import privacyEn from '@/components/protocol/privacy_En.vue';
import privacyPk from '@/components/protocol/privacy_Pk.vue';
import privacyEs from '@/components/protocol/privacy_Es.vue';
import privacyFr from '@/components/protocol/privacy_Fr.vue';
import { langToBack } from '@/hooks/sysDict.js';
import { useI18n } from 'vue-i18n';

const i18n = useI18n();

if (i18n.locale.value === langToBack.zh) {
  const app = createApp(privacy, {});
  app.mount(document.body);
} else if (i18n.locale.value === langToBack.en) {
  const app = createApp(privacyEn, {});
  app.mount(document.body);
} else if (i18n.locale.value === langToBack.id) {
  const app = createApp(privacyId, {});
  app.mount(document.body);
} else if (i18n.locale.value === langToBack.ar) {
  const app = createApp(privacyAr, {});
  app.mount(document.body);
} else if (i18n.locale.value === langToBack.pk) {
  const app = createApp(privacyPk, {});
  app.mount(document.body);
} else if (i18n.locale.value === langToBack.fr) {
  const app = createApp(privacyFr, {});
  app.mount(document.body);
} else if (i18n.locale.value === langToBack.es) {
  const app = createApp(privacyEs, {});
  app.mount(document.body);
}
</script>

<style lang="less" scoped></style>
