<!--全部课程-->
<template>
  <div :lang="i18n.locale.value" class="course">
    <div class="course_header">
      <div class="w1200 course_view_header">
        <!--        <a-breadcrumb>
                  <template #separator>
                    <icon-double-right />
                  </template>
                  <a-breadcrumb-item>全部课程</a-breadcrumb-item>
                  <a-breadcrumb-item>基础课程</a-breadcrumb-item>
                  <a-breadcrumb-item>{{ $t("header.menu.hsk") }}</a-breadcrumb-item>
                </a-breadcrumb>-->
        <div class="course_type">
          <a-tabs v-model:active-key="queryParams.categoryId" :hide-content="true" auto-switch show-add-button type="card-gutter" @tab-click="queryTags">
            <a-tab-pane key="" :title="$t('individual.center.all')" />
            <a-tab-pane v-for="item in courseCategoryList" :key="item.Id" :title="item.ContentName" />
          </a-tabs>
          <div v-if="queryParams.categoryId != ''" class="course_tags">
            <span class="course_type_text">{{ use$t('common.course.tags') }}：</span>
            <div>
              <a-button :class="queryParams.labelId === '' ? 'activated' : ''" status="normal" type="text" @click="changeTags('')">
                {{ $t('individual.center.all') }}
              </a-button>
              <a-button v-for="(tag, tagIndex) in tags" :key="tagIndex" :class="queryParams.labelId === tag.Id ? 'activated' : ''" status="normal" type="text" @click="changeTags(tag.Id)">
                {{ tag.ContentName }}
              </a-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="course_body">
      <div class="w1200 course_body_view">
        <div class="search_info">
          <p>{{ $t('common.all.course') }}</p>
          <p v-dompurify-html="use$t('all.course.find', { value: tableList.total })"></p>
        </div>
        <div class="search_form">
          <div class="search_form_sort">
            <p :class="queryParams.order === '0' ? 'activated' : ''" @click="sortEvent('0')">
              {{ $t('all.course.sort.synthesis') }}
            </p>
            <p :class="queryParams.order === '1' ? 'activated' : ''" @click="sortEvent('1')">
              {{ $t('all.course.sort.popularity') }}
            </p>
            <p v-show="false" :class="queryParams.order === '2' || queryParams.order === '3' ? 'activated' : ''" class="price" @click="sortEvent('p')">
              {{ $t('detail.course.price') }}
              <span class="price_sort">
                <icon-caret-up :style="{ color: queryParams.order === '2' ? '#0678FF' : '#575d6c' }" class="price_sort_up" />
                <icon-caret-down :style="{ color: queryParams.order === '3' ? '#0678FF' : '#575d6c' }" class="price_sort_down" />
              </span>
            </p>
          </div>
          <a-space>
            <a-checkbox-group v-model="queryParams.feeType" @change="courseTypeChange">
              <a-checkbox v-for="type in courseType" :key="type.value" :value="type.value">{{ use$t(type.label) }}</a-checkbox>
            </a-checkbox-group>
          </a-space>
        </div>
        <template v-if="tableList.list.length !== 0">
          <XyzTransition duration="auto" xyz="fade small in-delay-1">
            <div v-show="loading" class="course_body_card">
              <div v-for="item in tableList.list" :key="item.Id" class="card_item anime_zoom_lager square xyz-nested" @click="useToTabPage('/courseDetail/' + item.Id)">
                <a-image :preview="false" :src="useUrlCv(item.CoverPicURL)" />
                <div class="card_desc">
                  <a-typography-text class="card_desc_title" ellipsis>{{ item.Title }}</a-typography-text>
                  <a-typography-text class="card_desc_desc" ellipsis>{{ item.Introduction }}</a-typography-text>
                </div>
              </div>
            </div>
          </XyzTransition>
          <a-pagination v-model:current="queryParams.pageIndex" v-model:page-size="queryParams.pageSize" :total="tableList.total" @change="changePage" />
        </template>
        <template v-else>
          <Empty />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useCommonCacheStore } from '@/stores/commonCache.js';
import { use$t } from '@/hooks/language.js';
import { onMounted, reactive, ref, watch } from 'vue';
import { useCourseCategoryApi } from '@/hooks/commomApi.js';
import request from '@/common/request.js';
import { hideLoadingProcess, showLoadingProcess, useToCurrentPage, useToTabPage } from '@/hooks/page.js';
import { useUrlCv } from '@/hooks/format.js';
import { courseType } from '@/hooks/sysDict.js';
import { cloneDeep } from 'lodash';
import { useRoute, useRouter } from 'vue-router';
import Empty from '@/components/empty/index.vue';
import { useI18n } from 'vue-i18n';
import { useLocalCacheStore } from '@/stores/localCache.js';
import $ from 'jquery';

const router = useRouter();
const route = useRoute();
const i18n = useI18n();
const cache = useLocalCacheStore();

const queryParams = reactive({
  categoryId: '',
  labelId: '',
  title: '',
  feeType: [], //2免费1付费
  order: '0', //默认综合(0)人气(1)价格升(2)价格降(3)
  pageIndex: 1,
  pageSize: 12,
});

const tableList = reactive({
  list: [],
  total: 0,
});

const commonCache = useCommonCacheStore();
const courseCategoryList = reactive([]);
const tags = reactive([]);
const loading = ref(false);
commonCache.setHeaderWhiteTheme();

onMounted(async () => {
  let value = router.currentRoute.value;
  if (value.query.hasOwnProperty('categoryId')) {
    queryParams.categoryId = value.query.categoryId;
    queryTags(false);
  } else {
    queryParams.categoryId = '';
  }
  //查询课程分类
  let courseCategory = await useCourseCategoryApi();
  courseCategoryList.length = 0;
  courseCategory.data.forEach((item) => {
    courseCategoryList.push({
      Id: item.Id,
      ContentName: item.ContentName,
    });
  });
  if (queryParams.categoryId !== '') {
    const findObj = courseCategoryList.find((item) => item.Id === queryParams.categoryId);
    if (findObj === undefined) {
      queryParams.categoryId = '';
      useToCurrentPage('/allCourse');
    }
  }
  if (value.query.hasOwnProperty('searchValue')) {
    queryParams.title = value.query.searchValue;
  }
  if (route.query.feeType) {
    queryParams.feeType.push(route.query.feeType);
  }
  getList();
});

/**
 * 切换分类，查询标签
 * @param categoryId
 * @param labelName
 */
const queryTags = (fetchList = true) => {
  queryParams.labelId = '';
  queryParams.pageIndex = 1;
  if (queryParams.categoryId === '') {
    tags.length = 0;
    getList();
    return;
  }
  request({
    url: '/Label/GetLabelList',
    method: 'get',
    params: {
      categoryId: queryParams.categoryId,
    },
  }).then((resp) => {
    tags.length = 0;
    tags.push(...resp.data);
  });
  if (fetchList) getList();
};

/**
 * 切换tag
 * @param value
 */
const changeTags = (value) => {
  queryParams.labelId = value;
  queryParams.pageIndex = 1;
  getList();
};

/**
 * 课程免费、收费分类切换
 * @param val
 */
const courseTypeChange = (value, ev) => {
  if (value.length > 1) {
    queryParams.feeType = [value[1]];
  } else {
    queryParams.feeType = value;
  }
  getList();
};

/**
 * 课程排序
 */
const sortEvent = (value) => {
  if (value === 'p') {
    if (queryParams.order === '2') {
      queryParams.order = '3';
    } else {
      queryParams.order = '2';
    }
  } else {
    queryParams.order = value;
  }
  getList();
};

const changePage = () => {
  $('html, body').scrollTop(0);
  getList();
};

/**
 * 分页查询数据
 */
const getList = () => {
  showLoadingProcess();
  loading.value = false;
  let CountryId = cache.userinfo?.CountryId || '49e31065-520e-46b1-8cf2-9ce44cf7ecc8';
  request({
    url: '/Course/GetMoreCourseList',
    method: 'get',
    params: Object.assign(cloneDeep(queryParams), { feeType: queryParams.feeType[0], countryId: CountryId }),
  }).then((resp) => {
    hideLoadingProcess();
    loading.value = true;
    tableList.list = resp.data.DataList;
    tableList.total = resp.data.Total;
    queryParams.pageIndex = resp.data.PageIndex;
    queryParams.pageSize = resp.data.PageSize;
  });
};

watch(
  () => router.currentRoute.value,
  (value, oldValue, onCleanup) => {
    if (value.query.hasOwnProperty('searchValue')) {
      queryParams.title = value.query.searchValue;
      getList();
    }
  },
  { immediate: false }
);
</script>

<style lang="less" scoped>
.course {
  width: 1920px;
  display: inline-block;
  min-height: 80vh;
  margin-top: 88px;

  .course_header {
    background-color: #f8f8f8;

    .course_view_header {
      display: flex;
      margin: auto;

      .arco-breadcrumb {
        margin-top: 30px;
      }

      .course_type {
        width: 100%;
        margin-top: 45px;

        :deep(.arco-tabs-tab) {
          height: 32px;
          border: 0;
          padding-bottom: 0;
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #575d6c;
          line-height: 22px;
          text-align: left;
          font-style: normal;
        }

        :deep(.arco-tabs-nav) {
          height: 33px;

          &::before {
            background-color: #0678ff !important;
            height: 1px;
            z-index: 1;
          }
        }

        :deep(.arco-tabs-nav-tab) {
          height: 33px !important;
        }

        :deep(.arco-tabs-tab-active) {
          background: #0678ff;
          border-radius: 6px 6px 0 0;
          color: white;
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          text-align: left;
          font-style: normal;
        }

        .course_tags {
          display: flex;
          align-items: baseline;
          margin-bottom: 30px;
          margin-top: 30px;

          .course_type_text {
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #575d6c;
            line-height: 22px;
            white-space: nowrap;
            font-style: normal;
          }

          :deep(.arco-btn-status-normal) {
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #575d6c;
            line-height: 22px;
            text-align: center;
            font-style: normal;
            margin: 0 10px;
          }

          .activated {
            background: transparent;
            border-radius: 6px;
            color: #0678ff;
          }
        }
      }
    }
  }

  .course_body {
    background-color: white;

    .course_body_view {
      margin: auto;
      padding: 40px 0;

      .search_info {
        display: flex;
        justify-content: space-between;

        p:first-child {
          font-family: PingFang SC;
          font-weight: 600;
          font-size: 20px;
          color: #333333;
          line-height: 28px;
          text-align: left;
          font-style: normal;
        }

        p:last-child {
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 20px;
          color: #575d6c;
          line-height: 28px;
          text-align: left;
          font-style: normal;

          :deep(span) {
            color: black;
            font-weight: bold;
            margin: 0 5px;
          }
        }
      }

      .search_form {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        .search_form_sort {
          display: flex;
          align-items: center;

          p {
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #575d6c;
            line-height: 22px;
            text-align: left;
            font-style: normal;
            margin-left: 40px;
            cursor: pointer;

            &:first-child {
              margin-left: 0;
            }
          }

          .activated {
            color: #0678ff;
          }

          .price {
            display: flex;

            .price_sort {
              display: flex;
              flex-direction: column;
              align-items: center;

              .price_sort_up {
                font-size: 12px;
                position: relative;
                top: 3px;
              }

              .price_sort_down {
                font-size: 12px;
                position: relative;
                top: -3px;
              }
            }
          }
        }

        :deep(.arco-checkbox-label) {
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #575d6c;
          line-height: 22px;
          text-align: left;
          font-style: normal;
        }

        :deep(.arco-checkbox-icon) {
          height: 18px;
          width: 18px;
        }
      }

      .course_body_card {
        display: grid;
        grid-template-columns: 285px 285px 285px 285px;
        grid-gap: 20px;
        margin-top: 30px;

        .card_item {
          background: #ffffff;
          height: 250px;
          box-shadow:
            0 0 10px 0 rgba(1, 10, 29, 0.15),
            0 0 3px 0 rgba(142, 142, 142, 0.41);
          border-radius: 12px;
          filter: blur(0px);
          cursor: pointer;

          :deep(.arco-image-img) {
            width: 285px;
            height: 158px;
            border-radius: 12px 12px 0 0;
          }

          .card_desc {
            padding: 10px 12px;

            .card_desc_title {
              font-family: PingFang SC;
              font-weight: 500;
              font-size: 16px;
              color: #333333;
              text-shadow: 0 0 10px rgba(1, 10, 29, 0.15);
              font-style: normal;
              margin: 0;
            }

            .card_desc_desc {
              font-family: PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #8d8d8d;
              text-shadow: 0 0 10px rgba(1, 10, 29, 0.15);
              font-style: normal;
              margin: 10px 0 0;
            }
          }
        }
      }

      :deep(.arco-pagination) {
        justify-content: center;
        margin-top: 70px;
      }
    }
  }

  &:lang(ar),
  &:lang(pk) {
    .search_form_sort {
      p {
        margin-left: 0;
        margin-right: 40px;

        &:first-child {
          margin-right: 0;
        }
      }
    }

    :deep(.arco-checkbox-label) {
      margin-left: 0;
      margin-right: 8px;
    }
  }
}
</style>
