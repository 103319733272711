<!--首页-->
<template>
  <div class="home_view">
    <!--  轮播图  -->
    <HomeSwiper />
    <!--  我们的优势  -->
    <HomeAdvantage />
    <!-- 最新消息，推荐课程   -->
    <HomeNews />
    <!--  课程套餐  -->
    <SetMeal />
    <!--  课程类型陈列  -->
    <HomeCourseRoom />
    <!--  客服  -->
    <CallCenter />
  </div>
</template>

<script setup>
import { defineAsyncComponent, onMounted } from 'vue';
import { useLocalCacheStore } from '@/stores/localCache.js';
import { useI18n } from 'vue-i18n';
import { use$t, useChangeLanguage } from '@/hooks/language.js';
import HomeSwiper from '@/views/home/components/HomeSwiper.vue';
import { useCommonCacheStore } from '@/stores/commonCache.js';
import HomeAdvantage from '@/views/home/components/HomeAdvantage.vue';
import HomeNews from '@/views/home/components/HomeNews.vue';
import SetMeal from '@/views/home/components/SetMeal.vue';
import HomeCourseRoom from '@/views/home/components/HomeCourseRoom.vue';
import CallCenter from '@/components/callCenter/index.vue';
import MarketingModal from '@/components/marketingModal/index.js';
import { useCheckIsLogin, useGetUserinfo } from '@/hooks/commomApi.js';
import moment from 'moment';

const { t, locale, availableLocales, getLocaleMessages } = useI18n();
const localCache = useLocalCacheStore();
const commonCache = useCommonCacheStore();
const change = () => {
  localCache.defaultLanguage = localCache.defaultLanguage === 'en' ? 'zh' : 'en';
  useChangeLanguage(localCache.defaultLanguage);
};
onMounted(() => {
  commonCache.setHeaderDefaultTheme();
  //营销活动弹框
  // useCheckIsLogin(
  //   () => {
  //     //已登录
  //     useGetUserinfo(() => {
  //       const after7Days = moment(localCache.userinfo.CreateTime).add(7, 'days').unix();
  //       const now = moment().unix();
  //       if (after7Days >= now) {
  //         MarketingModal(use$t('marketing.message.06'), 'course');
  //       }
  //     });
  //   },
  //   false,
  //   () => {
  //     //没有登录
  //     MarketingModal(use$t('marketing.message.05'), 'register');
  //   }
  // );
});
</script>

<style lang="less" scoped>
.home_view {
  min-height: 100vh;
}
</style>