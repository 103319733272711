<template>
  <div class="course_detail">
    <div class="course_header">
      <div class="w1200 course_view_header">
        <a-breadcrumb>
          <template #separator>
            <icon-double-right />
          </template>
          <a-breadcrumb-item class="pointer" @click="useToTabPage('/allCourse')">{{ $t('detail.course.classify') }}</a-breadcrumb-item>
          <a-breadcrumb-item>{{ breadcrumbName }}</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="collect" @click="collectEvent">
          <SvgIcon :icon-class="isCollection ? 'favor_fill' : 'favor'" :lang="i18n.locale.value" class-name="favor_icon"></SvgIcon>
          <span>{{ isCollection ? $t('detail.course.uncollect') : $t('detail.course.collect') }}</span>
        </div>
      </div>
    </div>
    <div class="course_detail_view">
      <XyzTransition duration="auto" xyz="fade small">
        <div class="media_show square xyz-nested">
          <PreviewView ref="previewRef">
            <template #showroom>
              <div v-if="showThumbnail" class="thumbnail_group">
                <template v-for="(item, index) in courseDetailObj.ShowURL">
                  <template v-if="item.type === 'image'">
                    <div
                      :class="activeShowObj.Id === item.Id ? 'activited' : ''"
                      :style="{ backgroundImage: `url('${encodeURI(item.url)}')` }"
                      class="thumbnail_item anime_zoom_lager"
                      @click="activeShowEvent(item)"
                    >
                      <!--  <a-image :preview="false" :src="item.url" />-->
                    </div>
                  </template>
                  <template v-else>
                    <video
                      :class="activeShowObj.Id === item.Id ? 'activited' : ''"
                      :src="item.url"
                      autoplay
                      class="thumbnail_item anime_zoom_lager"
                      loop
                      muted
                      preload
                      @click="activeShowEvent(item)"
                    ></video>
                  </template>
                </template>
              </div>
            </template>
          </PreviewView>
          <!--          <video id="videoPlayer_course_detail" class="video-js media_show_video">
                      <p class="vjs-no-js">
                        To view this video please enable JavaScript, and consider upgrading to a web browser that
                        <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
                      </p>
                    </video>-->
        </div>
      </XyzTransition>
      <div class="course_detail_desc">
        <div class="course_detail_title">
          <a-tag bordered>{{ $t('detail.tag.video') }}</a-tag>
          <a-typography-text ellipsis>{{ courseDetailObj.Title }}</a-typography-text>
        </div>
        <div class="course_detail_sub_title">
          <!--          <p>讲师：黑大帅</p>
                    <div></div>-->
          <p>{{ $t('home.learned.count', { value: courseDetailObj.StudyNum }) }}</p>
          <div style="display: none"></div>
          <p style="display: none">{{ $t('detail.lessons.number', { value: courseDetailObj.Count }) }}</p>
        </div>
        <div class="course_price_info">
          <div v-if="courseDetailObj.PriceUsdList.length !== 0" :lang="i18n.locale.value" class="course_price">
            <p>{{ $t('detail.course.price') }}</p>
            <!--            <p v-else>{{ $t('currency.symbol') }}&nbsp;{{ courseDetailObj.InterPrice }}</p>-->
            <p v-if="courseDetailObj.PriceUsdList.length === 1">$&nbsp;{{ courseDetailObj.PriceUsdList[0].Price.toFixed(2) }}</p>
            <p v-else>$&nbsp;{{ courseDetailObj.PriceUsdList[0].Price.toFixed(2) }} - {{ courseDetailObj.PriceUsdList[courseDetailObj.PriceUsdList.length - 1].Price.toFixed(2) }}</p>
            <!--            <a-typography-text delete>￥3000</a-typography-text>-->
          </div>
          <!--          <div v-dompurify-html="$t('detail.course.plundered', { value: 166 })" class="course_sell"></div>-->
          <div v-else :lang="i18n.locale.value" class="course_price">
            <p>{{ $t('detail.course.price') }}</p>
            <p v-if="courseDetailObj.FeeType == '2'">{{ $t('home.price.free') }}</p>
          </div>
        </div>
        <div :lang="i18n.locale.value" class="course_special_service">
          <p>{{ $t('detail.course.characteristic.service') }}</p>
          <p>
            <SvgIcon class-name="special_service_icon" icon-class="safe" />
            {{ $t('detail.course.brush.questions') }}
          </p>
          <p>
            <SvgIcon class-name="special_service_icon" icon-class="time" />
            {{ $t('detail.course.online.consultation') }}
          </p>
          <p>
            <SvgIcon class-name="special_service_icon" icon-class="down" />
            {{ $t('detail.course.download.anytime') }}
          </p>
        </div>
        <div class="course_detail_line"></div>
        <div :lang="i18n.locale.value" class="course_detail_btn_group">
          <template v-if="courseDetailObj.Count != 0">
            <a-button v-if="courseDetailObj.FeeType == '2' || alreadyOwned === true" class="buy_btn" @click="studyNow">
              <div class="btn_content">
                {{ $t('detail.course.study.now') }}
              </div>
            </a-button>
            <a-button v-else-if="courseDetailObj.FeeType == '1' && alreadyOwned === false" class="buy_btn" @click="buyNow">
              <div class="btn_content">
                {{ $t('detail.course.buy.now') }}
              </div>
            </a-button>
          </template>
          <a-button @click="consulEvent" @mouseleave="mouseleave" @mouseover="mouseover">
            <img :src="phoneGif" class="kefu_icon" />
            {{ $t('detail.course.consult.now') }}
          </a-button>
        </div>
      </div>
    </div>
    <div class="course_content">
      <div class="course_content_detail">
        <a-tabs default-active-key="1" lazy-load>
          <a-tab-pane key="1" :title="$t('detail.course.course.details')">
            <CourseContent :content="courseDetailObj.Content" />
          </a-tab-pane>
          <a-tab-pane v-if="courseDetailObj.Count != 0" key="2" :title="$t('detail.course.course.chapter')">
            <CourseChapter :course-id="courseDetailObj.Id" :freeType="courseDetailObj.FeeType" :sign="courseDetailObj.Sign" />
          </a-tab-pane>
        </a-tabs>
      </div>
      <div class="other_recommend">
        <p class="other_recommend_title">{{ $t('detail.course.other.recommendations') }}</p>

        <div v-for="(item, index) in recommendCourseList" :key="index" class="recommend_item pointer" @click="useToTabPage('/courseDetail/' + item.Id)">
          <a-image :preview="false" :src="useUrlCv(item.CoverPicURL)" class="anime_zoom_lager"></a-image>
          <div :lang="i18n.locale.value" class="recommend_item_desc">
            <a-typography-text
              :ellipsis="{
                rows: 2,
              }"
            >
              {{ item.Title }}
            </a-typography-text>
            <p class="buy_count">{{ $t('home.learned.count', { value: item.StudyNum }) }}</p>
            <template v-if="item.FeeType == 1">
              <p v-if="item.PriceUsdList.length === 0" class="cursor_price_no">-</p>
              <p v-else-if="item.PriceUsdList.length === 1" class="cursor_price_no">{{ $t('currency.symbol') }}&nbsp;{{ item.PriceUsdList[0].Price.toFixed(2) }}</p>
              <p v-else class="cursor_price_no">
                {{ $t('currency.symbol') }}&nbsp;{{ item.PriceUsdList[0].Price.toFixed(2) }} -
                {{ item.PriceUsdList[item.PriceUsdList.length - 1].Price.toFixed(2) }}
              </p>
            </template>
            <p v-else class="cursor_price_no">{{ $t('common.all.course.free') }}</p>
          </div>
        </div>
        <empty v-if="recommendCourseList.length == 0" />
      </div>
    </div>
  </div>
  <Consult v-model:consult-visible="consultVisible" :courseId="courseDetailObj.Id" />
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue';
import PreviewView from '@/components/video/PreviewView.vue';
import SvgIcon from '@/components/svgIcon/index.vue';
import CourseContent from '@/views/courseDetail/components/CourseContent.vue';
import CourseChapter from '@/views/courseDetail/components/CourseChapter.vue';
import Consult from '@/components/consult/index.vue';
import Payment from '@/components/payment/index.js';
import { useToCurrentPage, useToTabPage } from '@/hooks/page.js';
import { useRoute } from 'vue-router';
import request from '@/common/request.js';
import { useCheckBuyCourse, useCheckIsCollect, useCheckIsLogin, useCourseCategoryApi, useRecommendCourse } from '@/hooks/commomApi.js';
import { useUrlCv } from '@/hooks/format.js';
import { useHead } from '@unhead/vue';
import { useI18n } from 'vue-i18n';
import { v4 as uuidv4 } from 'uuid';
import { Notification } from '@arco-design/web-vue';
import { use$t } from '@/hooks/language.js';
import Confirm from '@/components/common/Confirm/index.js';
import gif_w from '@/assets/images/phone.gif';
import gif_b from '@/assets/images/phone_b.gif';
import Empty from '@/components/empty/index.vue';
import activationSuccessful from '@/components/activationSuccessful/index.js';

const i18n = useI18n();
const consultVisible = ref(false);
const route = useRoute();
const recommendCourseList = reactive([]);
const breadcrumbName = ref('');
const previewRef = ref();
const alreadyOwned = ref(false);
const isCollection = ref(false);
const phoneGif = ref(gif_b);
const activeShowObj = reactive({
  Id: '',
  type: '',
  url: '',
});
const courseDetailObj = reactive({
  Id: '',
  Title: '',
  StudyNum: 0,
  Content: '',
  InterPrice: 0,
  Count: 0,
  Sign: '',
  FeeType: '', //2免费1付费
  ShowURL: [],
  CoverPicURL: '',
  PriceEurList: [],
  PriceUsdList: [],
});

const showThumbnail = ref(true);

const mouseleave = () => {
  phoneGif.value = gif_b;
};

const mouseover = () => {
  phoneGif.value = gif_w;
};

/**
 * 点击预览
 * @param item
 */
const activeShowEvent = (item) => {
  previewRef.value.setActiveValue({
    type: item.type,
    url: item.url,
  });
  activeShowObj.Id = item.Id;
  activeShowObj.type = item.type;
  activeShowObj.url = item.url;
};

/**
 * 立即购买
 */
const buyNow = () => {
  useCheckIsLogin(() => {
    Payment(courseDetailObj, () => {
      activationSuccessful(() => {
        studyNow();
        alreadyOwned.value = true;
      });
    });
  });
};
/**
 * 立即学习
 */
const studyNow = () => {
  useCheckIsLogin(() => {
    if (courseDetailObj.FeeType == 2) {
      //加入我的学习
      request({
        url: '/Course/BuyCourse',
        method: 'get',
        params: {
          courseId: courseDetailObj.Id,
        },
      }).then((resp) => {
        useToCurrentPage('/coursePlayer', {
          sign: courseDetailObj.Sign,
          chapterId: '',
          videoId: '',
          courseId: courseDetailObj.Id,
        });
      });
    } else {
      useToCurrentPage('/coursePlayer', {
        sign: courseDetailObj.Sign,
        chapterId: '',
        videoId: '',
        courseId: courseDetailObj.Id,
      });
    }
  });
};

/**
 * 视频收藏，取消收藏
 */
const collectEvent = () => {
  useCheckIsLogin(() => {
    if (isCollection.value) {
      //取消收藏
      Confirm({
        title: use$t('detail.course.uncollect'),
        content: use$t('individual.favorite.modal.content') + '?',
        okEvent: () => {
          request({
            url: '/Course/CancelCollect',
            method: 'get',
            params: {
              courseId: courseDetailObj.Id,
            },
          }).then((resp) => {
            console.log('已取消收藏', resp);
            isCollection.value = false;
          });
        },
      });
    } else {
      //收藏
      request({
        url: '/Course/AddCollect',
        method: 'get',
        params: {
          courseId: courseDetailObj.Id,
          sign: courseDetailObj.Sign,
        },
      }).then((resp) => {
        console.log('已收藏', resp);
        isCollection.value = true;
      });
    }
  });
};

/**
 * 课程咨询
 */
const consulEvent = () => {
  useCheckIsLogin(() => {
    consultVisible.value = true;
  });
};

useCheckIsLogin(() => {
  useCheckIsCollect(route.params.id).then((resp) => {
    if (resp === 1) {
      isCollection.value = true;
    } else {
      isCollection.value = false;
    }
  });
}, false);

onMounted(() => {
  request({
    url: '/Course/GetCourseDetails',
    method: 'get',
    params: { Id: route.params.id },
  }).then(async (resp) => {
    courseDetailObj.Id = resp.data.Id;
    courseDetailObj.Title = resp.data.Title;
    courseDetailObj.StudyNum = resp.data.StudyNum;
    courseDetailObj.Content = resp.data.Content;
    courseDetailObj.InterPrice = resp.data.InterPrice;
    courseDetailObj.Count = resp.data.Count;
    courseDetailObj.Count = resp.data.Count;
    courseDetailObj.Sign = resp.data.Sign;
    courseDetailObj.CoverPicURL = resp.data.CoverPicURL;
    courseDetailObj.FeeType = resp.data.FeeType;
    courseDetailObj.Introduction = resp.data.Introduction;
    courseDetailObj.PriceUsdList = resp.data.PriceList.filter((item) => item.CurrencyCode === 'USD');
    courseDetailObj.PriceEurList = resp.data.PriceList.filter((item) => item.CurrencyCode === 'EUR');
    courseDetailObj.PriceUsdList.sort(function (a, b) {
      if (a.Price > b.Price) return 1;
      if (a.Price < b.Price) return -1;
      return 0;
    });
    const showArr = resp.data.ShowURL.split(',');
    showArr.forEach((item) => {
      if (item.indexOf('.mp4') > -1) {
        //视频
        courseDetailObj.ShowURL.push({
          Id: uuidv4(),
          type: 'video',
          url: useUrlCv(item),
        });
      } else {
        courseDetailObj.ShowURL.push({
          Id: uuidv4(),
          type: 'image',
          url: useUrlCv(item),
        });
      }
    });
    activeShowObj.Id = courseDetailObj.ShowURL[0].Id;
    activeShowObj.type = courseDetailObj.ShowURL[0].type;
    activeShowObj.url = courseDetailObj.ShowURL[0].url;
    previewRef.value.setActiveValue({
      type: courseDetailObj.ShowURL[0].type,
      url: courseDetailObj.ShowURL[0].url,
    });

    //获取课程分类
    const courseCategory = await useCourseCategoryApi();
    const findObj = courseCategory.data.find((item) => {
      return item.Id === resp.data.CategoryId;
    });
    breadcrumbName.value = findObj.ContentName;

    //设置网页头
    useHead({
      title: courseDetailObj.Title,
    });

    let promise = await useCheckBuyCourse(courseDetailObj.Id, courseDetailObj.FeeType);
    if (promise.data !== 0) {
      alreadyOwned.value = true;
    }
  });

  //获取推荐课程
  useRecommendCourse(1, route.params.id).then((resp) => {
    recommendCourseList.length = 0;
    recommendCourseList.push(...resp.data);
    recommendCourseList.forEach((item) => {
      item['PriceUsdList'] = item.PriceList.filter((item) => item.CurrencyCode === 'USD');
      item['PriceEurList'] = item.PriceList.filter((item) => item.CurrencyCode === 'EUR');
      delete item['PriceList'];
      item['PriceUsdList'].sort(function (a, b) {
        if (a.Price > b.Price) return 1;
        if (a.Price < b.Price) return -1;
        return 0;
      });
    });
  });

  // let player = videojs('videoPlayer_course_detail', videoOptions);
  // window.addEventListener('scroll', (event) => {
  //   if (document.documentElement.scrollTop > 300 && !player.paused()) {
  //     if (!player.isInPictureInPicture()) {
  //       document.getElementsByClassName('vjs-picture-in-picture-control')[0].click();
  //       // player.requestPictureInPicture().catch((err) => {
  //       //   console.error(err);
  //       // });
  //     }
  //   }
  //   if (document.documentElement.scrollTop < 200) {
  //     if (player.isInPictureInPicture()) {
  //       player.exitPictureInPicture().catch((err) => {
  //         console.error(err);
  //       });
  //     }
  //   }
  // });
});
</script>

<style lang="less" scoped>
.course_detail {
  width: 1920px;
  display: inline-block;
  min-height: 80vh;
  margin-top: 88px;
  background-color: #f5f7f9;
  padding-bottom: 149px;

  .course_header {
    background-color: transparent;

    .course_view_header {
      margin: 30px auto auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .collect {
        padding: 0 20px;
        height: 30px;
        background: #ffffff;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        span {
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #666666;
          line-height: 22px;
          text-align: left;
          font-style: normal;
        }

        .favor_icon {
          width: 18px;
          height: 18px;
          margin-right: 10px;

          &:lang(ar),
          &:lang(pk) {
            margin-right: 0;
            margin-left: 10px;
          }
        }
      }
    }
  }

  .course_detail_view {
    margin: 30px auto auto;
    width: calc(1200px - 60px);
    height: calc(420px - 80px);
    background: #ffffff;
    padding: 40px 30px;
    display: flex;

    .media_show {
      background: #ffffff;
      flex-direction: column;
      display: flex;

      :deep(.thumbnail_group) {
        width: 100%;
        height: 80px;
        overflow: hidden;
        position: relative;
        padding: 0;
        bottom: 90px;
        align-items: center;
        display: flex;

        .thumbnail_item {
          width: 128px;
          height: 72px;
          margin-left: 9px;
          cursor: pointer;
          background-size: 100% 100%;
          background-repeat: no-repeat;

          //.arco-image-img {
          //  width: 127px !important;
          //  height: 72px !important;
          //  box-shadow: 5px 5px 5px 1px red inset;
          //}

          &:first-child {
            margin-left: 18px;
          }

          &:last-child {
            margin-right: 18px;
          }
        }

        .activited {
          border: 2px solid #145ee5;
          box-shadow:
            1px 1px 1px 1px #ffffff inset,
            -1px -1px 1px 1px #ffffff inset;
        }
      }
    }

    .course_detail_desc {
      width: calc(600px - 40px);
      padding: 0 20px;

      .course_detail_title {
        display: flex;
        align-items: center;

        .arco-tag {
          height: 23px;
          border-radius: 4px;
          border: 1px solid #0678ff;
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #0678ff;
          text-align: center;
          font-style: normal;

          text-overflow: unset;
          overflow: auto;
          box-sizing: unset;
        }

        :deep(.arco-typography) {
          font-family: PingFang SC;
          font-weight: 600;
          font-size: 24px;
          flex-grow: 1;
          color: #333333;
          line-height: 33px;
          text-align: left;
          font-style: normal;
          margin: 0 0 0 10px;
        }
      }

      .course_detail_sub_title {
        display: flex;
        align-items: center;
        margin-top: 17px;

        p {
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #575d6c;
          line-height: 20px;
          text-align: left;
          font-style: normal;
        }

        div {
          width: 1px;
          height: 15px;
          background-color: rgba(87, 93, 108, 0.5);
          margin: 0 10px;
        }
      }

      .course_price_info {
        width: 550px;
        height: 65px;
        margin-top: 20px;
        background-image: url('@/assets/images/course_price_bg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .course_price {
          display: flex;
          align-items: center;
          margin-left: 10px;

          p:first-child {
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #575d6c;
            line-height: 20px;
            text-align: left;
            font-style: normal;
          }

          p:nth-child(2) {
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 20px;
            color: #f54030;
            line-height: 28px;
            text-align: left;
            font-style: normal;
            margin-left: 24px;
          }

          &:lang(ar),
          &:lang(pk) {
            margin-left: 0;
            margin-right: 10px;

            p:nth-child(2) {
              margin-left: 0;
              margin-right: 24px;
            }
          }

          .arco-typography {
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #999999;
            line-height: 20px;
            text-align: left;
            font-style: normal;
            margin-left: 12px;
          }
        }

        .course_sell {
          font-family: PingFang SC;
          font-weight: 600;
          font-size: 14px;
          color: #999999;
          line-height: 20px;
          text-align: left;
          font-style: normal;
          margin-right: 20px;

          :deep(span) {
            color: #f54030;
          }
        }
      }

      .course_special_service {
        display: flex;
        align-items: center;
        margin-top: 20px;

        p {
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
          line-height: 20px;
          text-align: left;
          font-style: normal;
          margin-left: 20px;
          display: flex;
          align-items: center;

          .special_service_icon {
            width: 16px;
            height: 16px;
            margin-right: 3px;
          }
        }

        p:first-child {
          color: #575d6c;
          margin-left: 0;
        }

        &:lang(en) {
          justify-content: space-between;

          p {
            font-size: 12px;
            margin: 0;
          }
        }

        &:lang(ar),
        &:lang(pk) {
          justify-content: space-between;

          p {
            font-size: 12px;
            margin: 0;

            .special_service_icon {
              margin-left: 3px;
              margin-right: 0;
            }
          }
        }
      }

      .course_detail_line {
        width: 550px;
        height: 1px;
        background: #ededed;
        margin-top: 20px;
      }

      .course_detail_btn_group {
        display: flex;
        align-items: center;
        margin-top: 35px;

        .arco-btn {
          min-width: 148px;
          height: 44px;
          background: #0678ff;
          border-radius: 4px;
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #ffffff;
          line-height: 25px;
          margin-right: 30px;
          text-align: center;
          font-style: normal;

          &:last-child {
            background: #ffffff;
            border: 1px solid #0678ff;
            font-weight: 400;
            color: #0678ff;

            .kefu_icon {
              width: 22px;
              height: 22px;
              margin-right: 5px;
            }
          }
        }

        &:lang(ar),
        &:lang(pk) {
          .arco-btn {
            width: auto;
            margin-right: 0;

            &:last-child {
              margin-left: 0;
              margin-right: 30px;

              .kefu_icon {
                margin-right: 0;
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
  }

  .course_content {
    width: 1200px;
    margin: 20px auto auto;
    display: flex;
    justify-content: space-between;

    .course_content_detail {
      width: 820px;
      min-height: 510px;
      background-color: #ffffff;

      :deep(.arco-tabs) {
        margin: 0 20px;
      }

      :deep(.arco-tabs-tab) {
        line-height: 50px;
        margin-left: 20px;

        &:nth-child(2) {
          margin-left: 100px;
        }
      }

      :deep(.arco-tabs-content) {
        padding-bottom: 20px;
      }
    }

    .other_recommend {
      width: calc(360px - 40px);
      min-height: 510px;
      background-color: #ffffff;
      padding: 20px;
      height: fit-content;

      .other_recommend_title {
        font-family: PingFang SC;
        font-weight: 600;
        font-size: 18px;
        color: #333333;
        line-height: 25px;
        font-style: normal;
      }

      .recommend_item {
        display: flex;
        align-items: center;
        margin-top: 20px;

        :deep(.arco-image-img) {
          width: 156px;
          height: 88px;
        }

        .recommend_item_desc {
          height: 88px;
          margin-left: 16px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          &:lang(ar),
          &:lang(pk) {
            margin-left: 0;
            margin-right: 16px;
          }

          .arco-typography {
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #575d6c;
            line-height: 20px;
            font-style: normal;
            margin: 0;
          }

          .buy_count {
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #666666 !important;
            line-height: 17px;
            font-style: normal;
          }

          .cursor_price_no {
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #f54030;
            line-height: 20px;
            font-style: normal;
          }
        }
      }
    }
  }
}

:deep(.arco-tabs-content-list) {
  display: inline;
}
</style>
