<!--我的作业-->
<template>
  <div :lang="i18n.locale.value" class="my_homework">
    <div class="my_homework_header">
      <p :class="queryParams.statu === '' ? 'activated' : ''" @click="changeType('')">{{ $t('individual.center.all') }}</p>
      <p :class="queryParams.statu === 0 ? 'activated' : ''" @click="changeType(0)">
        {{ $t('individual.center.correcting') }}
      </p>
      <p :class="queryParams.statu === 1 ? 'activated' : ''" @click="changeType(1)">
        {{ $t('individual.center.corrected') }}
      </p>
    </div>
    <XyzTransition duration="auto" xyz="fade small">
      <div v-if="workList.loading" class="my_homework_body">
        <div v-for="(item, index) in workList.list" :key="index" class="homework_item xyz-nested" @click="clickEvent(item)">
          <a-image :preview="false" :src="useUrlCv(item.CoverPicURL)" />
          <div class="item_text">
            <a-typography-text class="item_text_title" ellipsis>{{ item.Title }}</a-typography-text>
            <div class="item_text_desc">
              <a-typography-text ellipsis>{{ item.VideoTitle }}</a-typography-text>
              <a-typography-text>{{ useFormatDate(item.CreateTime, 'YYYY.MM.DD') }}</a-typography-text>
            </div>
          </div>
          <div class="homework_item_mask">
            <SvgIcon v-show="false" class-name="check-circle_icon" icon-class="check-circle" />
            <SvgIcon class-name="time-circle_icon" icon-class="time-circle" />
            <div class="mask_text">
              {{ item.CorrectionState ? $t('individual.center.corrected') : $t('individual.center.correcting') }}
            </div>
          </div>
        </div>
      </div>
    </XyzTransition>
    <Empty v-if="workList.total === 0 && workList.loading == true" />
    <a-pagination v-if="workList.total != 0" :total="workList.total" />
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue';
import SvgIcon from '@/components/svgIcon/index.vue';
import { useToHome, useToTabPage } from '@/hooks/page.js';
import request from '@/common/request.js';
import { useFormatDate, useUrlCv } from '@/hooks/format.js';
import Alert from '@/components/common/Alert/index.js';
import { use$t } from '@/hooks/language.js';
import { useCheckIsLogin, useGetUserinfo } from '@/hooks/commomApi.js';
import { useI18n } from 'vue-i18n';

const i18n = useI18n();

const queryParams = reactive({
  pageIndex: 1,
  pageSize: 6,
  statu: '', //0未批改，1已批改
});

const workList = reactive({
  list: [],
  loading: false,
  total: 0,
});

const type = ref(1);

const changeType = (val) => {
  queryParams.statu = val;
  queryParams.pageIndex = 1;
  getList();
};

/**
 * 获取列表数据
 */
const getList = () => {
  useCheckIsLogin(
    () => {},
    false,
    () => {
      useToHome();
    }
  );
  workList.loading = false;
  request({
    url: '/Video/GetWorkVideo',
    method: 'get',
    params: queryParams,
  }).then((resp) => {
    workList.loading = true;
    queryParams.pageIndex = resp.data.PageIndex;
    queryParams.PageSize = resp.data.PageSize;
    workList.total = resp.data.Total;
    workList.list = resp.data.DataList;
  });
};

const clickEvent = (item) => {
  if (item.CorrectionState == false) {
    Alert({
      title: use$t('individual.center.correcting'),
      content: '您当前正在作业批改中，请耐心等待批改',
    });
    return;
  }
  useToTabPage('/practiceResult', {
    Id: item.Id,
  });
};

onMounted(() => {
  getList();
});
</script>

<style lang="less" scoped>
.my_homework {
  .my_homework_header {
    padding: 10px 10px 20px 10px;
    border-bottom: 1px solid #e2e2e2;
    display: flex;

    p {
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #666666;
      line-height: 22px;
      text-align: left;
      font-style: normal;
      cursor: pointer;
      margin-left: 50px;

      &:first-child {
        margin-left: 0;
      }
    }

    .activated {
      color: #0678ff;
    }
  }

  .my_homework_body {
    display: grid;
    grid-template-columns: 266px 266px 266px;
    grid-gap: 22px;
    margin: 30px auto;

    .homework_item {
      width: 266px;
      height: 233px;
      background: #ffffff;
      box-shadow: 0 0 9px 0 rgba(1, 10, 29, 0.15);
      cursor: pointer;
      border-radius: 11px;

      :deep(.arco-image) {
        width: 100%;
      }

      :deep(.arco-image-img) {
        border-radius: 11px 11px 0 0;
        width: 100%;
        height: 147px;
      }

      .item_text {
        padding: 11px 9px;

        .item_text_title {
          margin: 0;
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 15px;
          color: #333333;
          line-height: 21px;
          text-shadow: 0 0 9px rgba(1, 10, 29, 0.15);
          font-style: normal;
        }

        .item_text_desc {
          display: flex;
          align-items: center;
          margin-top: 10px;

          .arco-typography:first-child {
            margin: 0;
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 13px;
            color: #666666;
            line-height: 18px;
            text-shadow: 0 0 9px rgba(1, 10, 29, 0.15);
            font-style: normal;
            flex: 2;
          }

          .arco-typography:last-child {
            margin: 0;
            width: 60px;
            height: 16px;
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 11px;
            color: #999999;
            line-height: 16px;
            text-shadow: 0 0 9px rgba(1, 10, 29, 0.15);
            font-style: normal;
          }
        }
      }

      .homework_item_mask {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 266px;
        height: 147px;
        bottom: 218px;
        position: relative;
        background: rgba(0, 0, 0, 0.58);
        box-shadow: 0 0 6px 0 rgba(1, 10, 29, 0.15);
        backdrop-filter: blur(0.933321456865564px);
        border-radius: 11px 11px 0 0;

        .check-circle_icon {
          width: 46px;
          height: 46px;
        }

        .time-circle_icon {
          width: 46px;
          height: 46px;
        }

        .mask_text {
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 18px;
          color: #ffffff;
          line-height: 25px;
          text-shadow: 0 0 6px rgba(1, 10, 29, 0.15);
          margin-top: 10px;
        }
      }
    }
  }

  &:lang(ar),
  &:lang(pk) {
    .my_homework_header {
      p {
        margin-left: 0;
        margin-right: 50px;
      }
    }
  }
}
</style>
