<template>
  <div class="recommended_course">
    <div class="recommended_course_header">
      <div class="recommended_course_title">{{ $t('home.recommended.course') }}</div>
      <a-button class="refresh_btn" @click="refreshCourse">
        <icon-refresh />
        {{ $t('home.change.course') }}
      </a-button>
    </div>
    <XyzTransition duration="auto" xyz="fade small">
      <div v-if="loading" class="recommended_course_body">
        <div v-for="(item, index) in recommendCourseList" :key="index" class="course_item pointer square xyz-nested" @click="useToTabPage('/courseDetail/' + item.Id)">
          <a-image :preview="false" :src="useUrlCv(item.CoverPicURL)" class="anime_zoom_lager"></a-image>
          <div class="course_item_f">
            <a-typography-text class="course_item_f_t" ellipsis>{{ item.Title }}</a-typography-text>
            <a-typography-text class="course_item_f_d" ellipsis>{{ item.Introduction }}</a-typography-text>
          </div>
        </div>
      </div>
    </XyzTransition>
    <empty v-if="recommendCourseList.length == 0" />
  </div>
</template>

<script setup>
import { useRecommendCourse } from '@/hooks/commomApi.js';
import { onMounted, reactive, ref } from 'vue';
import { useUrlCv } from '@/hooks/format.js';
import { hideLoadingProcess, showLoadingProcess, useToTabPage } from '@/hooks/page.js';
import request from '@/common/request.js';
import { useRoute } from 'vue-router';
import Empty from '@/components/empty/index.vue';

const recommendCourseList = reactive([]);
const loading = ref(false);
const route = useRoute();

/**
 * 换一批
 */
const refreshCourse = () => {
  loading.value = false;
  showLoadingProcess();
  useRecommendCourse(4, route.query.courseId).then((resp) => {
    recommendCourseList.length = 0;
    recommendCourseList.push(...resp.data);
    loading.value = true;
    hideLoadingProcess();
  });
  // showLoadingProcess();
  // loading.value = false;
  // request({
  //   url: '/Course/GetRandomCourseList',
  //   method: 'get',
  //   params: { categoryId: '', labelId: '' },
  // }).then((resp) => {
  //   hideLoadingProcess();
  //   loading.value = true;
  //   recommendCourseList.length = 0;
  //   recommendCourseList.push(...resp.data.DataList);
  // });
};
refreshCourse();
</script>

<style lang="less" scoped>
.recommended_course {
  width: 1200px;
  margin: auto;

  .recommended_course_header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .recommended_course_title {
      font-family: PingFang SC;
      margin-top: 40px;
      font-weight: 400;
      font-size: 26px;
      color: #333333;
      line-height: 37px;
      text-align: left;
      font-style: normal;
    }

    .refresh_btn {
      width: 120px;
      height: 40px;
      background: #ffffff;
      border-radius: 5px;
      border: 1px solid #d3d3d3;
      font-family: PingFang SC;
      margin-top: 40px;
      font-weight: 400;
      font-size: 15px;
      color: #666666;
      line-height: 21px;
      text-align: center;
      font-style: normal;
    }
  }

  .recommended_course_body {
    display: grid;
    grid-template-columns: 285px 285px 285px 285px;
    grid-gap: 20px;
    margin-top: 32px;

    .course_item {
      width: 285px;
      height: 250px;
      background: #ffffff;
      box-shadow:
        0 0 10px 0 rgba(1, 10, 29, 0.15),
        0 0 3px 0 rgba(142, 142, 142, 0.41);
      border-radius: 12px;
      filter: blur(0px);

      :deep(.arco-image-img) {
        width: 285px;
        height: 158px;
        border-radius: 12px 12px 0 0;
      }

      .course_item_f {
        padding: 12px 10px;

        .course_item_f_t {
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #333333;
          line-height: 22px;
          text-shadow: 0 0 10px rgba(1, 10, 29, 0.15);
          text-align: left;
          font-style: normal;
          margin: 0;
        }

        .course_item_f_d {
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #8d8d8d;
          line-height: 20px;
          text-shadow: 0 0 10px rgba(1, 10, 29, 0.15);
          text-align: left;
          font-style: normal;
          margin: 10px 0 0;
        }
      }
    }
  }
}
</style>
