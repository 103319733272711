<!--我们的优势-->
<template>
  <div class="advantage">
    <div class="advantage_view">
      <p class="advantage_title">{{ $t('home.ourAdvantage') }}</p>
      <div class="swiper_container">
        <swiper
          :autoplay="{ delay: 5000, pauseOnMouseEnter: true, disableOnInteraction: false }"
          :freeMode="false"
          :loop="true"
          :modules="modules"
          :navigation="true"
          :slidesPerView="3"
          class="swiper"
          pagination
        >
          <swiper-slide v-for="(item, index) in swiperList" :key="index" :lang="i18n.global.locale.value">
            <div class="card-front">
              <a-image :preview="false" :src="item.url" class="swiper_image" />
              <div class="card-front_text">
                <a-typography-text class="card-front_text_title" ellipsis>{{ $t(item.title) }}</a-typography-text>
                <a-typography-text class="card-front_text_desc" ellipsis>{{ $t(item.desc) }}</a-typography-text>
              </div>
            </div>
            <div :style="{ backgroundImage: `url(${bgVal(index)})` }" class="card-back">
              <div class="card-back_text">
                <p class="card-back_text_title">{{ $t(item.title01) }}</p>
                <p class="card-back_text_desc">{{ $t(item.desc01) }}</p>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div class="advantage_btn_group">
      <a-button class="advantage_btn btn-shine" @click="useToTabPage('/allCourse')">
        <SvgIcon class-name="live_course_icon" icon-class="live_course"></SvgIcon>
        <i class="animation"></i>
        {{ $t('home.view.now') }}
        <i class="animation"></i>
      </a-button>
    </div>
  </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { Autoplay, FreeMode, Navigation } from 'swiper/modules';
import { reactive } from 'vue';
import img01 from './images/01.png';
import img02 from './images/02.png';
import img03 from './images/03.png';
import img04 from './images/04.png';
import img05 from './images/05.png';
import svg01 from '@/assets/images/bg01.png';
import svg02 from '@/assets/images/bg02.png';
import svg03 from '@/assets/images/bg03.png';
import svg04 from '@/assets/images/bg04.png';
import svg05 from '@/assets/images/bg05.png';
import SvgIcon from '@/components/svgIcon/index.vue';
import { useToTabPage } from '@/hooks/page.js';
import i18n from '@/locales/index.js';

const modules = reactive([FreeMode, Autoplay, Navigation]);

const swiperList = reactive([
  {
    url: img01,
    title: 'swiperList.01.title',
    desc: 'swiperList.01.desc',
    title01: 'swiperList.01.title',
    desc01: 'swiperList.01.desc01',
  },
  {
    url: img02,
    title: 'swiperList.02.title',
    desc: 'swiperList.02.desc',
    title01: 'swiperList.02.title',
    desc01: 'swiperList.02.desc01',
  },
  {
    url: img03,
    title: 'swiperList.03.title',
    desc: 'swiperList.03.desc',
    title01: 'swiperList.03.title',
    desc01: 'swiperList.03.desc01',
  },
  {
    url: img04,
    title: 'swiperList.04.title',
    desc: 'swiperList.04.desc',
    title01: 'swiperList.04.title',
    desc01: 'swiperList.04.desc01',
  },
  {
    url: img05,
    title: 'swiperList.05.title',
    desc: 'swiperList.05.desc',
    title01: 'swiperList.05.title',
    desc01: 'swiperList.05.desc01',
  },
]);

const bgVal = (index) => {
  if (index === 0) {
    return svg01;
  } else if (index === 1) {
    return svg02;
  } else if (index === 2) {
    return svg03;
  } else if (index === 3) {
    return svg04;
  } else if (index === 4) {
    return svg05;
  }
};
</script>

<style lang="less" scoped>
.advantage {
  width: 1920px;
  padding-top: 60px;

  .advantage_view {
    width: 1350px;
    margin: auto;

    .advantage_title {
      height: 59px;
      font-family: PingFang SC;
      font-weight: 600;
      font-size: 42px;
      color: #333333;
      line-height: 59px;
      text-align: center;
    }

    .swiper_container {
      position: relative;
      padding: 0 75px;

      &:hover {
        :deep(.swiper-button-prev) {
          height: 42px;
          width: 42px;

          &:after {
            content: '';
            height: 42px;
            width: 42px;
            background-image: url('@/assets/images/left.svg');
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
        }

        :deep(.swiper-button-next) {
          height: 42px;
          width: 42px;

          &:after {
            content: '';
            height: 42px;
            width: 42px;
            background-image: url('@/assets/images/right.svg');
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }

    .swiper {
      width: 1200px;
      direction: ltr;
      padding: 10px 0;
      margin-top: 37px;
      --swiper-navigation-size: 0;
      position: initial;

      :deep(.swiper-button-prev) {
        &:after {
          content: '';
        }
      }

      :deep(.swiper-button-next) {
        &:after {
          content: '';
        }
      }

      .swiper-slide {
        width: 380px !important;
        padding: 0 10px;
        height: 380px !important;

        &:hover {
          .card-back {
            transform: rotateY(0deg);
          }

          .card-front {
            transform: rotateY(-180deg);
          }
        }
      }

      .card-front {
        height: 360px;
        margin: 10px 0;
        background-color: #ffffff;
        box-shadow: 0px 2px 10px 0px rgba(1, 10, 29, 0.15);
        backdrop-filter: blur(10.8888888888889px);
        border-radius: 10px;
        backface-visibility: hidden;
        transition: transform 0.6s;
        transform: rotateY(0deg);

        .swiper_image {
          :deep(.arco-image-img) {
            width: 100%;
            border-radius: 10px 10px 0 0;
          }
        }

        .card-front_text {
          margin-top: 27px;

          .card-front_text_title {
            font-family: PingFang SC;
            font-weight: 600;
            font-size: 22px;
            color: #333333;
            line-height: 30px;
            text-align: center;
            font-style: normal;
            margin: 12px;
          }

          .card-front_text_desc {
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #999999;
            line-height: 22px;
            text-align: center;
            font-style: normal;
            margin: 12px;
          }
        }
      }

      .card-back {
        height: 360px;
        position: absolute;
        margin: 0 10px;
        top: 10px;
        left: 0;
        background-color: #0678ff;
        background-repeat: no-repeat;
        background-size: 200px 200px;
        background-position: 100% 0;
        box-shadow: 0 0 10px 0 rgba(1, 10, 29, 0.15);
        border-radius: 10px;
        backface-visibility: hidden;
        transition: transform 0.8s;
        transform: rotateY(180deg);
        transform-style: preserve-3d;

        .card-back_course_icon {
          height: 200px;
          width: 200px;
          float: right;
        }

        .card-back_text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;
          padding: 0 30px;

          .card-back_text_title {
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 22px;
            color: #ffffff;
            line-height: 30px;
            text-align: left;
            font-style: normal;
            margin-bottom: 30px;
          }

          .card-back_text_desc {
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #ffffff;
            line-height: 22px;
            text-align: left;
            font-style: normal;
          }
        }
      }
    }
  }

  .advantage_btn_group {
    text-align: center;
    margin-top: 62px;

    .advantage_btn {
      min-width: 420px;
      height: 76px;
      border-radius: 49px;
      font-family: PingFang SC;
      background-color: #0678ff;
      font-weight: 400;
      font-size: 22px;
      color: #ffffff;
      line-height: 30px;
      text-align: center;
      font-style: normal;

      .live_course_icon {
        width: 40px;
        height: 40px;
      }
    }
  }
}
</style>
