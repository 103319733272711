<!--视频练习-->
<template>
  <a-modal
    v-model:visible="visible"
    :esc-to-close="false"
    :footer="false"
    :mask-closable="false"
    :title="use$t('player.course.exercise')"
    :unmount-on-close="true"
    modal-class="video_exercise"
    @cancel="handleCancel"
    @before-open="beforeOpen"
  >
    <div v-if="exerciseList.length != 0" class="body_view">
      <p>{{ currentIndex + 1 }}.【{{ getProblemType }}】</p>
      <p v-rich-text="exerciseList[currentIndex].Problem.Title"></p>
      <a-radio-group v-if="exerciseList[currentIndex].Problem.ProblemType == '1' || exerciseList[currentIndex].Problem.ProblemType == '2'" v-model="answerParams[currentIndex].userSelect">
        <a-radio v-for="(item, index) in exerciseList[currentIndex].AnswerList" :key="index" :value="item.OptionTag">
          <template #radio="{ checked }">
            <div :class="computedClass(checked, item)" class="select_item">
              <div>{{ item.OptionTag }}</div>
              <div class="select_line"></div>
              <div v-rich-text="item.Content"></div>
            </div>
          </template>
        </a-radio>
      </a-radio-group>

      <a-checkbox-group v-if="exerciseList[currentIndex].Problem.ProblemType == '3'" v-model="answerParams[currentIndex].userSelect">
        <a-checkbox v-for="(item, index) in exerciseList[currentIndex].AnswerList" :key="index" :value="item.OptionTag">
          <template #checkbox="{ checked }">
            <div :class="computedClass(checked, item)" class="select_item">
              <div class="option_text">{{ item.OptionTag }}</div>
              <div class="select_line"></div>
              <div v-rich-text="item.Content" class="option_content"></div>
            </div>
          </template>
        </a-checkbox>
      </a-checkbox-group>
      <a-button
        v-if="exerciseList[currentIndex].Problem.submit == false && exerciseList[currentIndex].Problem.ProblemType == '3' && answerParams[currentIndex].userSelect != ''"
        class="topicBtnSubmit"
        @click="exerciseList[currentIndex].Problem.submit = true"
      >
        {{ use$t('individual.center.submit') }}
      </a-button>
      <a-collapse
        v-if="(fmtUserSelect.length >= answerParams[currentIndex].correct.length && exerciseList[currentIndex].Problem.ProblemType != '3') || exerciseList[currentIndex].Problem.submit == true"
        expand-icon-position="right"
      >
        <a-collapse-item>
          <template #header>
            <p>{{ $t('exercise.reading.correct.answer') }}：{{ answerParams[currentIndex].correct }}</p>
            <p>
              {{ $t('exercise.reading.your.answer') }}：
              <span :style="{ color: fmtUserSelect == exerciseList[currentIndex].Problem.Answers ? '#56CBB1' : '#FA5E59' }">
                {{ Array.isArray(answerParams[currentIndex].userSelect) ? answerParams[currentIndex].userSelect.join('') : answerParams[currentIndex].userSelect }}
              </span>
            </p>
          </template>
          <template #expand-icon="{ active }">
            <a-button v-if="currentIndex + 1 != exerciseList.length" @click.stop="nextQuestion">
              {{ $t('exercise.reading.next.question') }}
            </a-button>
            <div v-if="active" class="expand_text">
              {{ $t('exercise.reading.pack.up') }}
              <icon-down />
            </div>
            <div v-else class="expand_text">
              {{ $t('exercise.reading.view.parsing') }}
              <icon-up />
            </div>
          </template>
          <p v-rich-text="exerciseList[currentIndex].Problem.Analysis"></p>
        </a-collapse-item>
      </a-collapse>
    </div>
    <div class="index_view">
      <div class="index-header">
        <div class="header_l">
          <div></div>
          <p>{{ use$t('practiceResult.answer.sheet') }}</p>
        </div>
        <div class="header_r">
          <div class="header_r_item">
            <p>{{ use$t('practiceResult.answer.unanswered') }}</p>
            <div></div>
          </div>
          <div class="header_r_item">
            <p>{{ use$t('practiceResult.answer.answered') }}</p>
            <div></div>
          </div>
        </div>
      </div>
      <div class="index_body_view">
        <div v-if="answerParams.length != 0" class="index_body">
          <div
            v-for="item in exerciseList.length"
            :key="item"
            :class="answerParams[item - 1].userSelect != '' ? 'index_activated' : ''"
            :style="{ borderColor: currentIndex == item - 1 ? '#05ca20' : '' }"
            class="index_item"
            @click="changeIndex(item)"
          >
            {{ item }}
          </div>
        </div>
      </div>

      <a-button @click="handleCancel">{{ use$t('practiceResult.skip.practice') }}</a-button>
    </div>
  </a-modal>
</template>

<script setup>
import { computed, ref } from 'vue';
import { use$t } from '@/hooks/language.js';
import request from '@/common/request.js';
import { useRoute } from 'vue-router';

const route = useRoute();

const $props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  second: [String, Number],
  videoId: String,
});

const $emits = defineEmits(['update:visible', 'handleCancel']);

const visible = computed({
  get: () => {
    return $props.visible;
  },
  set: (val) => {
    $emits('update:visible', val);
  },
});

const exerciseList = ref([]);
const currentIndex = ref(0);
const answerParams = ref([]);

const fmtUserSelect = computed(() => {
  if (Array.isArray(answerParams.value[currentIndex.value].userSelect)) {
    return answerParams.value[currentIndex.value].userSelect.sort().join('');
  }
  return answerParams.value[currentIndex.value].userSelect;
});

const handleCancel = () => {
  visible.value = false;
  $emits('handleCancel');
};

const computedClass = (checked, item) => {
  const problem = exerciseList.value[currentIndex.value].Problem;
  const problemType = problem.ProblemType;
  if ((fmtUserSelect.value.length >= exerciseList.value[currentIndex.value].Problem.Answers.length && problemType != 3) || (problem.submit === true && problemType == 3)) {
    if (exerciseList.value[currentIndex.value].Problem.Answers.split('').includes(item.OptionTag)) {
      return 'select_item-checked';
    }
    if (!exerciseList.value[currentIndex.value].Problem.Answers.split('').includes(item.OptionTag) && checked) {
      return 'select_item-error';
    }
  }
};

/**
 * 判断 = 1,//判断
 * 单选  = 2,//单选
 * 多选 = 3,//多选
 * 主观 = 4,//主观
 * 阅读理解=10,//阅读理解
 * @type {ComputedRef<unknown>}
 */
const getProblemType = computed(() => {
  if (exerciseList.value.length == 0) {
    return;
  }
  if (exerciseList.value[currentIndex.value].Problem.ProblemType == 1) {
    return use$t('exercise.true.false questions');
  } else if (exerciseList.value[currentIndex.value].Problem.ProblemType == 2) {
    return use$t('exercise.Multiple-choice.question');
  } else if (exerciseList.value[currentIndex.value].Problem.ProblemType == 3) {
    return use$t('exercise.Multiple.choice.question');
  } else if (exerciseList.value[currentIndex.value].Problem.ProblemType == 4) {
    return use$t('exercise.subjective.questions');
  } else if (exerciseList.value[currentIndex.value].Problem.ProblemType == 10) {
    return use$t('exercise.reading.comprehension.questions');
  }
  return '';
});

const changeIndex = (index) => {
  currentIndex.value = index - 1;
};

/**
 * 下一题
 */
const nextQuestion = () => {
  currentIndex.value = currentIndex.value + 1;
};

const beforeOpen = () => {
  exerciseList.value = [];
  answerParams.value = [];
  currentIndex.value = 0;
  request({
    url: '/Video/GetPopProblem',
    method: 'get',
    params: {
      sign: route.query.sign,
      videoId: $props.videoId,
      second: $props.second,
      lang: 'zh',
    },
  }).then((resp) => {
    exerciseList.value = resp.data;
    exerciseList.value.forEach((item) => {
      item.Problem['submit'] = false;
    });
    console.log(exerciseList.value);
    exerciseList.value.forEach((item) => {
      answerParams.value.push({
        problemId: item.Problem.Id,
        userSelect: '',
        correct: item.Problem.Answers,
      });
    });
  });
};
</script>

<style lang="less">
.video_exercise {
  width: 1160px;
  height: 850px;
  background-color: #f4f6f9;

  .topicBtnSubmit {
    width: 220px;
    height: 36px;
    background: #1882ff;
    border-radius: 21px;
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    line-height: 22px;
    text-align: center;
    font-style: normal;
    margin-top: 20px;
  }

  .arco-modal-header {
    background-color: white;
    border-radius: var(--border-radius-medium) var(--border-radius-medium) 0 0;
  }

  .arco-modal-body {
    padding: 30px;
    max-height: 860px;
    overflow-y: auto;
    display: flex;
    justify-content: space-between;
    text-align: center;

    .body_view {
      width: calc(780px - 22px - 60px);
      background-color: white;
      padding: 16px 22px 60px;

      p {
        font-family: PingFang SC;
        color: #333333;
        text-align: left;
        font-style: normal;

        &:first-child {
          font-weight: 600;
          font-size: 18px;
          line-height: 25px;
          letter-spacing: 1px;
        }

        &:nth-child(2) {
          margin-top: 20px;
          font-weight: 500;
          font-size: 16px;
          display: flex;
          align-items: center;
          line-height: 22px;

          p {
            display: flex;
            align-items: center;
          }
        }
      }

      .arco-collapse {
        width: 100%;
        margin-top: 20px;

        .arco-collapse-item-header {
          width: 700px;
          height: 56px;
          background: #f4f5fb;
          border-radius: 6px 6px 0 0;

          .arco-collapse-item-icon-right {
            display: flex;
            align-items: center;

            .arco-btn {
              margin-right: 30px;
              width: 100px;
              height: 36px;
              background: #ffffff;
              border-radius: 6px;
              border: 1px solid #0678ff;
              font-family: PingFang SC;
              font-weight: 500;
              font-size: 16px;
              color: #1882ff;
              line-height: 22px;
              text-align: left;
              font-style: normal;
            }
          }

          .arco-collapse-item-header-title {
            display: flex;
            align-items: center;

            p {
              display: flex;
              font-family: PingFang SC;
              font-weight: 500;
              font-size: 16px;
              color: #333333;
              line-height: 22px;
              text-align: left;
              font-style: normal;
              margin: 0;

              &:last-child {
                margin-left: 50px;
              }
            }
          }

          .expand_text {
            font-family: PingFang SC;
            font-weight: 500;
            width: 75px;
            font-size: 14px;
            color: #1882ff;
            line-height: 20px;
            text-align: right;
            font-style: normal;
          }
        }

        .arco-collapse-item-content {
          background-color: #ffffff;
          min-height: 200px;
          max-height: 220px;
          overflow: auto;

          &-box {
            padding: 15px 2px;

            p {
              font-family: PingFang SC;
              font-weight: 500;
              font-size: 16px;
              color: #333333;
              line-height: 22px;
              text-align: left;
              font-style: normal;

              &:last-child {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
              }
            }
          }
        }
      }
    }

    .index_view {
      width: 302px;
      height: 460px;
      background-color: white;
      padding: 14px 20px;

      .index-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .header_l {
          align-items: center;
          display: flex;

          div {
            width: 3px;
            height: 10px;
            background: #0678ff;
            margin-right: 7px;
          }

          p {
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #333333;
            line-height: 20px;
            text-align: left;
            font-style: normal;
          }
        }

        .header_r {
          display: flex;

          .header_r_item {
            display: flex;
            align-items: center;

            p {
              font-family: PingFang SC;
              font-weight: 400;
              font-size: 12px;
              color: #575d6c;
              line-height: 17px;
              text-align: left;
              font-style: normal;
            }

            div {
              width: 12px;
              height: 12px;
              background: #ffffff;
              border-radius: 2px;
              border: 1px solid #cdcdcd;
              margin-left: 4px;
            }

            &:last-child {
              margin-left: 10px;

              div {
                background: #1882ff;
                border: 1px solid #1882ff;
              }
            }
          }
        }
      }

      .index_body_view {
        height: 300px;
        overflow-y: auto;
        border-bottom: 1px solid #e0e0e0;

        .index_body {
          display: grid;
          grid-template-columns: 36px 36px 36px 36px 36px;
          grid-row-gap: 14px;
          grid-column-gap: 14px;
          margin-top: 25px;
          padding: 0 25px;

          .index_item {
            cursor: pointer;
            width: 36px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #ffffff;
            border-radius: 4px;
            border: 2px solid #cdcdcd;
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
            line-height: 20px;
            font-style: normal;
          }

          .index_activated {
            background: #1882ff;
            border: 1px solid #1882ff;
            color: #ffffff;
          }
        }
      }

      .arco-btn {
        width: 220px;
        height: 36px;
        background: #1882ff;
        border-radius: 21px;
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        line-height: 22px;
        text-align: center;
        font-style: normal;
        margin-top: 80px;
      }
    }
  }

  .arco-radio-group,
  .arco-checkbox-group {
    width: 100%;

    .arco-radio,
    .arco-checkbox {
      width: calc(100% - 44px);
      background: #f4f5fb;
      border-radius: 6px;
      padding: 17px 22px;
      margin-top: 12px;

      &:first-child {
        margin-top: 16px;
      }
    }

    .arco-radio-checked,
    .arco-checkbox-checked {
      background-color: #ecf4ff;

      &:has(.select_item-error) {
        background-color: #fae4e4;
      }
    }

    .select_item {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .option_text {
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #333333;
        line-height: 22px;
        text-align: left;
        font-style: normal;
      }

      .select_line {
        width: 1px;
        height: 20px;
        background: #333333;
        margin-left: 15px;
        margin-right: 15px;
      }

      .option_content {
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #333333;
        line-height: 22px;
        text-align: left;
        font-style: normal;
      }
    }

    .select_item-checked {
      div:first-child {
        color: #56cbb1 !important;
      }

      div:nth-child(2) {
        background-color: #56cbb1 !important;
      }

      div:last-child {
        color: #56cbb1 !important;

        p,
        div,
        h1,
        h2,
        h3 {
          color: #56cbb1;
        }
      }
    }

    .select_item-error {
      div:first-child {
        color: #fa5e59 !important;
      }

      .select_line {
        background-color: #fa5e59 !important;
      }

      div:last-child {
        color: #fa5e59 !important;

        p,
        div,
        h1,
        h2,
        h3 {
          color: #fa5e59;
        }
      }
    }
  }
}
</style>
