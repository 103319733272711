<!--直播课时间轴-->
<template>
  <div class="w1200 live_container">
    <p class="live_title">{{ $t('live.message.01') }}</p>

    <div class="live_timeline">
      <a-timeline>
        <a-timeline-item v-for="(item, index) in tableList" :key="index" line-color="#2978FF">
          <div class="timeBtn">
            {{ $t('live.message.03') }}：{{ dateStr(item.TimeRange) }}
            <span class="timeBtnText">({{ dateTimeStr(item.TimeRange) }})</span>
          </div>
          <template #label>
            <a-card v-for="(live, $index) in item.Items" :key="$index" :style="{ width: '100%', borderRadius: '13px', borderColor: '#DFE3EB' }" hoverable>
              <div class="arco-row arco-row-justify-space-between arco-row-nowrap arco-row-align-center">
                <div class="arco-row arco-row-nowrap">
                  <div class="card_image anime_zoom_lager">
                    <a-image :preview="false" :src="useUrlCv(live.PicUrl)"></a-image>
                  </div>
                  <div class="arco-col liveView">
                    <a-typography-text :ellipsis="{ rows: 2 }" class="liveHeader">
                      {{ live.Title }}
                    </a-typography-text>
                    <div class="liveText">
                      {{ $t('live.message.04') }}：
                      <span class="live_t">{{ live.LiveTeacher }}</span>
                    </div>
                  </div>
                </div>
                <a-button class="viewDetail" type="primary" @click="toDetail(live)">{{ $t('culture.center.btn') }}</a-button>
              </div>
            </a-card>
          </template>
          <template #dot>
            <svg-icon class-name="dot_icon" icon-class="dot"></svg-icon>
          </template>
        </a-timeline-item>
      </a-timeline>
    </div>
    <template v-if="!loading && tableList.length === 0">
      <svg-icon class-name="emptyLive_icon" icon-class="emptyLive" />
      <p class="emptyText">{{ $t('live.message.12') }}</p>
    </template>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue';
import { useCommonCacheStore } from '@/stores/commonCache.js';
import { hideLoadingProcess, showLoadingProcess, useToCurrentPage, useToTabPage } from '@/hooks/page.js';
import SvgIcon from '@/components/svgIcon/index.vue';
import request from '@/common/request.js';
import moment from 'moment';
import { useUrlCv } from '@/hooks/format.js';

const commonCache = useCommonCacheStore();

const tableList = reactive([]);
const loading = ref(true);
onMounted(() => {
  commonCache.setHeaderWhiteTheme();
  showLoadingProcess();
  tableList.length = 0;
  loading.value = true;
  request({
    url: '/Live/GetLiveList',
    method: 'GET',
  })
    .then((resp) => {
      console.log(resp);
      tableList.push(...resp.data);
      console.log(tableList);
    })
    .finally(() => {
      hideLoadingProcess();
      loading.value = false;
    });
});

const toDetail = (live) => {
  useToCurrentPage('/live/detail/' + live.Id);
};

const dateStr = (TimeRange) => {
  return moment(TimeRange.Item1).format('MM/DD');
};

const dateTimeStr = (TimeRange) => {
  return moment(TimeRange.Item1).format('HH:mm') + '-' + moment(TimeRange.Item2).format('HH:mm');
};
</script>

<style lang="less" scoped>
.live_container {
  margin: 88px auto auto;
  display: flex;
  flex-direction: column;

  .live_title {
    font-family: PingFang SC;
    text-align: center;
    font-weight: 600;
    font-size: 42px;
    color: #333333;
    line-height: 59px;
    font-style: normal;
    margin-top: 60px;
  }

  .live_timeline {
    margin-top: 30px;

    .timeBtn {
      display: inline-block;
      background: #0772fe;
      height: 58px;
      line-height: 58px;
      border-radius: 6px;
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 24px;
      color: #ffffff;
      text-align: center;
      padding: 0 18px;
    }

    .timeBtnText {
      font-weight: 300;
      font-size: 21px;
    }

    .card_image {
      height: 170px;
      width: 310px;

      :deep(.arco-image-img) {
        height: 170px;
        width: 310px;
        border-radius: 8px;
      }
    }

    .liveView {
      justify-content: center;
      padding: 25px;

      .liveHeader {
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #333333;
        line-height: 30px;
      }

      .liveText {
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 15px;
        color: #8d8d8d;
        line-height: 21px;

        .live_t {
          color: #3e3f5e;
        }
      }
    }

    .viewDetail {
      height: 44px;
      min-width: 148px;
      background: #0678ff;
      border-radius: 4px;
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #ffffff;
      text-align: center;
    }
  }

  :deep(.arco-card) {
    margin: 30px 0;
  }

  .dot_icon {
    width: 25px;
    height: 25px;
  }

  .emptyLive_icon {
    margin: 0 auto;
    width: 500px;
    height: 500px;
  }

  .emptyText {
    font-family: PingFang SC;
    text-align: center;
    font-size: 26px;
    color: #999999;
  }
}

:deep(.arco-timeline-item-dot-line) {
  display: block !important;
}
</style>
