<template>
  <div class="origin_successView">
    <svg-icon class-name="origin_success_icon" icon-class="success"></svg-icon>

    <p class="success_text">支付成功</p>
    <p class="success_desc">您已成功购买汉语课程，快来体验吧~</p>
    <a-button type="primary" @click="window.close()">已完成支付</a-button>
  </div>

  <div class="w1200" style="font-size: 14px; margin: 10px auto">
    <p>orderId:{{ orderId }}</p>
    <br />
    <p>token:{{ token }}</p>
    <br />
    <p>PayerID:{{ PayerID }}</p>
  </div>
</template>
<script setup>
import { getUrlParams } from '@/hooks/utils.js';
import { onMounted, ref } from 'vue';
import SvgIcon from '@/components/svgIcon/index.vue';

const orderId = ref('');
const token = ref('');
const PayerID = ref('');

onMounted(() => {
  orderId.value = getUrlParams('orderId');
  token.value = getUrlParams('token');
  PayerID.value = getUrlParams('PayerID');
  console.log(getUrlParams('orderId'));
  console.log(getUrlParams('token'));
  console.log(getUrlParams('PayerID'));
});
</script>
<style lang="less" scoped>
@media screen and (max-width: 1200px) {
  .origin_successView {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;

    .origin_success_icon {
      font-size: 300px !important;
      margin: 0 auto;
    }

    .success_text {
      font-family: PingFang SC;
      font-size: 36px;
      font-weight: 500;
    }

    .success_desc {
      margin-top: 30px;
      font-family: PingFang SC;
      font-size: 20px;
      font-weight: 400;
    }

    .arco-btn {
      margin-top: 72px;
      width: 200px;
      height: 45px;
      font-family: PingFang SC;
      background: #0678ff;
      font-size: 16px;
      border-radius: 5px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .origin_successView {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .origin_success_icon {
      font-size: 300px !important;
      margin: 0 auto;
    }

    .success_text {
      font-family: PingFang SC;
      font-size: 36px;
      font-weight: 500;
    }

    .success_desc {
      margin-top: 30px;
      font-family: PingFang SC;
      font-size: 20px;
      font-weight: 400;
    }

    .arco-btn {
      margin-top: 72px;
      width: 200px;
      height: 45px;
      font-family: PingFang SC;
      background: #0678ff;
      font-size: 16px;
      border-radius: 5px;
    }
  }
}
</style>
