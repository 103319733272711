<!--更多套餐-->
<template>
  <div class="setMealList">
    <div class="article_header">
      <div class="w1200 article_view_header">
        <a-breadcrumb>
          <template #separator>
            <icon-double-right />
          </template>
          <a-breadcrumb-item class="pointer" @click="useToHome">{{ $t('header.menu.homePage') }}</a-breadcrumb-item>
          <a-breadcrumb-item>{{ $t('setMeal.message.01') }}</a-breadcrumb-item>
        </a-breadcrumb>
      </div>
    </div>
    <div class="setMealBody">
      <div class="search_info">
        <p>{{ $t('setMeal.message.09') }}</p>
        <p v-dompurify-html="use$t('all.course.find', { value: tableList.total })"></p>
      </div>
      <div class="pageView">
        <div v-for="(item, index) in tableList.list" :key="index" class="cardItem pointer" @click="useToCurrentPage('/setMeal/detail/' + item.Id)">
          <div class="itemFlag">{{ $t('setMeal.message.02') }}</div>
          <a-image :preview="false" :src="useUrlCv(item.CoverPicURL)"></a-image>
          <div class="item_l">
            <a-typography-text :ellipsis="{ rows: 2 }" class="itemTitle">{{ item.Title }}</a-typography-text>
            <a-typography-text :ellipsis="{ rows: 2 }" class="itemSubTitle">{{ item.Introduction }}</a-typography-text>
          </div>
        </div>
      </div>
      <a-pagination v-model:current="queryParams.pageIndex" v-model:page-size="queryParams.pageSize" :total="tableList.total" @change="changePage" />
    </div>
  </div>
</template>
<script setup>
import { hideLoadingProcess, showLoadingProcess, useToCurrentPage, useToHome } from '@/hooks/page.js';
import { onMounted, reactive, ref } from 'vue';
import { useCommonCacheStore } from '@/stores/commonCache.js';
import { use$t } from '@/hooks/language.js';
import $ from 'jquery';
import request from '@/common/request.js';
import { useUrlCv } from '@/hooks/format.js';

const commonCache = useCommonCacheStore();

const queryParams = reactive({
  pageIndex: 1,
  pageSize: 10,
});

const loading = ref(true);

const tableList = reactive({
  list: [],
  total: 0,
});

onMounted(() => {
  commonCache.setHeaderWhiteTheme();
  getList();
});

const getList = () => {
  showLoadingProcess();
  loading.value = true;
  request({
    url: '/Package/GetPackageList',
    method: 'get',
    params: queryParams,
  })
    .then((resp) => {
      tableList.list = resp.data.DataList;
      tableList.total = resp.data.Total;
      queryParams.pageIndex = resp.data.PageIndex;
      queryParams.pageSize = resp.data.PageSize;
    })
    .finally(() => {
      hideLoadingProcess();
      loading.value = false;
    });
};

const changePage = () => {
  $('html, body').scrollTop(0);
  getList();
};
</script>

<style lang="less" scoped>
.setMealList {
  width: 1920px;
  display: inline-block;
  min-height: 80vh;
  margin-top: 88px;
  padding-bottom: 149px;

  .article_header {
    .article_view_header {
      margin: 30px auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .setMealBody {
    margin: 0 auto;
    width: 1200px;

    .search_info {
      display: flex;
      justify-content: space-between;

      p:first-child {
        font-family: PingFang SC;
        font-weight: 600;
        font-size: 20px;
        color: #333333;
        line-height: 28px;
        text-align: left;
        font-style: normal;
      }

      p:last-child {
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 20px;
        color: #575d6c;
        line-height: 28px;
        text-align: left;
        font-style: normal;

        :deep(span) {
          color: black;
          font-weight: bold;
          margin: 0 5px;
        }
      }
    }

    .pageView {
      display: grid;
      grid-template-columns: 590px 590px;
      margin-top: 30px;
      grid-gap: 20px;

      .cardItem {
        width: 590px;
        height: 156px;
        background: #f4f9fc;
        border-radius: 12px;
        filter: blur(0px);
        display: flex;

        .itemFlag {
          min-width: 50px;
          height: 25px;
          background: #ff7330;
          border-radius: 8px 0 8px 0;
          position: absolute;
          z-index: 1;
          opacity: 0.8;

          font-family: PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          line-height: 25px;
          text-align: center;
        }

        :deep(.arco-image-img) {
          width: 281px;
          height: 156px;
          border-radius: 12px;
        }

        .item_l {
          margin: 31px 20px 0 20px;

          .itemTitle {
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #333333;
            line-height: 22px;
          }

          .itemSubTitle {
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #999999;
            line-height: 20px;
          }
        }
      }
    }
  }

  :deep(.arco-pagination) {
    justify-content: center;
    margin-top: 70px;
  }
}
</style>
