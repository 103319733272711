<!--课程播放页-->
<template>
  <div class="course_player">
    <div class="course_player_header_view">
      <div class="course_header">
        <div class="w1200 course_view_header">
          <a-breadcrumb>
            <template #separator>
              <icon-double-right />
            </template>
            <a-breadcrumb-item class="pointer" @click="useToTabPage('/allCourse')">{{ $t('common.all.course') }}</a-breadcrumb-item>
            <a-breadcrumb-item>{{ categoryName }}</a-breadcrumb-item>
          </a-breadcrumb>
        </div>
      </div>
      <div class="w1200 course_player_video">
        <CoursePlayerCp ref="coursePlayerRef" :courseDetail="courseDetail" />
        <div class="course_player_view">
          <div :lang="i18n.locale.value" class="course_player_title">
            <a-typography-text class="course_title" ellipsis>{{ courseDetail.Title }}</a-typography-text>
            <p>{{ $t('home.learned.count', { value: courseDetail.StudyNum }) }}</p>
          </div>
          <a-button @click="practiceEvent">
            <SvgIcon :lang="i18n.locale.value" class-name="edit_icon" icon-class="edit" />
            {{ $t('common.practice.after.class') }}
          </a-button>
        </div>
      </div>
    </div>
    <div class="cut_off"></div>

    <LearningMaterial :FeeType="courseDetail.FeeType" />

    <RecommendedCourse />
  </div>
</template>

<script setup>
import CoursePlayerCp from '@/views/coursePlayer/components/CoursePlayerCp.vue';
import SvgIcon from '@/components/svgIcon/index.vue';
import LearningMaterial from '@/views/coursePlayer/components/LearningMaterial.vue';
import RecommendedCourse from '@/views/coursePlayer/components/RecommendedCourse.vue';
import { reactive, ref } from 'vue';
import { useRefreshPage, useToTabPage } from '@/hooks/page.js';
import request from '@/common/request.js';
import { useRoute } from 'vue-router';
import { useCheckBuyCourse, useCheckIsLogin, useCourseCategoryApi } from '@/hooks/commomApi.js';
import Payment from '@/components/payment/index.js';
import { Notification } from '@arco-design/web-vue';
import { use$t } from '@/hooks/language.js';
import { useI18n } from 'vue-i18n';
import activationSuccessful from '@/components/activationSuccessful/index.js';

const route = useRoute();
const i18n = useI18n();

const coursePlayerRef = ref();
const courseDetail = reactive({
  Id: '',
  CategoryId: '',
  Title: '',
  StudyNum: '',
  InterPrice: 0,
  Count: 0,
  CoverPicURL: '',
  FeeType: '',
  PriceList: [],
});

const categoryName = ref('');
//课程详情
request({
  url: '/Course/GetCourseDetails',
  method: 'get',
  params: {
    Id: route.query.courseId,
  },
}).then((resp) => {
  courseDetail.Id = resp.data.Id;
  courseDetail.CategoryId = resp.data.CategoryId;
  courseDetail.Title = resp.data.Title;
  courseDetail.Free = resp.data.Title;
  courseDetail.StudyNum = resp.data.StudyNum;
  courseDetail.InterPrice = resp.data.InterPrice;
  courseDetail.Count = resp.data.Count;
  courseDetail.FeeType = resp.data.FeeType;
  courseDetail.CoverPicURL = resp.data.CoverPicURL;
  courseDetail.Introduction = resp.data.Introduction;
  courseDetail.PriceList = resp.data.PriceList;
  courseDetail.PriceList.sort(function (a, b) {
    if (a.Price > b.Price) return 1;
    if (a.Price < b.Price) return -1;
    return 0;
  });
  //查询分类
  useCourseCategoryApi().then((response) => {
    const findObj = response.data.find((item) => {
      return item.Id === courseDetail.CategoryId;
    });
    categoryName.value = findObj.ContentName;
  });
});

/**
 * 课后练习
 */
const practiceEvent = () => {
  const newUserTrialTotal = coursePlayerRef.value.newUserTrialTotal();
  const trialTime = coursePlayerRef.value.TrialTime();
  useCheckIsLogin(() => {
    if (trialTime != 0 && newUserTrialTotal != 0) {
      jumpHomeWork();
      return;
    }
    useCheckBuyCourse(route.query.courseId, courseDetail.FeeType).then((res) => {
      if (res.data) {
        jumpHomeWork();
      } else {
        //展示立即购买弹框
        Payment(courseDetail, () => {
          activationSuccessful(() => {
            useRefreshPage();
          });
        });
      }
    });
  });

  /**
   * 跳转课后练习
   */
  function jumpHomeWork() {
    //判断是否有题目
    request({
      url: '/Video/GetAfterCourse',
      method: 'get',
      params: {
        sign: route.query.sign,
        videoId: coursePlayerRef.value.videoId(),
        courseId: route.query.courseId,
        lang: 'zh',
      },
    }).then((resp) => {
      if (resp.data == null) {
        Notification.warning({
          content: use$t('common.practice.no.after.class'),
        });
        return;
      }
      useToTabPage('/homework', {
        sign: route.query.sign,
        videoId: coursePlayerRef.value.videoId(),
        courseId: route.query.courseId,
      });
    });
  }
};
</script>

<style lang="less" scoped>
.course_player {
  width: 100vw;
  display: inline-block;
  min-height: 80vh;
  margin-top: 88px;
  background-color: #ffffff;
  padding-bottom: 149px;

  .course_player_header_view {
    width: 100vw;
    background-color: #ffffff;

    .course_header {
      background-color: #ffffff;

      .course_view_header {
        margin: 30px auto auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .course_player_video {
      margin: auto;

      .course_player_view {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 43px;
        margin-bottom: 60px;

        .course_player_title {
          flex: 1;
          margin-right: 50px;

          .course_title {
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 26px;
            color: #333333;
            line-height: 37px;
            font-style: normal;
            margin: 0;
          }

          p {
            margin-top: 10px;
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #575d6c;
            line-height: 22px;
            font-style: normal;
          }

          &:lang(ar),
          &:lang(pk) {
            margin-right: 0;
          }
        }

        .arco-btn {
          width: 336px;
          height: 62px;
          background: #0678ff;
          border-radius: 40px;
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 22px;
          color: #ffffff;
          line-height: 30px;
          text-align: left;
          font-style: normal;

          .edit_icon {
            width: 20px;
            height: 20px;
            margin-right: 13px;

            &:lang(ar),
            &:lang(pk) {
              margin-right: 0;
              margin-left: 13px;
            }
          }
        }
      }
    }
  }

  .cut_off {
    background-color: #f8f8f8;
    height: 30px;
  }

  .learning_material {
    width: 1200px;
  }
}
</style>
