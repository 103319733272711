<template>
  <div :lang="i18n.locale.value" class="course_player_component">
    <div class="player_view">
      <div class="player_video">
        <video id="videoPlayer_CoursePlayerCp" ref="playerRef" class="video-js" controlslist="nodownload noremoteplayback">
          <p class="vjs-no-js">
            To view this video please enable JavaScript, and consider upgrading to a web browser that
            <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
          </p>
        </video>
      </div>
      <div class="player_footer">
        <a-button @click="collectEvent">
          <SvgIcon :icon-class="isCollection ? 'favor_fill' : 'favor'" class-name="favor_icon" />
          {{ isCollection ? $t('detail.course.uncollect') : $t('player.course.collect') }}
        </a-button>
        <a-button @click="downloadAttachment">
          <SvgIcon class-name="pull_down_icon" icon-class="pull_down" />
          {{ $t('player.course.download.attachment') }}
        </a-button>
      </div>
    </div>
    <div class="course_info">
      <a-tabs default-active-key="1">
        <a-tab-pane key="1" :title="$t('player.course.catalogue') + '（' + chapterList.length + '）'">
          <div v-for="(item, index) in chapterList" :key="index" class="catalogue_item">
            <a-typography-text class="chapter_name" ellipsis>{{ item.Title }}</a-typography-text>
            <template v-if="item.hasOwnProperty('videoList')">
              <div v-for="(video, videoIndex) in item.videoList" :id="video.Id" :key="videoIndex" class="chapter_course_item" @click="changeVideoEvent(video)">
                <div class="chapter_course_item_header">
                  <SvgIcon class-name="play-circle_icon" icon-class="play-circle" />
                  <a-typography-text :style="{ color: playerVideo.Id == video.Id ? '#16E089' : '' }" class="chapter_item_title" ellipsis>{{ video.Title }}</a-typography-text>
                  <a-image v-show="playerVideo.Id === video.Id && playerVideo.setMealQuota !== -1" :src="playGif"></a-image>
                  <icon-lock v-if="setMealCourse(video)" :size="20" />
                </div>
                <div :style="{ color: playerVideo.Id == video.Id ? '#16E089' : '' }" class="chapter_course_item_time">
                  {{ useSecondsToMinutes(video.TotalTime) }}
                  <icon-check-circle v-if="playNameText(video)" size="16" />
                </div>
              </div>
            </template>
          </div>
        </a-tab-pane>
        <!--        <a-tab-pane key="2" title="课程信息">Content of Tab Panel 2</a-tab-pane>-->
      </a-tabs>
    </div>
  </div>
  <VideoExercise v-model:visible="visible" :second="currentTime" :video-id="playerVideo.Id" @handleCancel="exerciseCloseEvent" />
  <Attachments v-model:visible="attachmentsVisible" :attach-path="playerVideo.AttachPath" />
</template>

<script setup>
import SvgIcon from '@/components/svgIcon/index.vue';
import { createApp, nextTick, onMounted, reactive, ref, watch, watchEffect } from 'vue';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import '@/components/video/components/stopButton.js';
import '@/components/video/components/previousButton.js';
import '@/components/video/components/nextButton.js';
import '@/components/video/components/timeDisplay.js';
import VideoExercise from '@/views/coursePlayer/components/VideoExercise.vue';
import { useSecondsToMinutes, useUrlCv } from '@/hooks/format.js';
import { useRoute } from 'vue-router';
import request from '@/common/request.js';
import Attachments from '@/views/coursePlayer/components/Attachments.vue';
import { useCheckBuyCourse, useCheckIsCollect, useCheckIsLogin } from '@/hooks/commomApi.js';
import { useIsMobileBrowser, useRefreshPage, useToCurrentPage, useVideoFullscreen } from '@/hooks/page.js';
import playGif from '@/assets/images/player.gif';
import videoModal from '@/components/videoModal/index.vue';
import { useUpdateVideoSource } from '@/hooks/utils.js';
import { use$t } from '@/hooks/language.js';
import BusinessContextEvent from '@/common/BusinessContextEvent.js';
import zh from 'video.js/dist/lang/zh-CN.json';
import en from 'video.js/dist/lang/en.json';
import Audition from '@/components/audition/index.js';
import BuyNow from '@/components/buyNow/index.js';
import Confirm from '@/components/common/Confirm/index.js';
import { useI18n } from 'vue-i18n';
import Payment from '@/components/payment/index.js';
import activationSuccessful from '@/components/activationSuccessful/index.js';
import Alert from '@/components/common/Alert/index.js';
import { useLocalCacheStore } from '@/stores/localCache.js';

const $props = defineProps({
  courseDetail: Object,
});
const route = useRoute();
const playerRef = ref();
const player = ref();
const modal = ref();
const isCollection = ref(false);
const i18n = useI18n();
const localCache = useLocalCacheStore();
const setMealByCourse = reactive({
  List: [],
  Total: 0,
});

const visible = ref(false);
const attachmentsVisible = ref(false);
const chapterList = reactive([]);
const alreadyOwned = ref(false);
const currentTime = ref('');
const preTime = ref('');
const preFullscreen = ref(false);
const playerVideo = reactive({
  Id: '',
  ChapterId: '',
  VideoURL: '',
  AttachPath: '',
  Title: '',
  TrialTime: 0,
  NewUserTrialTotal: '0',
  finish: false,
  videoExercises: [],
  setMealQuota: 0,
});

/**
 * 下载附件
 */
const downloadAttachment = () => {
  useCheckIsLogin(() => {
    attachmentsVisible.value = true;
  });
};

const playNameText = (video) => {
  const id = localCache.userinfo.Id;
  if (id) {
    console.log(video);
    let item = localStorage.getItem(id + video.Id);
    if (item === true || item === 'true') {
      return true;
    }
  }
  return false;
};

const setMealCourse = (video) => {
  if (video.NewUserTrialTotal === '1') {
    //试播
    return false;
  }
  if (setMealCourse.Total === 0) {
    return false;
  }
  if (setMealByCourse.Total === setMealByCourse.List.length) {
    let filter = setMealByCourse.List.filter((item) => {
      return item.VideoId === video.Id;
    });
    if (filter.length !== 0) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};
//获取该课程套餐信息
request({
  url: '/Video/GetLearnedVideoList',
  method: 'get',
  params: {
    courseId: route.query.courseId,
  },
}).then((resp) => {
  console.log(resp.data.List);
  setMealByCourse.List = resp.data.List || [];
  setMealByCourse.Total = resp.data.Total;
});
//目录和视频课程
//获取所有章节
request({
  url: '/Chapter/GetChapterListByCourseId',
  method: 'get',
  params: {
    sign: route.query.sign,
  },
}).then((resp) => {
  chapterList.length = 0;
  chapterList.push(...resp.data);
  chapterList.forEach((item, index) => {
    request({
      url: '/Video/GetVideoListByChapterId',
      method: 'get',
      params: {
        sign: route.query.sign,
        chapterId: item.Id,
      },
    }).then((response) => {
      item['videoList'] = response.data;
      if (route.query.hasOwnProperty('videoId') && route.query.videoId !== '') {
        response.data.forEach((video) => {
          if (video.Id === route.query.videoId) {
            playerVideo.Id = video.Id;
            queryVideoDetail();
          }
        });
      } else {
        if (index === 0) {
          playerVideo.Id = response.data[index].Id;

          queryVideoDetail();
        }
      }
    });
  });
});

const queryVideoDetail = () => {
  //查询视频课详情
  request({
    url: '/Video/GetVideoDetailById',
    method: 'get',
    params: {
      id: playerVideo.Id,
      sign: route.query.sign,
    },
  }).then((resp) => {
    playerVideo.VideoURL = useUrlCv(resp.data.VideoURL);
    playerVideo.AttachPath = resp.data.AttachPath;
    playerVideo.Title = resp.data.Title;
    playerVideo.ChapterId = resp.data.ChapterId;
    playerVideo.TrialTime = resp.data.TrialTime;
    playerVideo.NewUserTrialTotal = resp.data.NewUserTrialTotal;
    playerVideo.finish = false;
    if (resp.data.ShowProblem) {
      resp.data.ShowProblem.split(',').forEach((item) => {
        playerVideo.videoExercises.push({
          timingSec: item,
        });
      });
    }
    //获取电子教材
    BusinessContextEvent.$emit('getEBook', playerVideo);
    //初始化视频
    initPlayer(playerVideo.VideoURL);

    nextTick(() => {
      //滚动到中间
      let elementById = document.getElementById(playerVideo.Id);
      elementById.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });
  });
};

/**
 * 初始化视频
 * @param src
 */
const initPlayer = (src = '') => {
  videojs.addLanguage('zh', {
    ...zh,
  });
  videojs.addLanguage('en', {
    ...en,
  });
  const videoOptions = {
    autoplay: false,
    controls: true,
    language: i18n.locale.value,
    playbackRates: [0.5, 1, 1.5, 2],
    /* poster: videoImage,*/
    controlBar: {
      volumePanel: {
        inline: false,
      },
      children: ['previousButton', 'playToggle', 'nextButton', 'volumePanel', 'progressControl', 'timeDisplay', 'playbackRateMenuButton', 'stopButton', 'fullscreenToggle'],
    },
    sources: [
      {
        src: src,
        type: 'video/mp4',
      },
    ],
  };
  player.value = videojs(playerRef.value, videoOptions, () => {
    //盗版必究
    let htmlDivElementModal = document.createElement('div');
    htmlDivElementModal.setAttribute('id', 'videoCopyrightModal');
    htmlDivElementModal.setAttribute('class', 'copyrightView');
    htmlDivElementModal.innerHTML = `<p class="copyright">${use$t('copyright.message.01')}</p><p class="copyright">${use$t('copyright.message.02')}</p>`;
    document.getElementById('videoPlayer_CoursePlayerCp').append(htmlDivElementModal);
    const videoElement = player.value.el_;
    //隐藏右击菜单
    videoElement.addEventListener(
      'contextmenu',
      function (event) {
        event.preventDefault();
      },
      false
    );
    // Audition();
    player.value.on('ended', function () {
      // 这里的代码会在每次视频播放结束时执行
      console.log('视频播放已结束');
      let htmlDivElement = document.createElement('div');
      htmlDivElement.setAttribute('id', 'videoModal');
      htmlDivElement.setAttribute('class', 'h100');
      modal.value = player.value.createModal(htmlDivElement);
      const nextVideoInfo = getNextVideoInfo();
      let nextCourse = '';
      if (nextVideoInfo.Id !== '') {
        nextCourse = use$t('common.next.course') + ':' + nextVideoInfo.Title;
      }
      createApp(videoModal, {
        nextCourse: nextCourse,
        nextBtnText: use$t('common.next.course'),
        replayText: use$t('video.reload.text'),
        onNextEvent: () => {
          nextVideo();
        },
        onReplay: () => {
          modal.value.close();
          player.value.load();
          player.value.play();
        },
      }).mount('#videoModal');
      modal.value.open();
    });
    //播放
    player.value.on('play', function () {
      if (useIsMobileBrowser()) {
        useVideoFullscreen(player.value);
      }
    });
    //时间轴
    player.value.on('timeupdate', function () {
      let token = localStorage.getItem('Token');
      if (!token) {
        player.value.pause();
        useCheckIsLogin(() => {}, true);
      }
      const remainingTime = player.value.remainingTime(); // 获取剩余时间（秒）
      preTime.value = currentTime.value; //记录上一次的时间点
      currentTime.value = Math.floor(player.value.currentTime()); //记录当前时间点
      if (remainingTime < 20 && playerVideo.finish === false) {
        videoEndApi();
      }
      if (currentTime.value > playerVideo.TrialTime || playerVideo.NewUserTrialTotal === '0') {
        if (alreadyOwned.value === false) {
          //没有购买
          if (playerVideo.TrialTime === 0 || playerVideo.NewUserTrialTotal == '0') {
            //无试听,或者 注册时间已超过允许试听时间
            player.value.pause();
            if (document.getElementsByClassName('buy_now_modal').length === 0) {
              if (player.value.isFullscreen()) {
                player.value.exitFullscreen();
              }
              BuyNow(() => {
                Payment($props.courseDetail, () => {
                  activationSuccessful(() => {
                    useRefreshPage();
                  });
                });
              });
            }
          } else {
            player.value.pause();
            //有试听
            if (document.getElementsByClassName('audition_modal').length === 0) {
              if (player.value.isFullscreen()) {
                player.value.exitFullscreen();
              }
              Audition(() => {
                Payment($props.courseDetail, () => {
                  activationSuccessful(() => {
                    useRefreshPage();
                  });
                });
              });
            }
          }
        } else {
          console.log('套餐--->', playerVideo.setMealQuota);
          if (playerVideo.setMealQuota === -1) {
            player.value.pause();
            if (document.getElementsByClassName('modal_alert').length === 0) {
              Alert({
                content: use$t('setMeal.message.15'),
              });
            }
          }
        }
      }
      //弹出视频练习
      playerVideo.videoExercises.forEach((item) => {
        if (currentTime.value == item.timingSec && currentTime.value > preTime.value) {
          preFullscreen.value = player.value.isFullscreen();
          if (preFullscreen.value) {
            player.value.exitFullscreen();
          }
          visible.value = true;
          currentTime.value = item.timingSec;
          player.value.pause();
        }
      });
    });
    //视频准备就绪
    player.value.on('loadedmetadata', function () {
      //试听、套餐、课程购买
      request({
        url: '/Video/WriteVideoRecord',
        method: 'get',
        params: {
          courseId: route.query.courseId,
          videoId: playerVideo.Id,
        },
      }).then((resp) => {
        playerVideo.setMealQuota = resp.data;
      });

      videoStartApi();
      if (alreadyOwned.value === false && playerVideo.TrialTime == 0) {
        player.value.pause();
        if (document.getElementsByClassName('buy_now_modal').length === 0) {
          if (player.value.isFullscreen()) {
            player.value.exitFullscreen();
          }
          BuyNow(() => {
            Payment($props.courseDetail, () => {
              activationSuccessful(() => {
                useRefreshPage();
              });
            });
          });
        }
      }
    });
  });
};

/**
 * 获取下一个视频课的信息
 */
const getNextVideoInfo = () => {
  const videoUrlList = [];
  chapterList.forEach((item) => {
    if (item.hasOwnProperty('videoList')) {
      item.videoList.forEach((video) => {
        videoUrlList.push(video);
      });
    }
  });
  const currentVideoIndex = videoUrlList.findIndex((item) => {
    return item.Id === playerVideo.Id;
  });
  //最后一个视频终止
  if (currentVideoIndex === videoUrlList.length - 1) {
    return {
      Id: '',
      VideoURL: '',
      AttachPath: '',
      Title: '',
      ChapterId: '',
      TrialTime: 0,
      videoExercises: [],
      finish: false,
    };
  }
  const videoExercises = [];
  if (videoUrlList[currentVideoIndex + 1].ShowProblem) {
    videoUrlList[currentVideoIndex + 1].ShowProblem.split(',').forEach((item) => {
      videoExercises.push({
        timingSec: item,
      });
    });
  }
  return {
    Id: videoUrlList[currentVideoIndex + 1].Id,
    VideoURL: useUrlCv(videoUrlList[currentVideoIndex + 1].VideoURL),
    AttachPath: videoUrlList[currentVideoIndex + 1].AttachPath,
    Title: videoUrlList[currentVideoIndex + 1].Title,
    ChapterId: videoUrlList[currentVideoIndex + 1].ChapterId,
    videoExercises: videoExercises,
    finish: false,
  };
};

/**
 * 获取上一条视频信息
 */
const getPreVideoInfo = () => {
  const videoUrlList = [];
  chapterList.forEach((item) => {
    if (item.hasOwnProperty('videoList')) {
      item.videoList.forEach((video) => {
        videoUrlList.push(video);
      });
    }
  });
  const currentVideoIndex = videoUrlList.findIndex((item) => {
    return item.Id === playerVideo.Id;
  });
  //最后一个视频终止
  if (currentVideoIndex === 0) {
    return {
      Id: '',
      VideoURL: '',
      AttachPath: '',
      Title: '',
      ChapterId: '',
      TrialTime: 0,
      videoExercises: [],
      finish: false,
    };
  }
  const videoExercises = [];
  if (videoUrlList[currentVideoIndex - 1].ShowProblem) {
    videoUrlList[currentVideoIndex - 1].ShowProblem.split(',').forEach((item) => {
      videoExercises.push({
        timingSec: item,
      });
    });
  }
  return {
    Id: videoUrlList[currentVideoIndex - 1].Id,
    VideoURL: useUrlCv(videoUrlList[currentVideoIndex - 1].VideoURL),
    AttachPath: videoUrlList[currentVideoIndex - 1].AttachPath,
    Title: videoUrlList[currentVideoIndex - 1].Title,
    ChapterId: videoUrlList[currentVideoIndex - 1].ChapterId,
    videoExercises: videoExercises,
    finish: false,
  };
};

/**
 * 下一个视频课
 */
const nextVideo = () => {
  const nextVideoInfo = getNextVideoInfo();
  if (nextVideoInfo.Id === '') {
    return;
  }
  playerVideo.Id = nextVideoInfo.Id;
  playerVideo.VideoURL = nextVideoInfo.VideoURL;
  playerVideo.AttachPath = nextVideoInfo.AttachPath;
  playerVideo.Title = nextVideoInfo.Title;
  playerVideo.ChapterId = nextVideoInfo.ChapterId;
  playerVideo.TrialTime = nextVideoInfo.TrialTime;
  playerVideo.videoExercises = nextVideoInfo.videoExercises;
  playerVideo.finish = false;
  if (modal.value) {
    modal.value.close();
  }
  //获取电子教材
  BusinessContextEvent.$emit('getEBook', playerVideo);
  //更新视频源
  useUpdateVideoSource(player.value, playerVideo.VideoURL);
};

/**
 * 上一个视频课
 */
const preVideo = () => {
  const preVideoInfo = getPreVideoInfo();
  if (preVideoInfo.Id === '') {
    return;
  }
  playerVideo.Id = preVideoInfo.Id;
  playerVideo.VideoURL = preVideoInfo.VideoURL;
  playerVideo.AttachPath = preVideoInfo.AttachPath;
  playerVideo.Title = preVideoInfo.Title;
  playerVideo.ChapterId = preVideoInfo.ChapterId;
  playerVideo.TrialTime = preVideoInfo.TrialTime;
  playerVideo.videoExercises = preVideoInfo.videoExercises;
  playerVideo.finish = false;
  if (modal.value) {
    modal.value.close();
  }
  //获取电子教材
  BusinessContextEvent.$emit('getEBook', playerVideo);
  //更新视频源
  useUpdateVideoSource(player.value, playerVideo.VideoURL);
};

/**
 * 切换视频
 * @param item
 */
const changeVideoEvent = (item) => {
  playerVideo.setMealQuota = 0;
  if (setMealCourse(item)) {
    return;
  }
  const videoExercises = [];
  if (item.ShowProblem) {
    item.ShowProblem.split(',').forEach((item) => {
      videoExercises.push({
        timingSec: item,
      });
    });
  }
  playerVideo.Id = item.Id;
  playerVideo.VideoURL = useUrlCv(item.VideoURL);
  playerVideo.AttachPath = item.AttachPath;
  playerVideo.Title = item.Title;
  playerVideo.ChapterId = item.ChapterId;
  playerVideo.TrialTime = item.TrialTime;
  playerVideo.videoExercises = videoExercises;
  playerVideo.finish = false;
  if (modal.value) {
    modal.value.close();
  }
  //获取电子教材
  BusinessContextEvent.$emit('getEBook', playerVideo);
  //更新视频源
  useUpdateVideoSource(player.value, playerVideo.VideoURL);
};

/**
 * 通知后端该视频播放结束
 */
const videoEndApi = () => {
  request({
    url: '/Video/VideoStudyEnd',
    method: 'get',
    params: {
      sign: route.query.sign,
      chapterId: playerVideo.ChapterId,
      videoId: playerVideo.Id,
    },
  }).then((resp) => {
    let id = localCache.userinfo.Id;
    localStorage.setItem(id + playerVideo.Id, true);
    playerVideo.finish = true;
  });
};

/**
 * 学习进度
 */
const videoStartApi = () => {
  request({
    url: '/Video/AddVideoStudyProgress',
    method: 'get',
    params: {
      sign: route.query.sign,
      chapterId: playerVideo.ChapterId,
      videoId: playerVideo.Id,
    },
  }).then((resp) => {
    console.log('视频准备就绪', resp);
  });
};

onMounted(async () => {
  BusinessContextEvent.$off('nextVideo').$on('nextVideo', () => {
    nextVideo();
  });
  BusinessContextEvent.$off('preVideo').$on('preVideo', () => {
    preVideo();
  });
});

watchEffect(() => {
  if ($props.courseDetail.FeeType != '') {
    //查询该课程是否已经购买
    useCheckIsLogin(async () => {
      let promise = await useCheckBuyCourse(route.query.courseId, $props.courseDetail.FeeType);
      if (promise.data && promise.data === 1) {
        alreadyOwned.value = true;
      }
    });
  }
});

/**
 * 课程收藏，取消收藏
 */
const collectEvent = () => {
  useCheckIsLogin(() => {
    if (isCollection.value) {
      //取消收藏
      Confirm({
        title: use$t('detail.course.uncollect'),
        content: use$t('individual.favorite.modal.content') + '?',
        okEvent: () => {
          request({
            url: '/Course/CancelCollect',
            method: 'get',
            params: {
              courseId: route.query.courseId,
            },
          }).then((resp) => {
            console.log('已取消收藏', resp);
            isCollection.value = false;
          });
        },
      });
    } else {
      //收藏
      request({
        url: '/Course/AddCollect',
        method: 'get',
        params: {
          courseId: route.query.courseId,
          sign: route.query.sign,
        },
      }).then((resp) => {
        console.log('已收藏', resp);
        isCollection.value = true;
      });
    }
  });
};

/**
 * 随堂练习关闭
 */
const exerciseCloseEvent = () => {
  player.value.play();
  if (preFullscreen.value) {
    player.value.requestFullscreen();
  }
};

useCheckIsLogin(() => {
  useCheckIsCollect(route.query.courseId).then((resp) => {
    if (resp === 1) {
      isCollection.value = true;
    } else {
      isCollection.value = false;
    }
  });
}, false);

defineExpose({
  videoId: () => playerVideo.Id,
  newUserTrialTotal: () => playerVideo.NewUserTrialTotal,
  TrialTime: () => playerVideo.TrialTime,
});
</script>

<style lang="less" scoped>
#videoModal {
  height: 100%;
}

.course_player_component {
  width: 1200px;
  height: 560px;
  background-color: #1b2128;
  box-shadow: 0 0 10px 1px #dbd9d9;
  margin: 30px auto auto;
  display: flex;

  .player_view {
    width: 850px;
    height: 560px;
    display: flex;
    flex-direction: column;

    .player_video {
      width: 100%;
      height: 477px;
      background-color: white;

      #videoPlayer_CoursePlayerCp {
        width: 100%;
        height: 479px;
      }
    }

    .player_footer {
      margin: auto 30px;

      .arco-btn {
        width: auto;
        height: 36px;
        background: #323c46;
        border-radius: 21px;
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #929aa3;
        line-height: 22px;
        text-align: left;
        font-style: normal;

        &:last-child {
          margin-left: 30px;
        }

        .favor_icon {
          width: 22px;
          height: 22px;
          margin-right: 12px;
        }

        .pull_down_icon {
          width: 22px;
          height: 22px;
          margin-right: 12px;
        }
      }
    }
  }

  .course_info {
    width: 350px;

    :deep(.arco-tabs) {
      .arco-tabs-content {
        height: 500px;
        overflow-y: auto;
      }

      .arco-tabs-nav {
        height: 50px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.33);

        &:before {
          display: none;
        }
      }

      .arco-tabs-tab {
        height: 50px;
      }

      .arco-tabs-tab-title {
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #787e87;
        line-height: 22px;
        text-align: center;
        font-style: normal;
      }

      .arco-tabs-tab-active {
        .arco-tabs-tab-title {
          color: #c0c5cc;
          font-weight: 500;
        }
      }
    }

    .catalogue_item {
      .chapter_name {
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #e0e3ee;
        display: flex;
        align-items: center;
        line-height: 22px;
        letter-spacing: 1px;
        text-align: left;
        font-style: normal;
        margin-left: 30px;

        &:before {
          content: '';
          margin-right: 10px;
          display: inline-block;
          height: 20px;
          border-radius: 3px;
          width: 4px;
          background-color: #0678ff;
        }
      }

      .chapter_course_item {
        width: 290px;
        //height: 60px;
        background: #0f1419;
        border-radius: 12px;
        padding: 10px;
        margin: 10px auto;
        cursor: pointer;

        .chapter_course_item_header {
          display: flex;
          align-items: center;

          .play-circle_icon {
            width: 18px;
            height: 18px;
            margin-right: 12px;
          }

          .chapter_item_title {
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #a1a9b2;
            line-height: 20px;
            letter-spacing: 1px;
            text-align: left;
            font-style: normal;
            margin: 0;
            flex: 2;
          }

          :deep(.arco-image-img) {
            width: 15px;
            height: 15px;
          }

          :deep(.arco-icon-lock) {
            color: #999999;
          }
        }

        .chapter_course_item_time {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #606469;
          line-height: 17px;
          letter-spacing: 1px;
          text-align: left;
          font-style: normal;
          margin-left: 30px;
          margin-top: 4px;

          .arco-icon-check {
            color: rgb(22, 224, 137);
            font-weight: bolder;
          }
        }
      }
    }
  }

  &:lang(ar),
  &:lang(pk) {
    .player_footer {
      .arco-btn {
        &:last-child {
          margin-left: 0;
          margin-right: 30px;
        }
      }

      .favor_icon {
        margin-right: 0;
        margin-left: 12px;
      }

      .pull_down_icon {
        margin-right: 0;
        margin-left: 12px;
      }
    }
  }
}
</style>
