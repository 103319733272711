<!--分享-->
<template>
  <div class="tabs">
    <p>{{ use$t('share.message.01') }}</p>
  </div>
  <div :lang="i18n.locale.value" class="content_body">
    <div class="share-body">
      <XyzTransitionGroup class="example-grid" xyz="fade small stagger">
        <div v-if="show" class="link_view square">
          <a-input v-model="linkValue" readonly />
          <a-button v-copy="linkValue" v-copy:callback="handleCopied" class="copyBtn" shape="circle" type="primary">
            {{ use$t('share.message.02') }}
          </a-button>
        </div>
        <a-divider v-if="show" margin="40px" />
        <div v-if="show" class="share_table square">
          <p class="table_title">{{ use$t('share.message.04') }}</p>
          <a-table :bordered="false" :columns="columns" :data="data" sticky-header table-layout-fixed @pageChange="pageChange" />
        </div>
      </XyzTransitionGroup>
    </div>
  </div>
</template>
<script setup>
import { onMounted, onUnmounted, reactive, ref } from 'vue';
import { Notification } from '@arco-design/web-vue';
import { useLocalCacheStore } from '@/stores/localCache.js';
import request from '@/common/request.js';
import { use$t } from '@/hooks/language.js';
import { useI18n } from 'vue-i18n';

const localCache = useLocalCacheStore();
const i18n = useI18n();
const linkValue = ref('https://www.hanzfeng.com/?userCode=' + localCache.userinfo.InvitationCode);
const show = ref(false);
const queryParams = reactive({
  pageIndex: 1,
  pageSize: 6,
});

const columns = reactive([
  {
    title: use$t('share.message.05'),
    dataIndex: 'EMail',
  },
  {
    title: use$t('share.message.06'),
    dataIndex: 'CreateTime',
  },
]);

const data = reactive([]);

onMounted(() => {
  show.value = true;
  getList();
});

const getList = () => {
  request({
    url: '/Auth/MyInvitationListPager',
    method: 'GET',
    params: queryParams,
  }).then((res) => {
    data.length = 0;
    data.push(...res.data.DataList);
  });
};

onUnmounted(() => {
  show.value = false;
});

const handleCopied = (text = '') => {
  Notification.success({
    content: use$t('share.message.03'),
  });
};

const pageChange = (page) => {
  queryParams.pageIndex = page;
  getList();
};
</script>
<style lang="less" scoped>
.tabs {
  display: flex;

  p {
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #0678ff;
    line-height: 22px;
    text-align: left;
    font-style: normal;
    margin-top: 10px;
  }

  .activated {
    color: #0678ff;
  }
}

.content_body {
  border-top: 1px solid #e2e2e2;
  margin-top: 20px;

  .share-body {
    padding: 50px 60px;

    .link_view {
      width: 100%;
      align-items: center;
      display: flex;

      :deep(.arco-input) {
        height: 48px;
        width: 500px;
        font-family: PingFang SC;
        font-weight: 400;
        padding-top: 0;
        padding-bottom: 0;
        font-size: 14px;
        color: #0678ff;
        line-height: 20px;
        font-style: normal;
      }

      :deep(.arco-input-wrapper) {
        width: 500px;
        background: #ffffff;
        border-radius: 6px;
        border: 1px solid #d9d9d9;
      }

      .copyBtn {
        flex-grow: 1;
        height: 48px;
        margin-left: 22px;
        background: #1c61e1;
        border-radius: 6px;

        font-family: PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #ffffff;
        text-align: center;
      }

      .activation_icon {
        width: 18px;
        height: 18px;
      }
    }

    .share_table {
      .table_title {
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #333333;
        margin-bottom: 30px;
      }

      :deep(.arco-table-th-title) {
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #999999 !important;
        line-height: 20px;
      }

      :deep(.arco-table-td) {
        text-align: unset;
      }
    }
  }

  &:lang(ar),
  &:lang(pk) {
    .copyBtn {
      margin-left: 0;
      margin-right: 22px;
    }
  }
}
</style>
