<!--主页展示课程-->
<template>
  <a-skeleton :animation="true">
    <a-skeleton-shape />
  </a-skeleton>
  <div v-for="(item, index) in courseRoomList" :key="index" :data-key="index" :lang="i18n.locale.value" class="basic_chinese">
    <div class="w1200 basic_chinese_view">
      <p class="basic_chinese_view_title">{{ item.categoryObj.ContentName }}</p>
      <div class="basic_chinese_view_line_header">
        <div class="basic_chinese_view_line_header_btn-group">
          <a-button :type="item.activeTag === '' ? 'primary' : 'normal'" shape="round" @click="clickHandle('', index)">
            {{ $t('header.menu.allChannels') }}
          </a-button>
          <a-button v-for="(tag, tagIndex) in item.tags" :key="tagIndex" :type="tag.Id === item.activeTag ? 'primary' : 'normal'" shape="round" @click="clickHandle(tag.Id, index)">
            {{ tag.ContentName }}
          </a-button>
        </div>
        <div class="basic_chinese_view_line_header_more" @click="toCoursePage">
          {{ $t('home.course.more') }}
          <icon-double-right v-if="i18n.locale.value !== langToBack.ar" />
          <icon-double-left v-else />
        </div>
      </div>
      <XyzTransition duration="auto" xyz="fade small">
        <div v-if="index === 0 ? loading : loading02" class="basic_chinese_view_body square xyz-nested">
          <div v-for="(course, courseIndex) in item.tableList" :key="courseIndex" class="basic_chinese_view_item pointer anime_zoom_lager" @click="useToTabPage('/courseDetail/' + course.Id)">
            <a-image :preview="false" :src="useUrlCv(course.CoverPicURL)"></a-image>
            <div class="item_footer">
              <a-typography-text class="item_title" ellipsis>{{ course.Title }}</a-typography-text>
              <a-typography-text class="item_desc" ellipsis>{{ course.Introduction }}</a-typography-text>
            </div>
          </div>
        </div>
      </XyzTransition>
      <Empty v-if="item.tableList.length == 0" />
      <div class="basic_chinese_view_btn">
        <a-button v-if="item.tableList.length != 0" shape="square" status="normal" @click="refreshCourse(index)">
          <icon-loop />
          {{ $t('home.change.course') }}
        </a-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import { hideLoadingProcess, showLoadingProcess, useToTabPage } from '@/hooks/page.js';
import { useCourseCategoryApi } from '@/hooks/commomApi.js';
import request from '@/common/request.js';
import { useUrlCv } from '@/hooks/format.js';
import Empty from '@/components/empty/index.vue';
import { langToBack } from '@/hooks/sysDict.js';
import { useI18n } from 'vue-i18n';

const tag = ref('a');
const i18n = useI18n();
const router = useRouter();
const loading = ref(false);
const loading02 = ref(false);
const queryParams = reactive({
  categoryId: '',
  labelId: '',
});
const courseRoomList = reactive([]);

onMounted(async () => {
  const courseCategory = await useCourseCategoryApi();
  courseCategory.data.forEach((item, index) => {
    if (index < 2) {
      courseRoomList.push({
        categoryObj: {
          Id: '',
          ContentName: '',
        },
        activeTag: '',
        tags: [],
        tableList: [],
      });
      //获取课程分类信息
      courseRoomList[index].categoryObj.Id = item.Id;
      courseRoomList[index].categoryObj.ContentName = item.ContentName;
      //获取课程分类下的标签
      getTags(item.Id, (resp) => {
        resp.forEach((tag) => {
          courseRoomList[index].tags.push({
            Id: tag.Id,
            ContentName: tag.ContentName,
          });
          if (resp.lenth != 0) {
            // courseRoomList[index].activeTag = resp[0].Id;
            courseRoomList[index].activeTag = '';
          }
        });
        //获取默认课程分类和标签下的课程
        getCourseList(courseRoomList[index].categoryObj.Id, courseRoomList[index].tags.length == 0 ? '' : courseRoomList[index].activeTag, (resp) => {
          courseRoomList[index].tableList = resp.DataList;
        });
      });
    }
  });
});

/**
 * 获取课程分类下的标签
 * @param categoryId
 */
const getTags = (categoryId, callback) => {
  request({
    url: '/Label/GetLabelList',
    method: 'get',
    params: {
      categoryId: categoryId,
    },
  }).then((resp) => {
    callback(resp.data);
  });
};

/**
 * 获取课程列表
 */
const getCourseList = (categoryId, labelId, callback, index) => {
  if (index === 0) {
    loading.value = false;
  } else if (index === 1) {
    loading02.value = false;
  }
  request({
    url: '/Course/GetHomeCourseList',
    method: 'get',
    params: {
      categoryId: categoryId,
      labelId: labelId,
    },
  }).then((resp) => {
    loading.value = true;
    loading02.value = true;
    callback(resp.data);
  });
};

/**
 * 切换标签
 * @param val
 * @param index
 */
const clickHandle = (val, index) => {
  courseRoomList[index].activeTag = val;
  getCourseList(
    courseRoomList[index].categoryObj.Id,
    val,
    (resp) => {
      courseRoomList[index].tableList = resp.DataList;
    },
    index
  );
};

/**
 * 更多课程
 */
const toCoursePage = () => {
  useToTabPage('/allCourse');
};

/**
 * 换一批
 */
const refreshCourse = (index) => {
  showLoadingProcess();
  if (index == 1) {
    loading02.value = false;
  } else {
    loading.value = false;
  }

  request({
    url: '/Course/GetRandomCourseList',
    method: 'get',
    params: { categoryId: courseRoomList[index].categoryObj.Id, labelId: courseRoomList[index].activeTag },
  }).then((resp) => {
    hideLoadingProcess();
    if (index == 1) {
      loading02.value = true;
    } else {
      loading.value = true;
    }
    console.log('换一批', index, resp);
    courseRoomList[index].tableList = resp.data.DataList;
  });
};
</script>

<style lang="less" scoped>
.basic_chinese {
  width: 1920px;
  background-color: #ffffff;

  &[data-key='1'] {
    background-color: #f8f8f8;
  }

  .basic_chinese_view {
    margin: auto;

    .basic_chinese_view_title {
      font-family: PingFang SC;
      font-weight: 600;
      font-size: 42px;
      color: #333333;
      line-height: 59px;
      text-align: center;
      font-style: normal;
      padding-top: 65px;
      padding-bottom: 40px;
    }

    .basic_chinese_view_line_header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .basic_chinese_view_line_header_btn-group {
        :deep(.arco-btn) {
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          border-radius: 50px;
          text-align: center;
          font-style: normal;
        }

        :deep(.arco-btn) {
          margin-right: 20px;
          margin-top: 10px;
        }

        &:lang(ar),
        &:lang(pk) {
          :deep(.arco-btn:not(.arco-btn:first-child)) {
            margin-left: 0;
            margin-right: 20px;
          }
        }
      }

      .basic_chinese_view_line_header_more {
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #666666;
        line-height: 22px;
        white-space: nowrap;
        font-style: normal;
        cursor: pointer;
      }
    }

    .basic_chinese_view_body {
      width: 100%;
      margin-top: 28px;
      display: grid;
      grid-template-columns: 285px 285px 285px 285px;
      grid-gap: 20px;

      .basic_chinese_view_item {
        width: 285px;
        height: 250px;
        background: #ffffff;
        box-shadow:
          0px 0px 10px 0px rgba(1, 10, 29, 0.15),
          0px 0px 3px 0px rgba(142, 142, 142, 0.41);
        border-radius: 12px;
        filter: blur(0px);

        :deep(.arco-image-img) {
          width: 285px;
          height: 158px;
          border-radius: 12px 12px 0 0;
        }

        .item_footer {
          padding: 12px 10px;

          .item_title {
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #333333;
            line-height: 22px;
            text-shadow: 0 0 10px rgba(1, 10, 29, 0.15);
            font-style: normal;
            margin: 0;
          }

          .item_desc {
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #8d8d8d;
            line-height: 20px;
            text-shadow: 0 0 10px rgba(1, 10, 29, 0.15);
            text-align: left;
            font-style: normal;
            margin: 10px 0 0;
          }
        }
      }
    }

    .basic_chinese_view_btn {
      margin-top: 40px;
      text-align: center;
      padding-bottom: 100px;

      :deep(.arco-btn) {
        width: 164px;
        height: 44px;
        background: #ffffff;
        border-radius: 6px;
        border: 1px solid #d3d3d3;
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #666666;
        line-height: 22px;
        text-align: center;
        font-style: normal;

        .arco-icon {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
}
</style>
