<template>
  <a-modal v-model:visible="visible" :footer="false" :mask-closable="false" :title="use$t('player.course.download.attachment')" :unmount-on-close="true" modal-class="attachments">
    <template v-for="(item, index) in attachPathList">
      <div class="attachments_item">
        <div class="item_r">
          <SvgIcon class-name="file_icon" icon-class="file"></SvgIcon>
          <p>{{ item.name }}</p>
        </div>
        <icon-download class="pointer" @click="useDownloadFile(item.url)" />
      </div>
    </template>
    <template v-if="attachPathList.length == 0">
      <Empty />
    </template>
  </a-modal>
</template>

<script setup>
import { use$t } from '@/hooks/language.js';
import { computed } from 'vue';
import { useUrlCv } from '@/hooks/format.js';
import SvgIcon from '@/components/svgIcon/index.vue';
import { useDownloadFile } from '@/hooks/utils.js';
import Empty from '@/components/empty/index.vue';

const $props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  attachPath: String,
});

const $emits = defineEmits(['update:visible']);

const visible = computed({
  get: () => {
    return $props.visible;
  },
  set: (val) => {
    $emits('update:visible', val);
  },
});

/**
 * 计算附件数据
 * @type {ComputedRef<unknown>}
 */
const attachPathList = computed(() => {
  const arr = [];
  if ($props.attachPath) {
    const attachArr = $props.attachPath.split(',');
    attachArr.forEach((item) => {
      arr.push({
        url: useUrlCv(item),
        name: item.substring(item.indexOf('_') + 1),
      });
    });
    return arr;
  }
  return arr;
});
</script>

<style lang="less">
.attachments {
  border-radius: 8px;

  .arco-modal-header {
    border-radius: 8px 8px 0 0;
    height: 50px;
    background: #f5f9fd;
  }

  .arco-modal-body {
    padding-top: 0;
    padding-bottom: 0;
    min-height: 200px;

    .attachments_item {
      height: 60px;
      border-bottom: 1px solid #f0f0f0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .item_r {
        display: flex;

        p {
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
          line-height: 32px;
          text-align: left;
          font-style: normal;
          margin-left: 10px;
        }
      }

      .file_icon {
        width: 32px;
        height: 32px;
      }

      .arco-icon-download {
        width: 20px;
        height: 20px;

        &:hover {
          color: #0678ff;
        }
      }
    }
  }
}
</style>
