<!--首页轮播图-->
<template>
  <a-skeleton v-if="swiperList.length === 0" :animation="true">
    <a-skeleton-shape class="swiper" />
  </a-skeleton>
  <swiper
    v-else
    :autoplay="{ delay: 5000, pauseOnMouseEnter: true, disableOnInteraction: true }"
    :effect="'fade'"
    :fade-effect="{
      crossFade: true,
    }"
    :lazy="true"
    :modules="modules"
    :navigation="true"
    :observe-parents="true"
    :observer="true"
    :pagination="{ clickable: true }"
    :slidesPerView="1"
    :speed="500"
    :threshold="50"
    class="square xyz-in"
    loop
    xyz="small-100% origin-top in-duration-5"
  >
    <swiper-slide v-for="(item, index) in swiperList" :key="index" class="pointer">
      <video v-if="item.ContentPicUrl.indexOf('.mp4') > -1" :src="wukong" autoplay class="swiper_image anime_zoom_lager" loop></video>
      <a-image
        v-else
        :preview="false"
        :show-loader="true"
        :src="useUrlCv(item.ContentPicUrl)"
        class="swiper_image anime_zoom_lager"
        loading="lazy"
        @click="useToOtherPage(item.ContentLinkUrl)"
      ></a-image>
      <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
    </swiper-slide>
  </swiper>
</template>

<script setup>
import { reactive } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import request from '@/common/request.js';
import { useUrlCv } from '@/hooks/format.js';
import { useToOtherPage } from '@/hooks/page.js';

const swiperList = reactive([]);
const modules = reactive([Autoplay, Pagination, EffectFade, Navigation]);

request({
  url: '/CarouselPic/GetCarouselPicList',
  method: 'get',
  params: {},
}).then((resp) => {
  swiperList.length = 0;
  swiperList.push(...resp.data);
});
</script>

<style lang="less" scoped>
.swiper {
  width: 1920px;
  height: 660px;
  direction: ltr;
  --swiper-pagination-color: white; /* 两种都可以 */
  --swiper-pagination-bottom: 30px;
  --swiper-navigation-sides-offset: 300px;

  :deep(.swiper-button-prev) {
    &:after {
      content: '';
    }
  }

  :deep(.swiper-button-next) {
    &:after {
      content: '';
    }
  }

  &:hover {
    :deep(.swiper-button-prev) {
      height: 42px;
      width: 42px;

      &:after {
        content: '';
        height: 42px;
        width: 42px;
        background-image: url('@/assets/images/left.svg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }

    :deep(.swiper-button-next) {
      height: 42px;
      width: 42px;

      &:after {
        content: '';
        height: 42px;
        width: 42px;
        background-image: url('@/assets/images/right.svg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
  }

  .swiper-wrapper {
    .swiper-slide {
      .swiper_image {
        width: 100vw;
        height: calc(100vh - 90px);

        :deep(.arco-image-img) {
          width: 100vw;
          height: 660px;
        }
      }
    }
  }

  :deep(.swiper-pagination-bullet) {
    width: 8px !important;
    height: 8px !important;
  }
}
</style>
